import { WHOAMI, MENU_ACTIVE_ACTION, GET_CONTACTS_LIST } from '../actions/types';

const initialState = {
	whoami: '',
	ward: '',
	menu: '',
	contacts_list: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case WHOAMI:
			return {
				...state,
				whoami: action.payload,
			};
		case MENU_ACTIVE_ACTION:
			return {
				...state,
				menu: action.payload.menu,
			};
		case GET_CONTACTS_LIST:
			return {
				...state,
				contacts_list: action.payload,
			};
		default:
			return state;
	}
}
