import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../../Generic/Form/Main';
import { goBack } from 'connected-react-router';
import { sendRequestCodeAction, sendValidateCodeAction } from '../../actions/usersActions';
import { Collapse, Typography } from '@mui/material';
import { formValueSelector, change } from 'redux-form';
import { TYPES as FORM_TYPES } from '../../../Generic/FormTypes';

class PasswordValidate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validate: this.props.recovery_token !== null ? true : false,
			recovery_token: this.props.recovery_token ?? null,
      recovery_medium: this.props.recovery_medium ?? null,
      recovery_value: this.props.recovery_value ?? null,
      recoveryMediums: this.props.supported_recovery_password_mediums
			? this.props.supported_recovery_password_mediums
					.replace(/,\s*$/, '')
					.split(',')
					.map((item) => item.trim())
					.filter((item) => item.length > 0 && ['email','phone'].includes(item))
			: ['email'],
		};
	}

  componentDidMount() {
    if (this.state.validate) {
      this.props.sendValidateCodeAction('code', () => this.props.handleValidateCode(), () => this.handleRequestCode(false));
    }
	}

	componentDidUpdate(prevProps) {    
		if (prevProps.recovery_token !== this.props.recovery_token) {
			this.setState({
				recovery_token: this.props.recovery_token,
        recovery_medium: this.props.recovery_medium,
        recovery_value: this.props.recovery_value,
				validate: this.props.recovery_token !== null ? true : false,
			});

      if (prevProps.recovery_token === null && this.props.recovery_token !== null && this.props.recovery_value !== null && this.props.recovery_medium !== null) {
        this.props.change('request_code', 'medium_type', this.props.recovery_medium);
        this.props.change('request_code', 'medium_value', this.props.recovery_value);
        this.props.change('code', 'recovery_token', this.props.recovery_token);

        this.props.sendValidateCodeAction('code', () => this.props.handleValidateCode(), () => this.handleRequestCode(false));
      }
		} else if (prevProps.request_code_field !== this.props.request_code_field) {
      this.props.change('request_code', 'medium_value', this.props?.user?.[this.props.request_code_field] != null ? this.props?.user?.[this.props.request_code_field] : '');
    }
	}

	handleRequestCode = (value) => {
		this.setState({
			validate: value,
			recovery_token: null,
      recovery_medium: null,
      recovery_value: null,      
		});
	};

	handleConfigRequest = () => {
		const { translate } = this.props;

		let medium_options = [];
		if (this.state.recoveryMediums) {
			this.state.recoveryMediums.map((medium, index) =>
        {          
          if (this.props?.user?.id == null || this.props?.user?.[medium] != null) {
            medium_options.push({ label: translate('user_change_password_type_validate_'+ medium), value: medium })
          }
        }
			);
		}

    let medium_type_initial = typeof this.props.request_code_field !== 'undefined' ? this.props.request_code_field : (this.state.recoveryMediums.length > 0) ? this.state.recoveryMediums[0] : '';
    let medium_value_initial = '';
    
    medium_type_initial = this.state.recoveryMediums.includes(medium_type_initial) ? medium_type_initial : '';
    if (this.state.recovery_medium != null && this.state.recovery_value != null && this.state.recoveryMediums.includes(this.state.recovery_medium)) {
      medium_type_initial = this.state.recovery_medium;
      medium_value_initial = this.state.recovery_value;
    }

    if (this.props?.user?.id != null) {
      if (this.props?.user?.[medium_type_initial] != null) {
        medium_value_initial = this.props?.user?.[medium_type_initial];
      } else {
        medium_type_initial = '';
        medium_value_initial = '';
      }
    }
    
		const structure = {
			form: 'request_code',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			columns: [
				{
					id: 'request-code-column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'request-code-separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'medium_type',
									name: 'medium_type',
									label: translate('user_change_password_type_request_validate'),
									type: FORM_TYPES.SELECT,
                  readonly: medium_options.length == 1  ? true : false,
									empty_option: false,
                  options: medium_options,
									validate: {
										required: true,
									},
									initialvalue: medium_type_initial,
								},
								{
									id: 'medium_value',
									name: 'medium_value',
									label: ( typeof this.props.request_code_field !== 'undefined') ? translate('user_change_password_type_' + this.props.request_code_field) : translate('user_change_password_type_sms_email'),
									type: FORM_TYPES.INPUT,
                  readonly: (this.props?.user?.id != null) ? true : false,
									validate: {
										required: true,
                    email: ((typeof this.props.request_code_field !== 'undefined' && this.props.request_code_field == 'email') ? true : false),
									},
									helperText: (typeof this.props.request_code_field !== 'undefined') ? translate('user_change_password_helper_' + this.props.request_code_field) : translate('user_change_password_helper_email'),
									initialvalue: medium_value_initial,
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'request_code_submit_button',
					fullWidth: true,
					label: translate('user_change_password_send_request_validate'),
					grid_layout: { xs: 12 },
					action: () => this.props.sendRequestCodeAction('request_code', () => this.handleRequestCode(true)),
					api_validation: true,
				},
			],
		};

		return structure;
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'code',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			columns: [
				{
					id: 'code-column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'code-separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'recovery_token',
									name: 'recovery_token',
									label: translate('user_change_password_write_validate_code'),
									type: FORM_TYPES.INPUT,
									validate: {
										required: true,
									},
									initialvalue: this.state.recovery_token != null ? this.state.recovery_token : '',
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'code_submit_button',
					fullWidth: true,
					label: translate('user_change_password_send_validate_code'),
					grid_layout: { xs: 12 },
					action: () =>
						this.props.sendValidateCodeAction(
							'code',
							() => this.props.handleValidateCode(),
							() => this.handleRequestCode(false)
						),
					api_validation: true,
				},
				{
					id: 'code_goback_button',
					fullWidth: true,
					label: translate('generic_button_back'),
					grid_layout: { xs: 12 },
					action: () => (this.props.user) ? this.props.goBack() :  this.handleRequestCode(false),
					submit: false,
					color: 'inherit',
				},
			],
		};

		return structure;
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	render() {
		const configuration = this.handleConfig();
		const configurationRequest = this.handleConfigRequest();

		return (
			<div>
				<Collapse in={!this.state.validate}>
					<Typography align="center" variant="subtitle2">
						{this.props.translate('user_change_password_form_message')}
					</Typography>
					<Main form={configurationRequest.form} formData={configurationRequest} />
				</Collapse>
				<Collapse in={this.state.validate}>
					<Typography align="center" variant="subtitle2">
						{(typeof this.props.request_code_field === 'undefined') ? this.props.translate('user_change_password_form_message_code') : this.props.translate('user_change_password_form_message_code_' + this.props.request_code_field) }
					</Typography>
					<Main form={configuration.form} formData={configuration} />
				</Collapse>
			</div>
		);
	}
}
const selector = formValueSelector('request_code');
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	request_code_field: selector(state, 'medium_type'),
	user: state.users.whoami,
  supported_recovery_password_mediums: state.settings.auth.supported_recovery_password_mediums,
});

export default connect(mapStateToProps, { sendRequestCodeAction, sendValidateCodeAction, goBack, change})(PasswordValidate);
