export const FETCH_WEIGHT_LOGS = 'FETCH_WEIGHT_LOGS';
export const FETCH_FOOD_LOGS = 'FETCH_FOOD_LOGS';
export const FETCH_MOOD_LOGS = 'FETCH_MOOD_LOGS';
export const FETCH_EXERCISE_LOGS = 'FETCH_EXERCISE_LOGS';
export const FETCH_WEIGHT_SUMMARY = 'FETCH_WEIGHT_SUMMARY';
export const FETCH_EXERCISE_SUMMARY = 'FETCH_EXERCISE_SUMMARY';
export const FETCH_WEIGHT_GRAPH = 'FETCH_WEIGHT_GRAPH';
export const FETCH_DAILY_LOG = 'FETCH_DAILY_LOG';
export const FETCH_LATEST_DAILY_LOG = 'FETCH_LATEST_DAILY_LOG';
export const FETCH_MOOD_SUMMARY = 'FETCH_MOOD_SUMMARY';
