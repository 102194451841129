import React, { useState, useEffect } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../Generic/Form/Main';
import { TYPES as FORM_TYPES } from '../../Generic/FormTypes';
import { Grid, Typography } from '@mui/material';
import { resendTokenAction } from '../../Users/actions/usersActions';
import { sendVerifyTokenAction } from '../actions/loginActions';

export const EmailConfirm = ({ resendTokenAction, sendVerifyTokenAction, ...props }) => {
	const handleConfig = () => {
		const { translate } = props;

		const structure = {
			form: 'emailcode',
			id: 'emailcode',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'xl',
			fields_grid_layout: {
				xs: 12,
			},
			columns: [
				{
					id: 'column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'email',
									name: 'email',
									label: translate('user_external_field_email'),
									type: FORM_TYPES.INPUT,
									initialvalue: props.login.email_confirm,
									validate: {
										email: true,
										required: true,
									},
								},
								{
									id: 'verify_token',
									name: 'verify_token',
									label: translate('user_change_password_write_validate_code'),
									type: FORM_TYPES.INPUT,
									validate: {
										required: true,
									},
									initialvalue: '',
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'email_resend_confirm',
					label: translate('email_resend_confirm'),
					fullWidth: true,
					color: 'primary',
					exclude_fields_validation: ['verify_token'],
					submit: true,
					action: () => resendTokenAction('emailcode'),
				},
				{
					id: 'email_send_confirm',
					label: translate('email_send_confirm'),
					fullWidth: true,
					color: 'primary',
					action: () => sendVerifyTokenAction('emailcode'),
				},
			],
		};

		return structure;
	};

	const configuration = handleConfig();

	return (
		<Grid container>
			<Typography align="center" variant="subtitle2" component="p">
				{props.translate('login_confirmed_info')}
			</Typography>
			<Main form={configuration.form} formData={configuration} />
		</Grid>
	);
};

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	user: state.users.whoami,
	login: state.login,
});
const mapDispatchToProps = { resendTokenAction, sendVerifyTokenAction };

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirm);
