import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { connect } from 'react-redux';
import CatalogueGrid from './CatalogueGrid';
import genericActions from '../Generic/actions/genericActions';
import { catalogue_structure } from '../../catalogues';
import { Toolbar } from '@mui/material';

export const SideBar = ({ open, catalogue, handleSideBar, drawer_props = {} }) => {
	// const footerHeight = document.getElementById('footer-element').offsetHeight;
	const [footerHeight, setfooterHeight] = React.useState(36);

	useEffect(() => {
		const element = document.getElementById('footer-element');

		if (element) {
			setfooterHeight(element.offsetHeight);
		}
	}, []);

	return (
		<Drawer
			anchor={'right'}
			open={open}
			onClose={() => handleSideBar(false)}
			classes={{ paper: 'generic-sidebar-main' }}
			PaperProps={{
				sx: { width: { xs: '100%', sm: 'auto' }, minWidth: { xs: '100%', sm: '35%' }, maxWidth: { xs: '100%', sm: '35%' } },
			}}
			{...drawer_props}
		>
			{drawer_props?.variant === 'permanent' && <Toolbar />}
			<CatalogueGrid catalogue={catalogue} />
			{drawer_props?.variant === 'permanent' && (
				<Toolbar sx={{ height: footerHeight + 'px !important', minHeight: footerHeight + 'px !important' }} />
			)}
		</Drawer>
	);
};

SideBar.propTypes = {
	open: PropTypes.bool.isRequired,
	catalogue: PropTypes.shape(catalogue_structure),
	handleSideBar: PropTypes.func.isRequired,
	drawer_props: PropTypes.shape({
		anchor: PropTypes.string,
		classes: PropTypes.object,
		PaperProps: PropTypes.object,
		ModalProps: PropTypes.object,
		SlideProps: PropTypes.object,
		variant: PropTypes.string,
		sx: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
			PropTypes.func,
			PropTypes.object,
		]) /** MUI sx prop */,
	}),
};

const mapStateToProps = (state) => ({
	open: state.generic.side_bar.open,
	catalogue: state.generic.side_bar.catalogue,
	drawer_props: state.generic.side_bar.drawer_props,
});

const mapDispatchToProps = { handleSideBar: genericActions.handleSideBar };

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
