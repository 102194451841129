import { isMobileSize } from '../../helpers/functions/functions';

import PlaceholderIMG from '../images/placeholder.svg';

const welcome = (theme) => {
	return {
		'.welcome-main': {
      flexGrow: 1,
			textAlign: 'center',
		},
		'.welcome-navbar-main': {
			// position: 'fixed !important',
			backgroundColor: 'white !important',
		},
		'.register-navbar-main': {
			backgroundColor: 'white !important',
		},
		'.welcome-navbar-button': {
			marginRight: theme.spacing(2),
			borderRadius: '0px',
			'&:hover': {
				backgroundColor: 'unset',
			},
		},
		'.welcome-presentation-content': {
			color: (props) =>
				typeof props.settings.site !== 'undefined' && props.settings.site.homepage_title_color
					? props.settings.site.homepage_title_color
					: theme.palette.primary.main,
			width: '100%',
			height: '100%',
			display: 'flex',
			position: 'absolute',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: 'calc(10px + 2vmin)',
			backgroundImage: (props) =>
				typeof props.settings.site !== 'undefined' && props.settings.site.files[isMobileSize() ? 'welcome_mobile_image' : 'welcome_image']
					? `url(${
							props.config.apihost +
							'/configuration/files/' +
							props.settings.site.files[isMobileSize() ? 'welcome_mobile_image' : 'welcome_image'].uuid
					  })`
					: `url(${PlaceholderIMG})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center'			
		},
		'.welcome-presentation-list': {
			width: '100%',
			maxWidth: '360',
			paddingLeft: '5%',
			color: 'black',
		},
		'.welcome-presentation-title': {
			[theme.breakpoints.down('lg')]: {
				marginTop: theme.spacing(8),
			},
		},
		'.welcome-about-content': {
			minHeight: '100vh',
			display: 'grid',
		},
		'.welcome-about-title': {
			padding: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		'.welcome-about-card': {
			minWidth: 275,
		},
	};
};

export default welcome;
