import React from 'react';
import { CircularProgress } from '@mui/material';

function Loading(props) {
	return (
		<div className={props.insideComponent ? '' : 'generic-loading-in-component'}>
			<CircularProgress size={props.size ? props.size : 90} className={props.button ? "generic-loading-progress-buttons" : "generic-loading-progress"} />
		</div>
	);
}

export default Loading;
