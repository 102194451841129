import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { handleSideBar } from '../Generic/actions/genericActions';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CATALOGUES from '../../catalogues';
import Notifications from '../Generic/Notifications';
import { getTranslate } from 'react-localize-redux';
import { Alert } from '@mui/material';
function HideOnScroll(props) {
	const { children, window } = props;

	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export const NavBar = ({ push, handleSideBar, ...props }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);

	const isNotificationsMenuOpen = Boolean(notificationsAnchorEl);

	function handleNotificationsMenuClose() {
		setNotificationsAnchorEl(null);
	}

	function handleNotificationsMenuOpen(event) {
		setNotificationsAnchorEl(event.currentTarget);
	}

	return (
		props.client && (
			<React.Fragment>
				<HideOnScroll {...props}>
					<AppBar
						className="generic-navbar-main"
						sx={{
							...(props.side_bar?.drawer_props?.variant === 'permanent' && { zIndex: (theme) => theme.zIndex.drawer + 1 }),
						}}
					>
						<Container maxWidth={false}>
							<Toolbar sx={{ padding: 1 }}>
								<img
									src={
										props.client.logo &&
										props.client.logo.length > 0 &&
										props.config.apihost + '/auth/clients/' + props.client.id + '/files/' + props.client.logo[0].uuid
									}
									srcSet={
										props.client.logo &&
										props.client.logo.length > 0 &&
										props.config.apihost + '/auth/clients/' + props.client.id + '/files/' + props.client.logo[0].uuid
									}
									alt={props.client.logo && props.client.logo.length > 0 && props.client.logo[0].uuid}
									loading="lazy"
									height={'50px'}
									onClick={() => push(props.whoami.redirect_url ? props.whoami.redirect_url : '/dashboard')}
									style={{ cursor: 'pointer' }}
								/>
								<Box sx={{ flexGrow: 1 }} />
								{props.whoami.scopes.includes('websocket.user.notifications') && (
									<Box sx={{ display: { xs: 'flex' } }}>
										<Notifications
											menuId={'notifications-bell'}
											isMenuOpen={isNotificationsMenuOpen}
											anchorEl={notificationsAnchorEl}
											handleMenuOpen={handleNotificationsMenuOpen}
											handleMenuClose={handleNotificationsMenuClose}
											translate={props.translate}
										/>
									</Box>
								)}
								<Box sx={{ display: { xs: 'flex' } }}>
									<IconButton
										size="large"
										edge="end"
										color="inherit"
										aria-label="account of current user"
										aria-haspopup="true"
										onClick={() => {
											handleSideBar(
												true,
												props.layouts && props.layouts.navbar_sidebar
													? CATALOGUES[props.layouts.navbar_sidebar.toUpperCase()]
													: CATALOGUES.NAVIGATION
											);
										}}
									>
										<MenuIcon />
									</IconButton>
								</Box>
							</Toolbar>
						</Container>
						{props.persistent_alert.open && (
							<Alert
								severity={props.persistent_alert.severity}
								{...{ action: props.persistent_alert.action }}
								{...(props.persistent_alert.icon && { icon: props.persistent_alert.icon })}
							>
								{props.persistent_alert.message}
							</Alert>
						)}
					</AppBar>
				</HideOnScroll>
				<Toolbar />
				{props.persistent_alert.open && <Alert sx={{ visibility: 'hidden' }} />}
			</React.Fragment>
		)
	);
};

NavBar.propTypes = {
	config: PropTypes.object.isRequired,
	client: PropTypes.object,
	whoami: PropTypes.object,
};

const mapStateToProps = (state) => ({
	config: state.config,
	client: state.login.client ? state.settings.clients.find((client) => client.id === state.login.client.id) : null /**TODO:  */,
	whoami: state.users.whoami,
	translate: getTranslate(state.localize),
	persistent_alert: state.generic.persistent_alert,
	layouts: state.generic.layouts,
	side_bar: state.generic.side_bar,
});

const mapDispatchToProps = {
	push,
	handleSideBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
