import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import TICKET from '../../helpers/ticket';
import ACTIVITIES from '../../helpers/activities';
import SURVEYS from '../../helpers/surveys';

function StatusChip({ status, type, translate }) {
	switch (parseInt(status)) {
		case TICKET.STATUS.REFUSED:
			return <Chip className="tickets-chipstatus-refused" label={translate('generic_status_refused')} />;

		case TICKET.STATUS.FAILED:
			return <Chip className="tickets-chipstatus-failed" label={translate('generic_status_failed')} />;

		case TICKET.STATUS.CREATED:
			return <Chip className="tickets-chipstatus-created" label={translate('generic_status_created')} />;

		case TICKET.STATUS.PROCESSED:
			return <Chip className="tickets-chipstatus-processed" label={translate('generic_status_processed')} />;

		case TICKET.STATUS.PENDING:
			if (type == TICKET.TYPES.TYPE_INVITE) {
				return <Chip className="tickets-chipstatus-invite" label={translate('generic_status_invite')} />;
			} else if (type == TICKET.TYPES.FOLLOWUP) {
				return <Chip className="tickets-chipstatus-followup" label={translate('generic_status_followup')} />;
			} else {
				return <Chip className="tickets-chipstatus-pending" label={translate('generic_status_pending')} />;
			}
		case TICKET.STATUS.UNVERIFIED:
			return <Chip className="tickets-chipstatus-unverified" label={translate('generic_status_unverified')} />;
		case TICKET.STATUS.INVITE:
			return <Chip className="tickets-chipstatus-invite" label={translate('generic_status_invite')} />;

		case TICKET.STATUS.PAYED:
			return <Chip className="tickets-chipstatus-payed" label={translate('generic_status_payed')} />;

		case TICKET.STATUS.REJECTED:
			return <Chip className="tickets-chipstatus-rejected" label={translate('generic_status_rejected')} />;

		case TICKET.STATUS.WAITING:
			return <Chip className="tickets-chipstatus-waiting" label={translate('generic_status_waiting')} />;

		case TICKET.STATUS.ACTIVE:
			return <Chip className="tickets-chipstatus-active" label={translate('generic_status_active')} />;

		case TICKET.STATUS.CLOSED:
			return <Chip className="tickets-chipstatus-closed" label={translate('generic_status_closed')} />;

		case TICKET.STATUS.ARCHIVED:
			return <Chip className="tickets-chipstatus-archived" label={translate('generic_status_archived')} />;

		default:
			return null;
	}
}

function PrescriptionsChip({ status, translate }) {
	switch (parseInt(status)) {
		case TICKET.PRESCRIPTIONS.STATUS.PENDING:
			return <Chip className="tickets-prescription-chipstatus-pending" label={translate('ticket_prescription_status_pending')} />;

		case TICKET.PRESCRIPTIONS.STATUS.VALIDATED:
			return <Chip className="tickets-prescription-chipstatus-validated" label={translate('ticket_prescription_status_validated')} />;

		case TICKET.PRESCRIPTIONS.STATUS.REFUSED:
			return <Chip className="tickets-prescription-chipstatus-refused" label={translate('ticket_prescription_status_refused')} />;

		default:
			return null;
	}
}

function ActivitiesStatusChip({ status, translate }) {
	switch (parseInt(status)) {
		case ACTIVITIES.STATUS.ACTIVE:
			return <Chip className="tickets-chipstatus-active" label={translate('generic_status_active')} />;

		case ACTIVITIES.STATUS.CLOSE:
			return <Chip className="tickets-chipstatus-closed" label={translate('generic_status_closed')} />;

		default:
			return null;
	}
}

function SurveysStatusChip({ status, translate }) {
  return null;
	switch (status) {
		case SURVEYS.STATUS.UNSIGNED:
			return <Chip color="default" label={translate('survey_status_unsigned')} />;

		case SURVEYS.STATUS.SIGNED:
			return <Chip color="primary" label={translate('survey_status_signed')} />;

		default:
			return null;
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

const TicketPrescriptionsChip = connect(mapStateToProps)(PrescriptionsChip);
const ActivityStatusChip = connect(mapStateToProps)(ActivitiesStatusChip);
const SurveyStatusChip = connect(mapStateToProps)(SurveysStatusChip);

export default connect(mapStateToProps)(StatusChip);
export { TicketPrescriptionsChip as PrescriptionsChip };
export { ActivityStatusChip as ActivitiesStatusChip };
export { SurveyStatusChip as SurveysStatusChip };
