import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Backdrop, CircularProgress, Typography, Box } from '@mui/material';

class BlockUI extends Component {
	render() {
		return (
			<Backdrop sx={{ color: 'rgb(184, 184, 184)', zIndex: (theme) => theme.zIndex.modal + 1 }} open={this.props.blocking_ui.open}>
				<Box className="loading-indicator">
					<Typography variant="body1" className="block-ui-typography">
						{this.props.translate(this.props.blocking_ui.label)}
					</Typography>
					<CircularProgress />
				</Box>
			</Backdrop>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	blocking_ui: state.generic.blocking_ui,
});

export default connect(mapStateToProps, null)(BlockUI);
