import { FETCH_TICKETS_ANALYTIC, FETCH_TICKET_ANALYTIC_LOGS, CLEAN_TICKET_LOGS } from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	analytic_logs: [],
	fields: [],
	data: [],
	total_count: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_TICKETS_ANALYTIC:
			return {
				...state,
				fields: action.payload.columns,
				data: action.payload.data,
				total_count: action.payload.total_count,
			};
		case FETCH_TICKET_ANALYTIC_LOGS:
			return {
				...state,
				analytic_logs: action.payload,
			};
		case CLEAN_TICKET_LOGS:
			return {
				...state,
				analytic_logs: [],
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
