import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const withMediaQuery = () => Component => props => {
  const width = useWidth();
  const theme = useTheme();
  
  // const mediaQuery = useMediaQuery(...args);

  const useIsWidthUp = (breakpoint) => useMediaQuery(theme.breakpoints.up(breakpoint));
  const useIsWidthDown = (breakpoint) => useMediaQuery(theme.breakpoints.down(breakpoint));

  return <Component useIsWidthUp={useIsWidthUp} useIsWidthDown={useIsWidthDown} width={width} {...props} />;
}

export default withMediaQuery;