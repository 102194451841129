import { store } from '../../../store.js';
import {
	SET_CHANNEL,
	SET_CHANNEL_HISTORY,
	SEND_CHANNEL_MESSAGE,
	FILTER_CHANNEL_MESSAGES,
	// CLEAR_CHANNEL,
	SET_CHANNEL_PARTICIPANTS,
	SET_CHANNEL_NEW_MESSAGE,
	SET_READ_NOTIFICATIONS,
	SUBSCRIBE_CHANNEL,
	UNSUBSCRIBE_CHANNEL,
	SET_WS_CHANNEL_HISTORY,
	PUBLISH,
	DISCONNECT,
	SET_CHANNEL_CALLBACK,
	SET_CONNECTION_TOKEN,
	PARTICIPANT_SUBSCRIBED,
	PARTICIPANT_UNSUBSCRIBED,
	SET_CHANNELS,
  MODIFY,
} from '../actions/types';
import { apiRequest } from '../../../helpers/functions/request.js';

export const setChannelAction = (channel_name, channel, messages, connection_date) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL,
		payload: {
			channel_name,
			data: { channel, messages, connection_date },
		},
	});
};

export const setChannelHistoryAction = (channel_name, messages) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_HISTORY,
		payload: {
			channel_name,
			data: { messages },
		},
	});
};

export const sendChannelMessageAction =
	(channel_name, message, pushMessageToTop = false) =>
	(dispatch) => {
		dispatch({
			type: SEND_CHANNEL_MESSAGE,
			payload: {
				channel_name,
				message,
				pushMessageToTop,
			},
		});
	};

export const filterChannelMessagesAction = (channel_name, messages_to_remove) => (dispatch) => {
	dispatch({
		type: FILTER_CHANNEL_MESSAGES,
		payload: { channel_name, messages_to_remove },
	});
};

export const setChannelParticipantsAction =
	(channel_name, participants = []) =>
	(dispatch) => {
		dispatch({
			type: SET_CHANNEL_PARTICIPANTS,
			payload: {
				channel_name,
				participants,
			},
		});
	};

export const setChannelNewMessagesAction =
	(channel_name, mentioned = false, reset = false) =>
	(dispatch) => {
		dispatch({
			type: SET_CHANNEL_NEW_MESSAGE,
			payload: {
				channel_name,
				reset,
				mentioned,
			},
		});
	};

// export const clearChannelAction = (channel_name) => (dispatch) => {
// 	dispatch({
// 		type: CLEAR_CHANNEL,
// 		payload: { channel_name },
// 	});
// };

export const setReadTicketNotificationsAction = (channel_name, origin_type, origin_id, ref_type, ref_id) => (dispatch) => {
	dispatch({
		type: SET_READ_NOTIFICATIONS,
		payload: { channel_name, origin_type: origin_type, origin_id: origin_id, ref_type: ref_type, ref_id: ref_id },
	});
};

/**
 * Dispatches an action to remove websocket unsubscribed channel
 * @param {string} channel subscribed channel name
 * @param {string} created subscribed at date
 * @returns
 */
export const subscribeChannelAction =
	(channel, created, history, participants, ref_type, ref_id, title, callback = false) =>
	async (dispatch) => {
		await dispatch({
			type: SUBSCRIBE_CHANNEL,
			payload: { channel, history, created, participants, ref_type, ref_id, title },
		});

		if (callback) {
      return callback(channel);
		}
	};

/**
 * Dispatches an action to set a channel participant to online status
 * @param {string} channel subscribed channel name
 * @param {object} sender sender user object
 * @returns
 */
export const participantSubscribedAction = (channel, sender) => (dispatch) => {
	dispatch({
		type: PARTICIPANT_SUBSCRIBED,
		payload: { channel, sender },
	});
};

/**
 * Dispatches an action to set a participant to offline status
 * @param {string} channel subscribed channel name
 * @param {object} sender sender user object
 * @returns
 */
export const participantUnsubscribedAction = (channel, sender) => (dispatch) => {
	dispatch({
		type: PARTICIPANT_UNSUBSCRIBED,
		payload: { channel, sender },
	});
};

/**TODO */
/**
 * Dispatches an action to remove websocket unsubscribed channel
 * @param {string} channel subscribed channel name
 * @param {Function} callback method to call on message for channel
 * @returns
 */
export const setChannelCallbackAction = (channel, callback) => (dispatch) => {
	dispatch({
		type: SET_CHANNEL_CALLBACK,
		payload: {
			channel,
			callback: callback,
		},
	});
};

/**
 * Dispatches an action to remove websocket unsubscribed channel
 * @param {string} channel unsubscribed channel name
 * @returns
 */
export const unsubscribeChannelAction = (channel, callback) => async (dispatch) => {
	await dispatch({
		type: UNSUBSCRIBE_CHANNEL,
		payload: {
			channel,
		},
	});

  if (callback) {
    return callback(channel);
  }
};

/**
 * Dispatches an action to empty websocket reducer on disconnect
 * @returns
 */
export const disconnectAction = (keepToken = false) => (dispatch) => {
	dispatch({
		type: DISCONNECT,
    payload: {
      keepToken
    }
	});
};

/**
 * Dispatches an action to add published websocket message to redux store
 * @param {object} data ws channel message
 * @returns
 */
export const publishChannelAction = (data) => (dispatch) => {
	dispatch({
		type: PUBLISH,
		payload: {
			...data,
		},
	});
};

/**
 * Dispatches an action to remove websocket unsubscribed channel
 * @param {string} channel subscribed channel name
 * @param {string} message message published
 * @param {string} created subscribed at date
 * @param {object} sender object with user data
 * @returns
 */
export const modifyChannelAction = (data) => (dispatch) => {
	dispatch({
		type: MODIFY,
		payload: {
			...data,
		},
	});
};

/**
 * Dispatches an action to remove websocket unsubscribed channel
 * @param {string} channel subscribed channel name
 * @param {array} history contains history of messages on channel
 * @param {array} participants contains participants object on channel
 * @returns
 */
export const setWsChannelHistoryAction = (channel, history, participants) => (dispatch) => {
	dispatch({
		type: SET_WS_CHANNEL_HISTORY,
		payload: {
			channel,
			history,
			participants,
		},
	});
};

/**
 * Dispatches a redux action to establish the websocket connection
 * @returns
 */
export const setWebsocketToken = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/channels/token`,
	});

	dispatch({
		type: SET_CONNECTION_TOKEN,
		payload: response.data,
	});

	return response.data;
};

/**
 * Dispatches a redux action to get the list of available user channels
 * @returns
 */
export const getChannelsAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/channels`,
	});

	dispatch({
		type: SET_CHANNELS,
		payload: response.data,
	});

	return response.data;
};

/**
 * Dispatches a redux action to trigger a ws notification message by calling the api service
 * @returns
 */
export const sendNotificationAction = (message, callback = false) => async (dispatch) => {
	const state = store.getState();
	var data = new FormData();

  Object.entries(message).forEach(([key, value]) => {
    data.append(key, value);
  });

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/events`,
    data
	});

  if (callback) {
    callback();
  }

	return response.data;
};

const websocketActions = {
	setWebsocketToken,
};

export default websocketActions;
