import {
	HANDLE_SPLASH_SCREEN,
	HANDLE_LOADING,
	MODAL_ACTION,
	LIGHTBOX_ACTION,
	DISABLE_FORM_BUTTON_ACTION,
	ADD_WRAPPER,
	ZOOM_BUTTON_LIGHTBOX_ACTION,
	FETCH_ROLES,
	DOCUMENT_PREVIEW,
	PROGRESS_ACTION,
	SET_PROGRESS_ACTION,
	NOTIFICATIONS_MODAL_ACTION,
	HANDLE_BLOCKING_UI,
	PLAYER_ACTION,
	SIDE_BAR,
	MODAL_CATALOGUE,
	FETCH_LAYOUTS,
	FETCH_TAGS,
	PERSISTENT_ALERT,
	FETCH_GROUPS,
  BACKDROP
} from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

const persistent_alert_initial_state = {
	open: false,
	message: null,
	severity: 'error',
	action: null,
  icon: false
};

const side_bar_initial_state = {
	open: false,
	catalogue: undefined,
	drawer_props: undefined,
	info: false,
	variant: 'permanent',
};

const backdrop_initial_state = {
	open: false,
  invisible: false
};

const initialState = {
	layouts: null,
	splash_screen: true,
	loading: false,
	files: [],
	tags: [],
	modal: {
		id: '',
		open: false,
		title: '',
		description: '',
		text: '',
		button_no: '',
		button_no_id: '',
		button_yes: '',
		disable_click_away: false,
		action_no_button: null,
		props_no_button: null,
		action_on_close: null,
		show_button_yes: true,
		show_button_no: true,
		button_yes_id: '',
		action: null,
		props: null,
		max_width: 'sm',
		input: false,
	},
	notifications_modal: {
		open: false,
	},
	progress: {
		open: false,
		value: 0,
		title: '',
		description: '',
		text: '',
		button_cancel: '',
		button_cancel_id: '',
		action_cancel_button: null,
		props_cancel_button: null,
		props: null,
		canceled: false,
	},
	lightbox: {
		open: false,
		image: [],
		disabledZoomButton: false,
		screenshot: null,
	},
	form: {
		disable_button: false,
	},
	wrapper: 1,
	roles: [],
	document_preview: {
		loading: false,
	},
	blocking_ui: { open: false, label: '' },
	player: {
		open: false,
		url: null,
	},
	side_bar: side_bar_initial_state,
	modal_catalogue: {
		open: false,
		catalogue: undefined,
		dialog_props: undefined,
		info: false,
	},
	persistent_alert: persistent_alert_initial_state,
	groups: [],
  backdrop: backdrop_initial_state
};

export default function (state = initialState, action) {
	switch (action.type) {
		case HANDLE_SPLASH_SCREEN:
			return {
				...state,
				splash_screen: action.payload,
			};
		case HANDLE_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case MODAL_ACTION:
			return {
				...state,
				modal: { ...initialState.modal, ...action.payload },
			};
		case LIGHTBOX_ACTION:
			return {
				...state,
				lightbox: {
					open: action.payload.open,
					image: action.payload.image,
					disabledZoomButton: action.payload.disabledZoomButton,
					screenshot: action.payload.screenshot ? action.payload.screenshot : null,
				},
			};
		case ZOOM_BUTTON_LIGHTBOX_ACTION:
			return {
				...state,
				lightbox: {
					...state.lightbox,
					disabledZoomButton: action.payload,
				},
			};
		case DISABLE_FORM_BUTTON_ACTION:
			return {
				...state,
				form: {
					disable_button: action.payload,
				},
			};
		case HANDLE_BLOCKING_UI:
			return {
				...state,
				blocking_ui: { open: action.payload.open, label: action.payload.label },
			};
		case ADD_WRAPPER:
			return {
				...state,
				wrapper: action.payload,
			};
		case FETCH_ROLES:
			return {
				...state,
				roles: { ...state.roles, [action.payload.type]: action.payload.data },
			};
		case DOCUMENT_PREVIEW:
			return {
				...state,
				document_preview: { ...state.document_preview, ...action.payload },
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
				...{ splash_screen: false },
			};
		case PROGRESS_ACTION:
			return {
				...state,
				progress: { ...initialState.progress, ...action.payload },
			};
		case SET_PROGRESS_ACTION:
			return {
				...state,
				progress: { ...state.progress, value: action.payload },
			};
		case NOTIFICATIONS_MODAL_ACTION:
			return {
				...state,
				notifications_modal: { ...initialState.notifications_modal, ...action.payload },
			};
		case PLAYER_ACTION:
			return {
				...state,
				player: {
					open: action.payload.open,
					url: action.payload.url,
				},
			};
		case SIDE_BAR:
			return {
				...state,
				side_bar: {
					open: action.payload.open,
					catalogue: action.payload.catalogue,
					drawer_props: {
						...side_bar_initial_state.drawer_props,
						...action.payload.drawer_props,
					},
					info: action.payload.info,
				},
			};
		case MODAL_CATALOGUE:
			return {
				...state,
				modal_catalogue: {
					open: action.payload.open,
					catalogue: action.payload.catalogue,
					dialog_props: {
						...initialState.modal_catalogue.dialog_props,
						...action.payload.dialog_props,
					},
					info: action.payload.info,
				},
			};
		case FETCH_LAYOUTS:
			return {
				...state,
				layouts: action.payload,
			};
		case FETCH_TAGS:
			return {
				...state,
				tags: action.payload,
			};
		case PERSISTENT_ALERT:
			return { ...state, ...persistent_alert_initial_state, persistent_alert: action.payload };
		case BACKDROP:
			return { ...state, ...backdrop_initial_state, backdrop: action.payload };
		case FETCH_GROUPS:
			return {
				...state,
				groups: action.payload,
			};
		default:
			return state;
	}
}
