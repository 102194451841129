import React, { Suspense, lazy, useMemo } from 'react';
import { TextField, FormHelperText, MenuItem, Typography } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useSelector } from 'react-redux';
import { getActiveLanguage } from 'react-localize-redux';
import { CircularProgress } from '@mui/material';
import country_flags from '../../../helpers/country_flags';

function RenderPhoneField({ input, meta: { invalid, touched, error, active }, ...custom }) {
	const { language, available_languages } = useSelector((state) => ({
		language: getActiveLanguage(state.localize).code,
		available_languages: state.settings.site.available_languages
			.map((available_language) => available_language.code.split('-')[1])
			.filter((available_language) => available_language),
	}));

	return (
		<div>
			{custom.inputdata.title && <Typography>{custom.inputdata.title}</Typography>}
			<MuiTelInput
				{...input}
        disableFormatting
				getFlagElement={(isoCode, { imgProps, countryName, isSelected }) => {
					return <img width="26" src={country_flags[isoCode]} aria-label={countryName} />;
				}}
				preferredCountries={available_languages}
				langOfCountryName={language}
				defaultCountry={language.split('-')[1]}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				label={custom.inputdata.label}
				placeholder={custom.inputdata.placeholder}
				fullWidth
				variant="outlined"
				disabled={custom.inputdata.readonly}
				error={touched && invalid && !active}
				helperText={touched && !active && error}
				onChange={(event, info) => {
					input.onChange(event);
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(event.target.value);
					}
				}}
				type={custom.inputdata.type}
				size={custom.inputdata.size ? custom.inputdata.size : null}
				{...custom}
				inputProps={{
					step: custom.inputdata.props ? custom.inputdata.props.step : null,
					min: custom.inputdata.props ? custom.inputdata.props.min : null,
					maxLength: custom.inputdata.props ? custom.inputdata.props.maxLength : null,
					className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
				}}
			/>
			<FormHelperText id="helper-text-phone-field"> {custom.inputdata.helperText}</FormHelperText>
		</div>
	);
}

export default RenderPhoneField;
