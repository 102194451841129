import { Dialog } from '@mui/material';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';

class Player extends Component {
	render() {
		return (
			<Dialog open={this.props.open} onClose={this.props.onClose} maxWidth={'lg'}>
				<ReactPlayer
					url={this.props.url}
					controls
					playing
					width="100%"
					height="100%"
				/>
			</Dialog>
		);
	}
}


export default Player;