const block_ui = (theme) => {
	return {
		'.loading-indicator': {
			textAlign: 'center',
		},

		'.block-ui': {
			position: 'relative',
			minHeight: '3em',
		},
		
		'.block-ui-typography': {
			fontWeight: 'bold'
		},

		'.block-ui-container': {
			position: 'fixed',
			zIndex: 10050,
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			height: '100%',
			width: '100%',
			minHeight: '2em',
			cursor: 'wait',
			overflow: 'hidden',
		},

		'.block-ui-container:focus': {
			outline: 'none',
		},

		'.block-ui-overlay': {
			width: '100%',
			height: '100%',
			opacity: '0.75',
			filter: 'alpha(opacity=50)',
			backgroundColor: 'rgb(184, 184, 184)',
		},

		'.block-ui-message-container': {
			position: 'absolute',
			top: '50%',
			left: '0',
			right: '0',
			textAlign: 'center',
			transform: 'translateY(-50%)',
			zIndex: '10001',
		},

		'.block-ui-message': {
			color: '#333',
			background: 'none',
			zIndex: '1011',
		},

		'#indicator': {
			width: '100px',
			height: '100px',
			position: 'absolute',
			top: 'calc(50% - 50px)',
			left: 'calc(50% - 50px)',
			animation: 'spin 1s linear infinite',
		},

		'#circle': {
			fill: 'none',
			stroke: theme.palette.primary.main,
			strokeLinecap: 'round',
			strokeWidth: '4',
			animation: 'draw 3s ease-in-out infinite',
		},

		'@keyframes draw': {
			'0%': {
				strokeDasharray: '20, 282.6',
			},
			'50%': {
				strokeDasharray: '200, 282.6',
			},
			'100%': {
				strokeDasharray: '20, 282.6',
			},
		},

		'@keyframes spin': {
			to: {
				transform: 'rotate(360deg)',
			},
		},
	};
};

export default block_ui;
