import { checkVersion } from './check_version';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport, name) {
	return new Promise((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');

		const isProduction = process.env.NODE_ENV === 'production';
    
		// try to import the component
		componentImport()
			.then(async (component) => {
				if (isProduction) {
					window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
          return checkVersion({callback: () => resolve(component)});
				} else {
					return resolve(component);
				}
			})
			.catch(async (error) => {
				console.log(error);
				if (isProduction) {
					console.log(hasRefreshed);
					if (!hasRefreshed) {
						// not been refreshed yet
						window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
						/**show popup */

						return checkVersion({force_refresh: true});
					}
					return reject(error); // Default error behaviour as already tried refresh
				}
				return reject(error); // Default error behaviour as already tried refresh
			});
	});
};

export { lazyRetry };
