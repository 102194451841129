import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormHelperText, Checkbox, Tooltip, Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const RenderCustomCheckboxGroup = React.forwardRef(({ inputdata, validate, onChangeValue }, ref) => {
	const handleChange = (event) => (inputValue, value, onChange) => {
		const arr = [...(inputValue ? inputValue : [])];
		if (!arr.includes(value)) {
			arr.push(value);
		} else {
			arr.splice(arr.indexOf(value), 1);
		}

		if (typeof inputdata.onChangeValue !== 'undefined') {
			inputdata.onChangeValue(arr);
		}

		return onChange(arr);
	};

	const checkbox = (option, props) => (value, onChange) => {
		const checked = value.includes(option.value);
		return (
			<Checkbox
				id={option.label}
				key={option.value}
				name={option.label}
				value={option.value}
				icon={option.icon}
				checkedIcon={option.checked_icon}
				checked={checked}
				onChange={(event) => handleChange(event)(value, option.value, onChange)}
				{...props}
			/>
		);
	};

	return (
		<Field
			{...inputdata}
			type="checkbox"
			component={({ input: { name, onChange, value }, meta: { touched, error, invalid, active } }) => (
				<FormControl fullWidth error={touched && invalid && !active}>
					<FormLabel component="legend">
						<Typography sx={{ fontSize: '18px', color: '#1A1818', mb: 2 }}>{inputdata.label}</Typography>
						{inputdata.infoTooltip && (
							<Tooltip title={inputdata.infoTooltip} placement="right-end" color="secondary">
								<InfoIcon size="small" />
							</Tooltip>
						)}
					</FormLabel>
					<Box sx={{ width: '100%' }}>
						{inputdata.renderCheckbox((event) => handleChange(event), onChange)(
							(option, props) => checkbox(option, props)(value, onChange),
							inputdata.options
						)}
					</Box>
					<FormHelperText>{touched && !active && error}</FormHelperText>
				</FormControl>
			)}
			validate={validate}
			ref={ref} // Forward the ref
		/>
	);
});

RenderCustomCheckboxGroup.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
};

export default RenderCustomCheckboxGroup;
