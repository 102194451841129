import React from 'react';
import { lazyRetry } from './helpers/functions/lazy_loading';

const DefaultLayout = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Layout/DefaultLayout'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'default-layout')
);
const TicketLayout = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Tickets/Layout'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'tickets-layout')
);
const MyCases = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Tickets/List/MyCases'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'my-cases')
);
const AnalyticTicketsLogs = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Analytic/AnalyticTicketsLogs'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'analytics-ticket-logs')
);
const WaitingRoom = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/List/WaitingRoom'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'waiting-room')
);
const AllCases = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/List/AllCases'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'all-cases')
);
const CountdownWrapp = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./helpers/CountdownWrapp'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'countdown-wrapp')
);
const History = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/Overview/History'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'tickets-history')
);
const Dashboard = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Dashboard/Dashboard'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'dashboard')
);
const WardSelectionForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/New/WardSelectionForm'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'ward-selection-form')
);
const WardPatientForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/New/WardPatientForm'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'ward-patient-form')
);
const PatientForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/New/PatientForm'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'patient-form')
);
const SelectCondition = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/New/SelectCondition'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'select-condition')
);
const FollowupQuestionnaires = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/Overview/FollowupQuestionnaires'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'followup-questionnaire')
);
const FailedTriage = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Tickets/New/FailedTriage'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'failed-triage')
);
const SummaryPayment = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Tickets/New/Payments'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'ticket-payments')
);
const Questionnaires = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Questionnaires/Questionnaires'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'ticket-questionnaires')
);
const Activity = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Activities/Activity'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'activity')
);
const ActivityForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Activities/ActivityForm'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'activity-form')
);
const EventMeeting = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Activities/EventMeeting'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'event-meeting')
);
const Activities = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Activities/Activities'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'activities')
);
const Library = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Libraries/Library'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'library')
);
const LibraryManagement = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Libraries/LibraryManagement'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'library-management')
);
const LibraryFileForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Libraries/FileForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'file-form')
);
const QlikSenseApp = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Statistics/QlikSenseApp'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'qliksense-app')
);
const EventForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([
			import('./components/Users/MyPages/Calendar/EventForm'),
			new Promise((resolve) => setTimeout(resolve, 1000)),
		]);
		return moduleExports;
	}, 'event-form')
);
const Meeting = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Meetings/Meeting'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'meeting')
);
const POA = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/POA/PoaOverview'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'poa-overview')
);
const SDK = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/SDK/Layout'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'sdk-layout')
);
const InviteSingleForm = React.lazy(() =>
	lazyRetry(async () => {
		const [moduleExports] = await Promise.all([import('./components/Tickets/Invite/InviteSingleForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
		return moduleExports;
	}, 'invite-single-form')
);

export {
	DefaultLayout,
	MyCases,
	AnalyticTicketsLogs,
	SelectCondition,
	TicketLayout,
	WaitingRoom,
	AllCases,
	History,
	CountdownWrapp,
	Dashboard,
	WardSelectionForm,
	WardPatientForm,
	PatientForm,
	FollowupQuestionnaires,
	FailedTriage,
	SummaryPayment,
	Questionnaires,
	Activity,
	ActivityForm,
	EventMeeting,
	Activities,
	Library,
	LibraryManagement,
	LibraryFileForm,
	QlikSenseApp,
	EventForm,
	Meeting,
	POA,
	SDK,
  InviteSingleForm
};
