import { replaceObjectInNestedArray } from '../../../helpers/functions/functions';
import { FETCH_SURVEYS, SET_SURVEY, UPDATE_QUESTION, CLEAR_SURVEY } from '../actions/types';

const surveyInitialState = {
  id: null,
  name: '',
  completed: '0',
  questions: [],
  survey_id: null,
  users_id: null,
}

const initialState = {
	items: [],
	current: 1,
	first: 1,
	last: 1,
	limit: 10,
	next: 1,
	previous: 1,
	total_items: 0,
	survey: surveyInitialState,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_SURVEYS:
			return {
				...state,
				...action.payload,
			};
		case SET_SURVEY:
			return {
				...state,
				survey: { ...state.survey, ...action.payload },
			};
		case UPDATE_QUESTION:
			let auxQuestions = replaceObjectInNestedArray({
				arr: [...state.questions],
				key: 'id',
				value: action.payload.question_id,
				replacement: action.payload.data,
			});

			return {
				...state,
				survey: { ...state.survey, ...{ questions: auxQuestions } },
			};
		case CLEAR_SURVEY:
			return {
				...state,
				survey: surveyInitialState,
			};
		default:
			return state;
	}
}
