import React from 'react';
import { TextField, FormHelperText, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

function RenderTextFieldMultiple({ input, meta: { invalid, touched, error, active }, ...custom }) {
	let value = input.value.length === 0 ? [] : input.value;

	const translate = useSelector((state) => getTranslate(state.localize));
	const [allValue, setAllValue] = React.useState(value.length === custom.inputdata.options.length);

	const handleAllValue = (flag) => {
		setAllValue(flag);
	};

	return (
		<div>
			<TextField
				{...input}
				value={value}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				label={custom.inputdata.label}
				placeholder={custom.inputdata.placeholder}
				fullWidth
				variant="outlined"
				disabled={custom.inputdata.readonly}
        error={touched && invalid && !active}
        helperText={touched && !active && error}
				onChange={(event) => {
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(event.target.value);
					}

					if (event.target.value.filter((value) => value !== 'all').length === custom.inputdata.options.length && allValue) {
						return input.onChange([]);
					} else if (event.target.value.includes('all')) {
						return input.onChange(custom.inputdata.options.map((option) => option.value));
					} else if (event.target.value.length === custom.inputdata.options.length && !allValue) {
						handleAllValue(true);
					} else {
						handleAllValue(false);
					}

					return input.onChange(event.target.value.filter((value) => value !== 'all'));
				}}
				SelectProps={{
					multiple: true,
					renderValue: (selected) => {
						return selected.map((value) => custom.inputdata.options.find((option) => option.value == value).label).join(', ');
					},
				}}
				type={custom.inputdata.type}
				size={custom.inputdata.size ? custom.inputdata.size : null}
				{...custom}
				inputProps={{
					step: custom.inputdata.props ? custom.inputdata.props.step : null,
					min: custom.inputdata.props ? custom.inputdata.props.min : null,
					className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
				}}
			>
				<MenuItem
					key={'all'}
					value={'all'}
					onClick={() => {
						handleAllValue(!allValue);
					}}
				>
					<Checkbox checked={allValue} />
					<ListItemText primary={translate('form_field_select_multiple_all_value')} />
				</MenuItem>
				{custom.inputdata.options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						<Checkbox checked={value.includes(option.value)} />
						<ListItemText primary={option.label} />
					</MenuItem>
				))}
			</TextField>
			<FormHelperText id="my-helper-text"> {custom.inputdata.helperText}</FormHelperText>
		</div>
		// required={(custom.inputdata.validate && custom.inputdata.validate.required) ? true : false}
	);
}

export default RenderTextFieldMultiple;
