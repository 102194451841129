import CalendarImage from '../images/calendar.jpeg';
import new_case_iris_image from '../images/woman_reading.png';
import new_case_image from '../images/new_case_widget.jpg';
import emergency_phone from '../images/accessduo-logo-green.png';

let new_case_iris_image_obj = new Image();
new_case_iris_image_obj.src = new_case_iris_image;
let irisImageHeight = new_case_iris_image_obj.height;

let new_case_image_obj = new Image();
new_case_image_obj.src = new_case_image;
let imageHeight = new_case_image_obj.height;

const dashboard = (theme) => {
	return {
		'#dashboard-main-container .react-grid-layout': {
			[theme.breakpoints.down('xl')]: {
				height: 'auto !important',
			},
		},
		'.widgets-content': {
			overflow: 'auto',

			[theme.breakpoints.down('xl')]: {
				width: 'auto !important',
				height: 'fit-content !important',
				position: 'relative !important',
				transform: 'none !important',
				marginBottom: theme.spacing(4) + ' !important',
			},
		},
		'.widgets-card': {
			border: 'unset',
		},
		'.widgets-card-header': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
		},
		'.widgets-card-header-divider': {
			backgroundColor: theme.palette.secondary.main,
		},
		'.widgets-card-header-secondary': {
			backgroundColor: theme.palette.grey[300],
			color: 'black',
		},
		'.widgets-card-header-avatar-secondary': {
			backgroundColor: theme.palette.primary.main,
			// color: theme.palette.primary.main
		},

		'.widgets-card-actions': {
			display: 'block',
			textAlign: 'center',
		},

		'.widgets-buttongroup': {
			height: '100%',
		},

		'.widgets-button-root': {
			display: 'flex',
			flexWrap: 'wrap',
			width: '100%',
		},
		'.widgets-button-image': {
			position: 'unset',
			height: '-webkit-fill-available',
			width: '100%',
			[theme.breakpoints.down('md')]: {
				width: '100% !important', // Overrides inline-style
				height: '-webkit-fill-available',
			},
			'&:hover, &.widgets-button-focusVisible': {
				zIndex: 1,
				'& .widgets-button-imageBackdrop': {
					opacity: 0.15,
				},
				'& .widgets-button-imageMarked': {
					opacity: 0,
				},
				'& .widgets-button-imageTitle': {
					border: '4px solid currentColor',
				},
			},
		},
		'.widgets-button-focusVisible': {},
		'.widgets-button-imageButton': {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.common.white,
		},
		'.widgets-button-imageSrc': {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			backgroundSize: 'cover',
			backgroundPosition: 'center 40%',
			backgroundImage: `url(${CalendarImage})`,
		},
		'.widgets-button-imageBackdrop': {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			backgroundColor: theme.palette.common.black,
			opacity: 0.4,
			transition: theme.transitions.create('opacity'),
		},
		'.widgets-button-imageTitle': {
			position: 'relative',
			padding: `${theme.spacing(2)} ${theme.spacing(4)} calc(${theme.spacing(1)} + 6px)`,
		},
		'.widgets-button-imageMarked': {
			height: 3,
			width: 18,
			backgroundColor: theme.palette.common.white,
			position: 'absolute',
			bottom: -2,
			left: 'calc(50% - 9px)',
			transition: theme.transitions.create('opacity'),
		},

		'.widgets-button-secondary': {
			color: theme.palette.primary.main,
		},
		'.mydashboard-widget-content': {
			boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
		},

		'.mydashboard-widget-content-title': {
			margin: '0',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		'.mydashboard-widget-content-remove-button': {
			position: 'absolute',
			right: '7px',
			top: '5px',
			cursor: 'pointer',
		},
		'.widgets-card-content': {
			padding: '10px',
		},
		'.widgets-card-container': {
			width: '100%',
			height: '100%',
		},
		'.widgets-flex-box-container': {
			height: '100%',
		},
		'.widgets-new-case-iris-image': {
			cursor: 'pointer',
			height: '40%',
			backgroundImage: `url('${new_case_iris_image}')`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			[theme.breakpoints.down('xl')]: {
				minHeight: irisImageHeight / 2,
			},
			backgroundPosition: 'center',
		},
		'.widgets-new-case-image': {
			cursor: 'pointer',
			height: '40%',
			backgroundImage: `url('${new_case_image}')`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			[theme.breakpoints.down('xl')]: {
				minHeight: imageHeight / 2,
			},
			backgroundPosition: 'center',
		},
		'.widgets-new-case-iris-container-text-align': {
			display: 'flex',
			height: '40%',
			justifyContent: 'center',
			alignItems: 'center',
			padding: theme.spacing(2),
		},
		'.widgets-container-button-align': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: theme.spacing(2),
		},
		'.widgets-emergency-phone-container-text-align': {
			display: 'flex',
			height: '70%',
			justifyContent: 'center',
			alignItems: 'center',
			padding: theme.spacing(2),
		},
		'.widgets-emergency-phone-image': {
			backgroundImage: `url('${emergency_phone}')`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			minHeight: '100px',
			height: '100%',
		},
		'.widgets-content-nhs': {
			overflow: 'auto',
			[theme.breakpoints.down('xl')]: {
				width: 'auto !important',
				height: 'fit-content !important',
				position: 'relative !important',
				transform: 'none !important',
			},
		},
	};
};

export default dashboard;
