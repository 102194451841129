import WIDGETS from './widgets';

export default {
  name: 'smart_goals_weight',
	widgets: [
		{ content: WIDGETS.GOBACKSMARTGOALS, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.SETWEIGHTGOALS, breakpoints: { xs: 12, sm: 6 }, lazy_loading: 0 },
    { content: WIDGETS.SETQUALITYGOALS, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
	],
};
