import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid } from '@mui/material';

class Column extends Component {
	render() {
		return (
			<Grid key={this.props.form + '_column_' + this.props.i} id={this.props.id} container item {...this.props.grid_layout}>
				{this.props.children}
			</Grid>
		);
	}
}

export default Column;

Column.propTypes = {
	id: PropTypes.string.isRequired,
	text: PropTypes.string,
	grid_layout: PropTypes.object.isRequired,
	separators: PropTypes.array.isRequired,
};
