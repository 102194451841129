import WIDGETS from './widgets';

export default {
  name: 'daily_report',
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKDAILYREPORT, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.DAILYREPORTS, breakpoints: { xs: 12, sm: 12 } },
	],
};
