import {
	FETCH_WEIGHT_LOGS,
	FETCH_WEIGHT_SUMMARY,
	FETCH_EXERCISE_SUMMARY,
	FETCH_WEIGHT_GRAPH,
	FETCH_DAILY_LOG,
	FETCH_LATEST_DAILY_LOG,
	FETCH_FOOD_LOGS,
	FETCH_MOOD_LOGS,
	FETCH_EXERCISE_LOGS,
	FETCH_MOOD_SUMMARY,
} from '../actions/types';

import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	weight_logs: null,
	weight_summary: null,
	exercise_summary: null,
	weight_graph: [],
	latest_daily_log: null,
	daily_log: null,
	food_logs: null,
	mood_logs: null,
	exercise_logs: null,
	mood_summary: null,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_WEIGHT_LOGS:
			return {
				...state,
				weight_logs: action.payload,
			};
		case FETCH_FOOD_LOGS:
			return {
				...state,
				food_logs: action.payload,
			};
		case FETCH_MOOD_LOGS:
			return {
				...state,
				mood_logs: action.payload,
			};
		case FETCH_EXERCISE_LOGS:
			return {
				...state,
				exercise_logs: action.payload,
			};
		case FETCH_WEIGHT_SUMMARY:
			return {
				...state,
				weight_summary: action.payload,
			};
		case FETCH_EXERCISE_SUMMARY:
			return {
				...state,
				exercise_summary: action.payload,
			};
		case FETCH_MOOD_SUMMARY:
			return {
				...state,
				mood_summary: action.payload,
			};
		case FETCH_WEIGHT_GRAPH:
			return {
				...state,
				weight_graph: action.payload,
			};
		case FETCH_DAILY_LOG:
			return {
				...state,
				daily_log: action.payload,
			};
		case FETCH_LATEST_DAILY_LOG:
			return {
				...state,
				latest_daily_log: action.payload,
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
