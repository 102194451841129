import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimerProvider from './IdleTimerProvider';
import { logoutAction, refreshTokenAction, refreshTokenExpireDate } from './components/Login/actions/loginActions';
import { displayModalAction, closeModalAction } from './components/Generic/actions/genericActions';
import Countdown from 'react-countdown';
import { getTranslate } from 'react-localize-redux';

/** IdleTimer implementation. Handles user inactivity and token refresh. */
class IdleTimer extends Component {
	constructor(props) {
		super(props);

		this.idleTimer = null;
		this.intervalTimeLeft = null;

		this.refresh_interval = 5000;

		this.state = {
			prevent_refresh: false,
		};
	}

	componentDidMount() {
    if (this.props.inactivity_timeout && this.props.inactivity_timeout != '0') {
      if (this.props.login.login) {
        setTimeout(() => {
          this.idleTimer.start();
        }, 1000);
      }
    }
  
      this.intervalTimeLeft = setInterval(() => {
        this.handleRefreshToken();
      }, this.refresh_interval);
	}

	/** Handle token refresh logic and calls store refresh method if needed. */
	handleRefreshToken = () => {
		/*
      If nothing is given to Date() on construction it will default to UTC and consequently
      a call to `getTime` will return UTC. However, when you feed it a string that doesn't
      contain timezone information it will default to the user timezone not UTC.

      The token expiration timestamp is returned as a string without timezone information from 
      the api. Due to some string mangling happening elsewhere in the frontend the api value 
      can't be corrected and instead we have to deal with it here.
    */

		const utc_ts = new Date().getTime();

		if (this.props.login.token_expires_at && (!this.idleTimer || this.idleTimer.isLeader() || this.props.publisher.session)) {
			let [expire_date, expire_time] = this.props.login.token_expires_at.split(' ');
			let stitch = expire_date + 'T' + expire_time + 'Z';

			if (new Date(stitch).getTime() - (this.refresh_interval * 2) - utc_ts < 0) {
				if (!this.state.prevent_refresh) {
					this.setState({ prevent_refresh: true }, () => {
						setTimeout(
							() => {
								this.props.refreshTokenAction(() => {
									this.setState({ prevent_refresh: false });
                  if (this.idleTimer) {
                    this.idleTimer.message({ data: 'on video conference', actions: ['refresh_token_expires_at'] });
                  }
								});
							},
							this.idleTimer && !this.idleTimer.isLeader() && this.props.publisher.session ? 3000 : 0
						);
					});
				}
			}
		}
	};

	componentWillUnmount() {
		clearInterval(this.intervalTimeLeft);
	}

	componentDidUpdate(prevProps) {
    if (this.props.inactivity_timeout && this.props.inactivity_timeout != '0') {
        if (!prevProps.login.login && this.props.login.login) {
          this.idleTimer.start();
          this.idleTimer.message({ data: 'logged on another tab', actions: ['login'] });
        } else if (prevProps.login.login && !this.props.login.login) {
          this.idleTimer.pause();
          this.idleTimer.message({ data: 'logged out on another tab', actions: ['force_logout'] });
        }
      }
	}

	onMessage = (message) => {
		if (message.actions.includes('force_logout')) {
			this.props.logoutAction(true, this.props.login.type !== 'meeting');
		}
		if (message.actions.includes('logout')) {
			this.props.logoutAction(true, this.props.login.type !== 'meeting', this.props.translate('inactivity_logout_info_message'));
		}
		if (message.actions.includes('close_modal')) {
			this.props.closeModalAction();
		}
		if (message.actions.includes('start')) {
			this.idleTimer.start();
		}
		if (message.actions.includes('refresh_token_expires_at')) {
			setTimeout(() => {
				this.props.refreshTokenExpireDate();
			}, 1000);
		}
		if (!this.props.login.login && message.actions.includes('login')) {
			window.location.reload(false);
		}
	};

	onPrompt = () => {
		if (this.props.publisher.session) {
			this.idleTimer.message({ data: 'on video conference', actions: ['start', 'close_modal'] }, true);
		} else {
			// Fire a Modal Prompt
			this.props.displayModalAction({
				id: 'inactivity_modal',
				title: this.props.translate('inactivity_check_title'),
				description: (
					<React.Fragment>
						{this.props.translate('inactivity_check_description', {
							counter: (
								<Countdown
									renderer={({ hours, minutes, seconds, completed }) => (
										<span>
											{hours !== 0 ? hours + ':' : ''}
											{minutes}:{seconds}
										</span>
									)}
									daysInHours={true}
									date={Date.now() + this.props.inactivity_prompt_timeout}
								/>
							),
						})}
					</React.Fragment>
				),
				button_no: this.props.translate('generic_button_logout'),
				button_yes: this.props.translate('generic_yes'),
				button_no_id: 'modal_idle_logout_button',
				action_no_button: () => {
					this.idleTimer.message({ data: 'log out', actions: ['force_logout'] }, true);
				},
				action: () => {
					this.idleTimer.message({ data: 'close modal', actions: ['close_modal', 'refresh_token_expires_at'] });
				},
				action_on_close: () => {
					this.idleTimer.message({ data: 'close modal', actions: ['close_modal', 'refresh_token_expires_at'] });
				},
			});
		}
	};

	onIdle = () => {
		this.idleTimer.message({ data: 'log out', actions: ['logout'] }, true);
		this.props.closeModalAction();
	};

	onActive = (event) => {};

	onAction = (event) => {
		if (this.idleTimer.isIdle()) {
			this.props.closeModalAction();
			this.idleTimer.message({ data: 'log out', actions: ['logout'] }, true);
		} else if (
			this.props.login.login &&
			this.idleTimer.isPrompted() &&
			event.target.id !== 'modal_idle_logout_button' &&
			event.target.parentElement.id !== 'modal_idle_logout_button'
		) {
			this.idleTimer.message({ data: 'close modal', actions: ['close_modal', 'start'] }, true);
		}
	};

	render() {
		return (
			this.props.inactivity_timeout &&
			this.props.inactivity_timeout != '0' &&
			this.props.inactivity_prompt_timeout ? (
				<IdleTimerProvider
					ref={(ref) => {
						this.idleTimer = ref;
					}}
					timeout={this.props.inactivity_timeout}
					promptTimeout={this.props.inactivity_prompt_timeout}
					events={[
						'keydown',
						'wheel',
						'DOMMouseScroll',
						'mousewheel',
						'mousedown',
						'touchstart',
						'touchmove',
						'MSPointerDown',
						'MSPointerMove',
					]}
					onPrompt={this.onPrompt}
					onIdle={this.onIdle}
					onAction={this.onAction}
					onActive={this.onActive}
					onMessage={this.onMessage}
					startManually={true}
					crossTab={true}
					stopOnIdle={true}
					startOnMount={false}
					syncTimers={200}
					leaderElection={true}
				>
					{this.props.children}
				</IdleTimerProvider>
			) : this.props.children
		);
	}
}

const mapStateToProps = (state) => ({
	timezone: state.users.whoami.timezone,
	publisher: state.publisher,
	login: state.login,
	inactivity_timeout: typeof state.settings.auth !== 'undefined' ? state.settings.auth.inactivity_timeout : 600000,
	inactivity_prompt_timeout: typeof state.settings.auth !== 'undefined' ? state.settings.auth.inactivity_prompt_timeout : 10000,
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, { logoutAction, refreshTokenAction, displayModalAction, closeModalAction, refreshTokenExpireDate })(
	IdleTimer
);
