import React, { useState, useEffect } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../Generic/Form/MainReinitialize';
import { TYPES as FORM_TYPES } from '../../Generic/FormTypes';
import { reduxForm, formValueSelector } from 'redux-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Typography, Grid, Container } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const UserProfile = ({ registration_fields, settings, user, login, onSubmitSuccess, password_field, password_verify, groups, ...props }) => {
	const [consented, setConsented] = useState(user !== null ? user.consented : 0);
	const [charNumberValid, setCharNumberValid] = useState(false);
	const [specialCharValid, setSpecialCharValid] = useState(false);
	const [uppercaseValid, setUppercaseValid] = useState(false);
	const [numberValid, setNumberValid] = useState(false);
	const [visibility, setVisibility] = useState(false);
	const [valid, setValid] = useState(false);

	// Check the length of the input
	const checkPasswordLength = (password) => {
		if (
			typeof settings.site.password_complexity === 'undefined' ||
			settings.site.password_complexity === null ||
			password.length >= settings.site.password_complexity.length ||
			!settings.site.password_complexity.length
		) {
			setCharNumberValid(true);
		} else {
			setCharNumberValid(false);
		}
	};

	// Check for special characters
	const checkSpecialCharacters = (password) => {
		const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
		const valSC = (password.match(pattern) || []).length;
		if (
			typeof settings.site.password_complexity === 'undefined' ||
			settings.site.password_complexity === null ||
			valSC >= settings.site.password_complexity.special_characters ||
			!settings.site.password_complexity.special_characters
		) {
			setSpecialCharValid(true);
		} else {
			setSpecialCharValid(false);
		}
	};

	// Check for an uppercase character
	const checkUppercase = (password) => {
		const pattern = /[A-Z]/g;
		const valU = (password.match(pattern) || []).length;

		if (
			typeof settings.site.password_complexity === 'undefined' ||
			settings.site.password_complexity === null ||
			valU >= settings.site.password_complexity.uppercase ||
			!settings.site.password_complexity.uppercase
		) {
			setUppercaseValid(true);
		} else {
			setUppercaseValid(false);
		}
	};

	// Check for a number
	const checkNumber = (password) => {
		const pattern = /[0-9]/g;
		const valN = (password.match(pattern) || []).length;
		if (
			typeof settings.site.password_complexity === 'undefined' ||
			settings.site.password_complexity === null ||
			valN >= settings.site.password_complexity.numbers ||
			!settings.site.password_complexity.numbers
		) {
			setNumberValid(true);
		} else {
			setNumberValid(false);
		}
	};

	const handlePasswordChange = (value) => {
		checkPasswordLength(value);
		checkSpecialCharacters(value);
		checkUppercase(value);
		checkNumber(value);
		setValid(password_verify === value);
	};

	const getPasswordComplexityLayout = () => {
		const { translate } = props;

		return (
			<Container maxWidth="lg">
				<Grid container>
					{!settings.site.password_complexity.length || !settings.site.password_complexity.length === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{charNumberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_carachters', {
									length: settings.site.password_complexity.length,
								})}
							</Typography>
						</Grid>
					)}
					{!settings.site.password_complexity.special_characters || settings.site.password_complexity.special_characters === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{specialCharValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_special_carachters', {
									length: settings.site.password_complexity.special_characters,
								})}
							</Typography>
						</Grid>
					)}
					{!settings.site.password_complexity.uppercase || settings.site.password_complexity.uppercase === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{uppercaseValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_change_password_uppercase_letter', {
									length: settings.site.password_complexity.uppercase,
								})}
							</Typography>
						</Grid>
					)}
					{!settings.site.password_complexity.numbers || settings.site.password_complexity.numbers === 0 ? (
						''
					) : (
						<Grid item md={3} xs={12}>
							<Typography align="justify" variant="subtitle2" paragraph={true}>
								{numberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
								{translate('user_rchange_password_number', {
									length: settings.site.password_complexity.numbers,
								})}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Container>
		);
	};

	const handleConfig = () => {
		const { translate } = props;

		const structure = {
			form: 'create_new_account_user_profile',
			id: 'create_new_account_user_profile',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'xl',
			fields_grid_layout: {
				xs: 12,
			},
			buttons_grid_layout: {
				xs: 12,
				content_align: 'center',
				spacing: 2,
			},
			columns: [
				{
					id: 'column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'first_name',
									name: 'first_name',
									label: translate('users_register_form_myprofile_field_first_name'),
									type:
										registration_fields.find((elem) => elem.field == 'first_name') &&
										registration_fields.find((elem) => elem.field == 'first_name').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'first_name') &&
											registration_fields.find((elem) => elem.field == 'first_name').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'last_name',
									name: 'last_name',
									label: translate('users_register_form_myprofile_field_last_name'),
									type:
										registration_fields.find((elem) => elem.field == 'last_name') &&
										registration_fields.find((elem) => elem.field == 'last_name').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'last_name') &&
											registration_fields.find((elem) => elem.field == 'last_name').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'gender',
									name: 'gender',
									label: translate('users_register_form_myprofile_field_gender'),
									type:
										registration_fields.find((elem) => elem.field == 'gender') &&
										registration_fields.find((elem) => elem.field == 'gender').display
											? FORM_TYPES.SELECT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'gender') &&
											registration_fields.find((elem) => elem.field == 'gender').required,
									},
									grid_layout: { xs: 12, md: 6 },
									options: registration_fields
										.find((elem) => elem.field == 'gender')
										.options.map((gender) => ({
											label: translate('users_register_form_myprofile_field_gender_option_' + gender),
											value: gender,
										})),
								},
								{
									id: 'pronoun',
									name: 'pronoun',
									label: translate('users_form_field_pronoun'),
									type:
										registration_fields.find((elem) => elem.field == 'pronoun') &&
										registration_fields.find((elem) => elem.field == 'pronoun').display
											? FORM_TYPES.SELECT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'pronoun') &&
											registration_fields.find((elem) => elem.field == 'pronoun').required,
									},
									grid_layout: { xs: 12, md: 6 },
									options: registration_fields
										.find((elem) => elem.field == 'pronoun')
										.options.map((pronoun) => ({
											label: translate('users_form_field_pronoun_option_' + pronoun),
											value: pronoun,
										})),
								},
								{
									id: 'birthdate',
									name: 'birthdate',
									label: translate('users_register_form_personal_data_field_birthdate'),
									type:
										registration_fields.find((elem) => elem.field == 'birthdate') &&
										registration_fields.find((elem) => elem.field == 'birthdate').display
											? FORM_TYPES.DATEPICKER
											: FORM_TYPES.NONE,
									openTo: 'year',
									disableFuture: true,
									picker_type: 'birth',
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'birthdate') &&
											registration_fields.find((elem) => elem.field == 'birthdate').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'address',
									name: 'address',
									label: translate('users_register_form_myprofile_field_address'),
									type:
										registration_fields.find((elem) => elem.field == 'address') &&
										registration_fields.find((elem) => elem.field == 'address').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'address') &&
											registration_fields.find((elem) => elem.field == 'address').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'zip_code',
									name: 'zip_code',
									label: translate('users_register_form_myprofile_field_zip_code'),
									type:
										registration_fields.find((elem) => elem.field == 'zip_code') &&
										registration_fields.find((elem) => elem.field == 'zip_code').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'zip_code') &&
											registration_fields.find((elem) => elem.field == 'zip_code').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'city',
									name: 'city',
									label: translate('users_register_form_myprofile_field_city'),
									type:
										registration_fields.find((elem) => elem.field == 'city') &&
										registration_fields.find((elem) => elem.field == 'city').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'city') &&
											registration_fields.find((elem) => elem.field == 'city').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'phone',
									name: 'phone',
									label: translate('users_register_form_myprofile_field_phone'),
									type:
										registration_fields.find((elem) => elem.field == 'phone') &&
										registration_fields.find((elem) => elem.field == 'phone').display
											? FORM_TYPES.PHONE
											: FORM_TYPES.NONE,
									disableNumberArrows: true,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'phone') &&
											registration_fields.find((elem) => elem.field == 'phone').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'email',
									name: 'email',
									label: translate('users_register_form_myprofile_field_email'),
									type:
										registration_fields.find((elem) => elem.field == 'email') &&
										registration_fields.find((elem) => elem.field == 'email').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'email') &&
											registration_fields.find((elem) => elem.field == 'email').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'language',
									name: 'language',
									label: translate('users_register_form_myprofile_field_language'),
									type:
										registration_fields.find((elem) => elem.field == 'language') &&
										registration_fields.find((elem) => elem.field == 'language').display
											? FORM_TYPES.SELECT
											: FORM_TYPES.NONE,
									options: settings.site.available_languages
										? settings.site.available_languages.map((obj) => {
												return {
													value: obj.code,
													label: translate('language_' + obj.label),
												};
										  })
										: [
												{
													label: translate('users_register_form_myprofile_field_language_option_english'),
													value: 'en-US',
												},
												{
													label: translate('users_register_form_myprofile_field_language_option_swedish'),
													value: 'sv-SE',
												},
										  ],
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'language') &&
											registration_fields.find((elem) => elem.field == 'language').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'password',
									name: 'password',
									label: translate('user_change_write_new_password'),
									type:
										registration_fields.find((elem) => elem.field == 'password') &&
										registration_fields.find((elem) => elem.field == 'password').display
											? FORM_TYPES.INPUT_ADORNMENT
											: FORM_TYPES.NONE,
									inputType: visibility ? FORM_TYPES.INPUT : FORM_TYPES.PASSWORD,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'password') &&
											registration_fields.find((elem) => elem.field == 'password').required,
										functionValidation: [
											{
												validation: () => charNumberValid && specialCharValid && uppercaseValid && numberValid,
												message: 'user_change_password_need_all_values_validate',
											},
										],
									},
									onChange: (value) => {
										handlePasswordChange(value);
									},
									adornment: {
										action: () => setVisibility(!visibility),
										triggered: false,
										alwaysEnabled: true,
										icon: visibility ? <Visibility /> : <VisibilityOff />,
									},
									grid_layout: { xs: 12, md: 6 },
									initialvalue: '',
								},
								{
									id: 'password_confirm',
									name: 'password_confirm',
									label: translate('user_change_password_write_repeat'),
									type:
										registration_fields.find((elem) => elem.field == 'password') &&
										registration_fields.find((elem) => elem.field == 'password').display
											? FORM_TYPES.PASSWORD
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'password') &&
											registration_fields.find((elem) => elem.field == 'password').required,
										functionValidation: [
											{
												validation: (value) => {
													const isValid = password_field === value;
													setValid(isValid);
													return isValid;
												},
												message: 'user_change_password_confirm_validate',
											},
										],
									},

									grid_layout: { xs: 12, md: 6 },
									initialvalue: '',
								},
								...(settings.site.password_complexity
									? [
											{
												id: 'password_complexity',
												type: FORM_TYPES.EXTERNAL,
												content: getPasswordComplexityLayout(),
											},
									  ]
									: []),
								{
									id: 'groups_id',
									name: 'groups_id',
									label: translate('users_register_form_myprofile_field_groups'),
									type:
										registration_fields.find((elem) => elem.field == 'groups_id') &&
										registration_fields.find((elem) => elem.field == 'groups_id').display
											? FORM_TYPES.SELECT
											: FORM_TYPES.NONE,
									options:
										groups &&
										groups.map((obj) => {
											return {
												value: obj.id,
												label: obj.name,
											};
										}),
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'groups_id') &&
											registration_fields.find((elem) => elem.field == 'groups_id').required,
									},
									grid_layout: { xs: 12, md: 6 },
								},
								{
									id: 'consented',
									name: 'consented',
									label: translate('users_register_form_personal_data_field_consented'),
									type:
										registration_fields.find((elem) => elem.field == 'consented') &&
										registration_fields.find((elem) => elem.field == 'consented').display
											? FORM_TYPES.SINGLE_CHECKBOX
											: FORM_TYPES.NONE,
									initialvalue: user !== null ? (user.consented == '1' ? true : false) : null,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'consented') &&
											registration_fields.find((elem) => elem.field == 'consented').required,
									},
									onChange: (value) => {
										setConsented(value ? '1' : '0');
									},
								},
								{
									id: 'client_id',
									name: 'client_id',
									label: '',
									type: FORM_TYPES.HIDDEN,
									initialvalue: login.client.id,
								},
							],
						},
					],
				},
			],
		};

		return structure;
	};

	const configuration = handleConfig();

	return (
		<div>
			<Main form={configuration.form} formData={configuration} />
		</div>
	);
};

const selector = formValueSelector('create_new_account_user_profile');
const mapStateToProps = (state) => ({
	user: state.users.whoami,
	login: state.login,
	settings: state.settings,
	translate: getTranslate(state.localize),
	registration_fields: state.settings.users.registration_fields,
	password_field: selector(state, 'password'),
	password_verify: selector(state, 'password_confirm'),
	groups: state.generic.groups,
});

const ConnectedUserProfile = reduxForm({
	form: 'create_new_account_user_profile',
	destroyOnUnmount: false,
	onSubmit: (values, dispatch, props) => {
		if (!props.valid) {
			props.onSubmitSuccess();
		}
	},
})(UserProfile);

export default connect(mapStateToProps)(ConnectedUserProfile);
