import WIDGETS from './widgets';

export default {
  name: 'precall_meeting',
	spacing: 2,
	widgets: [
		{
			content: WIDGETS.PAGE_TITLE,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
			component_props: {
				translations: { title: 'precall_title_meeting', subtitle: 'precall_subtitle_meeting' },
			},
		},
		{
			content: WIDGETS.PRECALL,
			breakpoints: { xs: 12, sm: 6 },
			lazy_loading: 0,
			component_props: { redux: { patient_user: 'tickets.ticket.patient' } },
		},
	],
};
