const precall = (theme) => {
	return {
		'.precall-main-container': {
			backgroundColor: (props) => props?.settings?.styling?.videoconference_background_color,
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-title': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-description': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-publisher-container': {
			height: '300px',
			display: 'flex',
			alignItems: 'center',
		},
		'.precall-publisher-error-grid-container': {
			border: '1px solid ' + theme.palette.error.main,
		},
		'.precall-publisher-error-description': {
			color: theme.palette.error.main,
		},
		
		'.precall-button.Mui-disabled': {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.primary.main,
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
			
		},
		'.precall-box-control-container': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
			display: 'flex',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'.precall-box-control-container > svg': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-box-control-container .MuiInputBase-root': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-box-control-container .MuiSwitch-switchBase .precall-switch': {
			
			backgroundColor: (props) => props?.settings?.styling?.videoconference_primary_color + ' !important',
		},
		'.precall-box-control-container .MuiButtonBase-root': {
			color: (props) => props?.settings?.styling?.videoconference_primary_contrast_color,
		},
		'.precall-box-control-container label': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-box-control-container .Mui-disabled': {
			color: (props) => props?.settings?.styling?.videoconference_secondary_color,
		},
		'.precall-box-control-container .MuiFormControlLabel-label': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
			'-webkit-text-fill-color': (props) => props?.settings?.styling?.videoconference_primary_color + ' !important',
		},
		'.precall-box-control-meter-container.Mui-disabled fieldset': {
			color: (props) => props?.settings?.styling?.videoconference_primary_color,
		},
		'.precall-box-control-meter-container label': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-box-control-meter-container .MuiTypography-root': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-box-control-meter-container .MuiInputBase-root': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.precall-box-control-meter-container .Mui-disabled': {
			color: (props) => props?.settings?.styling?.videoconference_primary_color,
		},
		'.precall-camera-preview-container': {
			marginBottom: theme.spacing(0.5),
		},
		'.precall-box-control-meter-container': {
			position: 'relative',
			top: '50%',
			transform: 'translateY(-50%)',
			textAlign: 'center',
		},
		'.precall-volume-meter': {
			height: theme.spacing(2),
		},
		'.precall-network-test-speed': {
			fontWeight: 'bold',
		},

		'.precall-divider': {
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(0.5),
		},
		'.precall-device-settings-button': {
			textAlign: 'center',
		},
		'.precall-box-control-error-container': {
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'.precall-publisher-alert-error': {
			border: 'none',
		},
    '.precall-button-primary-contrast': {
      color: (props) => props?.settings?.styling?.videoconference_primary_contrast_color,
  },
	};
};

export default precall;
