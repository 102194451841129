import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../../Generic/Form/Main';
import { TYPES as FORM_TYPES } from '../../../Generic/FormTypes';
import { goBack } from 'connected-react-router';
import { sendNewPasswordAction } from '../../actions/usersActions';
import { Grid, Typography, Container } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { formValueSelector } from 'redux-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

class PasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			confirmPassword: '',
			match: null,
			charNumberValid: false,
			specialCharValid: false,
			uppercaseValid: false,
			numberValid: false,
			visibility: false,
			valid: false,
		};
	}
	// Check the length of the input
	checkPasswordLength = (password) => {
		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			password.length >= this.props.settings.password_complexity.length ||
			!this.props.settings.password_complexity.length
		) {
			this.setState({
				charNumberValid: true,
			});
		} else {
			this.setState({
				charNumberValid: false,
			});
		}
	};

	// Check for special characters
	checkSpecialCharacters = (password) => {
		const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
		const valSC = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			valSC >= this.props.settings.password_complexity.special_characters ||
			!this.props.settings.password_complexity.special_characters
		) {
			this.setState({
				specialCharValid: true,
			});
		} else {
			this.setState({
				specialCharValid: false,
			});
		}
	};

	// Check for an uppercase character
	checkUppercase = (password) => {
		const pattern = /[A-Z]/g;
		const valU = (password.match(pattern) || []).length;

		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			valU >= this.props.settings.password_complexity.uppercase ||
			!this.props.settings.password_complexity.uppercase
		) {
			this.setState({
				uppercaseValid: true,
			});
		} else {
			this.setState({
				uppercaseValid: false,
			});
		}
	};

	// Check for a number
	checkNumber = (password) => {
		const pattern = /[0-9]/g;
		const valN = (password.match(pattern) || []).length;
		if (
			typeof this.props.settings.password_complexity === 'undefined' ||
			this.props.settings.password_complexity === null ||
			valN >= this.props.settings.password_complexity.numbers ||
			!this.props.settings.password_complexity.numbers
		) {
			this.setState({
				numberValid: true,
			});
		} else {
			this.setState({
				numberValid: false,
			});
		}
	};
	handlePasswordChange = (value) => {
		this.checkPasswordLength(value);
		this.checkSpecialCharacters(value);
		this.checkUppercase(value);
		this.checkNumber(value);
		this.setState({ valid: this.props.password_verify === value });
	};

	handleDefaultValues = (config) => {
		let values = {};
		config.fields.filter((field) => typeof field.initialvalue !== 'undefined').map((field, i) => (values[field.name] = field.initialvalue));
		return values;
	};

	handleConfig = () => {
		const { translate } = this.props;

		const structure = {
			form: 'password',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'sm',
			columns: [
				{
					id: 'password-code-column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'password-code-separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'password',
									name: 'password',
									label: translate('user_change_write_new_password'),
									type: FORM_TYPES.INPUT_ADORNMENT,
									inputType: this.state.visibility ? FORM_TYPES.INPUT : FORM_TYPES.PASSWORD,
									validate: {
										required: true,
										functionValidation: [
											{
												validation: () => {
													return (
														this.state.charNumberValid &&
														this.state.specialCharValid &&
														this.state.uppercaseValid &&
														this.state.numberValid
													);
												},

												message: 'user_change_password_need_all_values_validate',
											},
										],
									},
									onChange: (value) => {
										this.handlePasswordChange(value);
									},
									adornment: {
										action: () =>
											this.setState({
												visibility: !this.state.visibility,
											}),
										triggered: false,
										alwaysEnabled: true,
										icon: this.state.visibility ? <Visibility /> : <VisibilityOff />,
									},
									initialvalue: '',
								},
								{
									id: 'password_verify',
									name: 'password_verify',
									label: translate('user_change_password_write_repeat'),
									type: FORM_TYPES.PASSWORD,
									validate: {
										required: true,
										functionValidation: [
											{
												validation: () => {
													return this.state.valid;
												},

												message: 'user_change_password_confirm_validate',
											},
										],
									},
									onChange: (value) => {
										this.setState({ valid: this.props.password_field === value });
									},
									initialvalue: '',
								},
							],
						},
					],
				},
			],
			buttons: [
				{
					id: 'password_submit_button',
					fullWidth: true,
					label: translate('user_change_new_password'),
					grid_layout: { xs: 12 },
					action: () => this.props.sendNewPasswordAction('password', () => {
						this.props.handleRecovery();
						this.props.handleValidateCode();
					}),
					api_validation: true,
				},
				{
					id: 'password_goback_button',
					fullWidth: true,
					label: translate('generic_button_back'),
					grid_layout: { xs: 12 },
					action: () =>  (this.props.user) ? this.props.goBack() : this.props.handleValidateCode(),
					submit: false,
					color: 'inherit',
				},
			],
		};

		return structure;
	};

	render() {
		const { translate } = this.props;
		const configuration = this.handleConfig();

		return (
			<div>
				<Grid container>
					<Grid item xs={12} md={12}>
						<Main form={configuration.form} formData={configuration} />
					</Grid>
					<Container maxWidth="sm">
						{!this.props.settings.password_complexity ? (
							''
						) : (
							<Grid item xs={12} md={12}>
								{!this.props.settings.password_complexity.length || !this.props.settings.password_complexity.length === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.charNumberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_carachters', {
											length: this.props.settings.password_complexity.length,
										})}
									</Typography>
								)}
								{!this.props.settings.password_complexity.special_characters ||
								this.props.settings.password_complexity.special_characters === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.specialCharValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_special_carachters', {
											length: this.props.settings.password_complexity.special_characters,
										})}
									</Typography>
								)}
								{!this.props.settings.password_complexity.uppercase || this.props.settings.password_complexity.uppercase === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.uppercaseValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_uppercase_letter', {
											length: this.props.settings.password_complexity.uppercase,
										})}
									</Typography>
								)}
								{!this.props.settings.password_complexity.numbers || this.props.settings.password_complexity.numbers === 0 ? (
									''
								) : (
									<Typography align="justify" variant="subtitle2" paragraph={true}>
										{this.state.numberValid ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
										{translate('user_change_password_number', {
											length: this.props.settings.password_complexity.numbers,
										})}
									</Typography>
								)}
							</Grid>
						)}
					</Container>
				</Grid>
			</div>
		);
	}
}
const selector = formValueSelector('password');
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	password_field: selector(state, 'password'),
	password_verify: selector(state, 'password_verify'),
	settings: state.settings.site,
  user: state.users.whoami,
});

export default connect(mapStateToProps, { sendNewPasswordAction, goBack })(PasswordForm);
