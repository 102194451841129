import React, { useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import { TextField, MenuItem } from '@mui/material';

function RecursiveTreeView({ options, handleChange }) {
	const renderTree = (nodes) => (
		<TreeItem
			key={nodes.id}
			nodeId={nodes.id}
			label={nodes.name}
			onClick={() => {
				handleChange(nodes.id);
			}}
		>
			{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
		</TreeItem>
	);

	return (
		<TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
			{options.map((option) => renderTree(option))}
		</TreeView>
	);
}

function recursiveMenuItemsList(menuOptions) {
	let result = [];

	const renderMenuItemsChild = (menuOptions) => {
		menuOptions.forEach((item) => {
			result.push(
				<MenuItem style={{ display: 'none' }} key={item.id} value={item.id}>
					{item.name}
				</MenuItem>
			);

			if (Array.isArray(item.children)) {
				renderMenuItemsChild(item.children);
			}
		});
	};

	renderMenuItemsChild(menuOptions);

	return result;
}

function RenderTreeSelect({ input, meta: { invalid, touched, error, active }, ...custom }) {
	const [inputValue, setInputValue] = React.useState(custom.inputdata.initialvalue);
	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		input.onChange(custom.inputdata.initialvalue);
		if (typeof custom.inputdata.onChange !== 'undefined') {
			custom.inputdata.onChange(custom.inputdata.initialvalue);
		}
		setInputValue(custom.inputdata.initialvalue);
	}, [custom.inputdata.initialvalue]);

	const handleChange = (value) => {
		input.onChange(value);
		if (typeof custom.inputdata.onChange !== 'undefined') {
			custom.inputdata.onChange(value);
		}
		setInputValue(value);
		setOpen(false);
	};

	// const handleClickAway = () => {
	// 	console.log('click away');
	// 	setOpen(false);
	// };

	return (
		<TextField
			{...input}
			id={custom.inputdata.id}
			name={custom.inputdata.name}
			label={custom.inputdata.label}
			placeholder={custom.inputdata.placeholder}
			fullWidth
			variant="outlined"
			disabled={custom.inputdata.readonly}
			error={touched && invalid && !active}
			helperText={touched && !active && error}
			//value={inputValue}
			select
			SelectProps={{
				open: open,
				SelectDisplayProps: {
					onClick: () => {
						setOpen(true);
					},
				},
			}}
			/*onChange={(event) => {
				input.onChange(event);
				if (typeof custom.inputdata.onChange !== 'undefined') {
					custom.inputdata.onChange(event.target.value);
				}
			}}*/
			type={custom.inputdata.type}
			size={custom.inputdata.size ? custom.inputdata.size : null}
			{...custom}
			inputProps={{
				step: custom.inputdata.props ? custom.inputdata.props.step : null,
				min: custom.inputdata.props ? custom.inputdata.props.min : null,
				className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
			}}
		>
			<MenuItem value="">
				<em>-</em>
			</MenuItem>
			{recursiveMenuItemsList(custom.inputdata.options)}
			{/* <RecursiveMenuItemsList menuOptions={custom.inputdata.options} /> */}
			<RecursiveTreeView options={custom.inputdata.options} handleChange={handleChange} />
		</TextField>
	);
}

export default RenderTreeSelect;
