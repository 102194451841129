import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import makeStyles from '@mui/styles/makeStyles';
import { TreeView, TreeItem } from '@mui/lab';
import { Typography, Divider } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, ArrowRight as ArrowRightIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';

const useTreeItemStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.secondary,
		'&:focus > $content': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
			color: 'var(--tree-view-color)',
		},
	},
	content: {
		color: theme.palette.text.secondary,
		borderTopRightRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightMedium,
		'$expanded > &': {
			fontWeight: theme.typography.fontWeightRegular,
		},
	},
	expanded: {},
}));

function StyledTreeItem(props) {
	const classes = useTreeItemStyles();
	const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

	return (
		<TreeItem
			{...(typeof props.tree_item_id !== 'undefined' && { id: props.tree_item_id })}
			label={
				<div className="generic-treelist-treeitem-label">
					<Typography variant="body2">{labelText}</Typography>
					<Typography variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</div>
			}
			style={{
				'--tree-view-color': color,
				'--tree-view-bg-color': bgColor,
			}}
			classes={{
				root: classes.root,
				content: classes.content,
				expanded: classes.expanded,
			}}
			{...other}
		/>
	);
}

const OptionItem = ({ options, action, flat, accordion_id = null }) => {
	console.log(accordion_id)
	return flat ? (
		<StyledTreeItem
			{...(accordion_id && { tree_item_id: accordion_id + '-file-' + options.name })}
			nodeId={'file_' + options.id}
			labelText={options.name}
			onClick={(uuid, name) => action(options.uuid, options.name)}
		/>
	) : (
		<StyledTreeItem
			{...(accordion_id && { tree_item_id: accordion_id + '-folder-' + options.name })}
			nodeId={options.id}
			labelText={options.name}
		>
			{options.files.map((option, key) => (
				<StyledTreeItem
					{...(accordion_id && { tree_item_id: accordion_id + '-file-' + option.name })}
					key={key}
					nodeId={'file_' + option.id}
					labelText={option.name}
					onClick={(uuid, name) => action(option.uuid, option.name)}
				/>
			))}
			{options.folders.map((option, key) => (
				<OptionItem key={key} options={option} action={action} />
			))}
		</StyledTreeItem>
	);
};

class TreeList extends Component {
	render() {
		const { translate } = this.props;

		return !this.props.data ? (
			<React.Fragment>
				<Divider />
				<Typography align="center" variant="subtitle2">
					{translate('generic_no_data_available')}
				</Typography>
				<Divider />
			</React.Fragment>
		) : (
			<TreeView
				{...(this.props.accordion_id && { id: this.props.accordion_id })}
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}
				defaultEndIcon={<AttachFileIcon />}
			>
				{this.props.data.map((item, key) => (
					<OptionItem
						key={key}
						options={item}
						action={this.props.action}
						flat={this.props.flat !== 'undefined' ? this.props.flat : false}
						{...(this.props.accordion_id && { accordion_id: this.props.accordion_id })}
					/>
				))}
			</TreeView>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, null)(TreeList);
