import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton, Typography, Input } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon, RemoveCircleOutline as RemoveCircleOutlineIcon } from '@mui/icons-material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import moment from 'moment';

function formatTime(timeValue) {
	const duration = moment.duration(timeValue, 'minutes');
	const hours = Math.floor(duration.asHours());
	const minutes = Math.floor(duration.asMinutes()) % 60;
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function formatWeight(weightValue) {
	return weightValue.toString();
}

function formatValueWithUnit(value, unit) {
	switch (unit) {
		case 'minutes':
			return formatTime(value);
		case 'kg':
			return formatWeight(value);
		// Add more cases for other units if needed
		default:
			return value.toString();
	}
}

function RenderIncrementor({ input: { value, onChange }, meta: { invalid, touched, error, active }, ...custom }) {
	const increment = () => {
		const numericValue = parseFloat(value);
		const newValue = (numericValue + custom.inputdata.steps).toFixed(1);
		onChange(newValue);
	};

	const decrement = () => {
		const numericValue = parseFloat(value);
		const newValue = (numericValue - custom.inputdata.steps).toFixed(1);
		const nonNegativeValue = Math.max(parseFloat(newValue), 0).toFixed(1);
		onChange(nonNegativeValue);
	};

	const incrementStone = () => {
		const [stonePart, poundPart] = value.split('sts, ');

		const newStoneValue = parseInt(stonePart, 10) + custom.inputdata.steps;

		const newValue = `${newStoneValue}sts, ${poundPart}`;
		onChange(newValue);
	};

	const decrementStone = () => {
		const [stonePart, poundPart] = value.split('sts, ');

		const newStoneValue = Math.max(parseInt(stonePart, 10) - custom.inputdata.steps, 0);

		const newValue = `${newStoneValue}sts, ${poundPart}`;
		onChange(newValue);
	};

	const incrementPound = () => {
		const [stonePart, poundPart] = value.split('sts, ');

		const newPoundValue = (parseFloat(poundPart) + custom.inputdata.setps_aux).toFixed(1);

		const newValue = `${stonePart}sts, ${newPoundValue}lb`;
		onChange(newValue);
	};

	const decrementPound = () => {
		const [stonePart, poundPart] = value.split('sts, ');

		const newPoundValue = (parseFloat(poundPart) - custom.inputdata.setps_aux).toFixed(1);

		const newValue = `${stonePart}sts, ${newPoundValue}lb`;
		onChange(newValue);
	};

	const handleStoneInputChange = (newStoneValue) => {
		const [stonePart, poundPart] = value.split('sts, ');

		const newValue = `${newStoneValue}sts, ${poundPart}`;
		onChange(newValue);
	};

	const handlePoundInputChange = (newPoundValue) => {
		const [stonePart, poundPart] = value.split('sts, ');

		const newValue = `${stonePart}sts, ${newPoundValue}lb`;
		onChange(newValue);
	};
	let stonePart = '';
	let poundPart = '';

	if (custom.inputdata.unit === 'stones') {
		[stonePart, poundPart] = value.split('sts, ');
	}
	const formattedValue = formatValueWithUnit(value, custom.inputdata.unit);

	return (
		<FormControl fullWidth>
			{custom.inputdata.unit !== 'stones' ? (
				<Grid2 container spacing={1} alignItems="center" justifyContent="center">
					<Grid2 xs={12} sx={{ justifyContent: 'center', display: 'flex' }}>
						<Typography sx={{ fontSize: '12px', color: '#707070' }}>{custom.inputdata.label}</Typography>
					</Grid2>
					<Grid2 sx={{ textAlign: 'right' }}>
						<IconButton onClick={decrement} sx={{ color: '#0060AF' }}>
							<RemoveCircleOutlineIcon sx={{ width: '40px', height: '40px' }} />
						</IconButton>
					</Grid2>
					<Grid2 xs={5} sx={{ textAlign: 'center' }}>
						{custom.inputdata.modify ? (
							<Input
								type="number"
								id="my-input"
								value={value}
								onChange={(e) => onChange(e.target.value)}
								sx={{
									fontSize: '36px',
									color: '#1A1818',
									textAlign: 'center',
									verticalAlign: 'middle',
								}}
								slotProps={{
									input: {
										sx: { textAlign: 'center' },
									},
								}}
							/>
						) : (
							<Typography variant="h5" sx={{ fontSize: '36px', color: '#1A1818' }}>
								{formattedValue}
							</Typography>
						)}
					</Grid2>
					<Grid2 sx={{ textAlign: 'left' }}>
						<IconButton onClick={increment} sx={{ color: '#0060AF' }}>
							<AddCircleOutlineIcon sx={{ width: '40px', height: '40px' }} />
						</IconButton>
					</Grid2>
				</Grid2>
			) : (
				<Grid2 container spacing={1} alignItems="center" justifyContent="center">
					<Grid2 xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Typography sx={{ fontSize: '12px', color: '#707070' }}>{custom.inputdata.label_aux}</Typography>
					</Grid2>
					<Grid2 xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Typography sx={{ fontSize: '12px', color: '#707070' }}>{custom.inputdata.label_aux1}</Typography>
					</Grid2>
					<Grid2 xs={6} sx={{ display: 'flex' }}>
						<IconButton onClick={decrementStone} sx={{ color: '#0060AF' }}>
							<RemoveCircleOutlineIcon sx={{ width: '40px', height: '40px' }} />
						</IconButton>
						<Input
							type="number"
							value={parseInt(stonePart, 10)}
							onChange={(e) => handleStoneInputChange(e.target.value)}
							sx={{
								fontSize: '36px',
								color: '#1A1818',
								textAlign: 'center',
								verticalAlign: 'middle',
							}}
							slotProps={{
								input: {
									sx: { textAlign: 'center' },
								},
							}}
						/>
						<IconButton onClick={incrementStone} sx={{ color: '#0060AF' }}>
							<AddCircleOutlineIcon sx={{ width: '40px', height: '40px' }} />
						</IconButton>
					</Grid2>
					<Grid2 xs={6} sx={{ display: 'flex' }}>
						<IconButton onClick={decrementPound} sx={{ color: '#0060AF' }}>
							<RemoveCircleOutlineIcon sx={{ width: '40px', height: '40px' }} />
						</IconButton>
						<Input
							type="number"
							value={parseFloat(poundPart)}
							onChange={(e) => handlePoundInputChange(e.target.value)}
							sx={{
								fontSize: '36px',
								color: '#1A1818',
								textAlign: 'center',
								verticalAlign: 'middle',
							}}
							slotProps={{
								input: {
									sx: { textAlign: 'center' },
								},
							}}
						/>
						<IconButton onClick={incrementPound} sx={{ color: '#0060AF' }}>
							<AddCircleOutlineIcon sx={{ width: '40px', height: '40px' }} />
						</IconButton>
					</Grid2>
				</Grid2>
			)}

			{/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
		</FormControl>
	);
}

RenderIncrementor.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		onChange: PropTypes.func.isRequired,
	}).isRequired,
	meta: PropTypes.shape({
		invalid: PropTypes.bool,
		touched: PropTypes.bool,
		error: PropTypes.string,
		active: PropTypes.bool,
	}),
};

export default RenderIncrementor;
