import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

// import { NavBar } from '../Generic';
import NavBar from './NavBar';
import Footer from './Footer';
import { Container } from '@mui/material';
import PrivateRoute from '../../helpers/PrivateRoute';
import { Loading } from '../Generic';
import { setMenuAction } from '../Generic/actions/genericActions';
import ErrorBoundary from './ErrorBoundary';

import { SocketContext } from '../Chat/SocketProvider';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import MESSAGES from '../../helpers/messages';

import { handleModalCatalogue } from '../Generic/actions/genericActions';
import CATALOGUES from '../../catalogues';
class DefaultLayout extends Component {
	static contextType = SocketContext;

	componentDidMount() {
		if (
			this.props.whoami.scopes &&
			this.props.whoami.scopes.includes('websocket.user.notifications') &&
			this.context.ws &&
			this.context.subscribe &&
			this.props.whoami.channel
		) {
			this.context.subscribe(this.props.whoami.channel.name);
		}
		if (this.props.menu) {
			this.props.setMenuAction(this.props.menu);
		}
		if (this.props.params && this.props.params.events_id && this.props.params.modal_catalogue) {
			this.props.handleModalCatalogue(true, CATALOGUES.EVENT_CANCEL, {
				classes: { paper: 'generic-side-bar' },
			});
		}
	}

	componentDidUpdate() {
		if (this.props.menu) {
			this.props.setMenuAction(this.props.menu);
		}
	}

	componentWillUnmount() {
		if (
			this.props.whoami.scopes &&
			this.props.whoami.scopes.includes('websocket.user.notifications') &&
			this.context.ws &&
			this.context.unsubscribe &&
			this.props.whoami.channel
		) {
			this.context.unsubscribe(this.props.whoami.channel.name);
		}
	}

	render() {
		const { dispatch, component: Component, path, extraProps, catalogue, ...rest } = this.props;

		return rest.isLoggedIn ? (
			<div
				className={'generic-defaultlayout-main ' + (this.props.backgroundColorClass ? this.props.backgroundColorClass : '')}
				style={{ flexGrow: 1 }}
			>
				{!rest.disableNavigation && <NavBar />}
				<div style={{ display: 'flex' }}>
					{rest.loading ? (
						<Loading />
					) : (
						<React.Fragment>
							<Container
								component="main"
								maxWidth={
									// this.props.side_bar?.open && this.props.side_bar?.drawer_props?.variant === 'permanent'
									// 	? this.props.side_bar?.drawer_props?.width :
									this.props.fullsizeContainer ? false : 'xl'
								}
								// {...(this.props.side_bar?.open &&
								// 	this.props.side_bar?.drawer_props?.variant === 'permanent' && { sx: { flexGrow: 1, p: 3 } })}
								{...(!this.props.disableMargins && { className: 'generic-defaultlayout-content' })}
								disableGutters={this.props.disableGutters}
							>
								<ErrorBoundary>
									<PrivateRoute {...rest} exact path={path} extraProps={extraProps} component={Component} catalogue={catalogue} />
								</ErrorBoundary>
							</Container>
						</React.Fragment>
					)}
					{/* {!rest.disableNavigation && <Footer></Footer>} */}
				</div>
			</div>
		) : (
			<Redirect to="/login" />
		);
	}
}

const mapStateToProps = (state) => ({
	whoami: state.users.whoami,
	loading: state.generic.loading,
	player: state.generic.player,
	isLoggedIn: state.login.login,
	params: state.settings.params,
	side_bar: state.generic.side_bar,
});

export default connect(mapStateToProps, {
	setMenuAction,
	handleModalCatalogue,
})(DefaultLayout);
