import { convertHex } from '../../helpers/functions/functions';

const table = (theme) => {
	return {
		'.table-custom-toolbar-select': {
			[theme.breakpoints.down('xl')]: {
				width: '100%',
				marginBottom: theme.spacing(2),
			},
			[theme.breakpoints.up('md')]: {
				width: '35%',
			},
			textAlign: 'left',
		},
		'.table-activation-date-typography': {
			marginBottom: '0px',
			marginTop: theme.spacing(1),
		},
		'.muitable-expanded-top-row': {
			backgroundColor: convertHex(theme.palette.primary.main, 5),
			borderTop: '2px solid ' + theme.palette.primary.main		
		},
		'.muitable-expanded-bottom-row': {
			borderTop: '2px solid ' + theme.palette.primary.main		
		},
	};
};

export default table;
