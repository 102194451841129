/**Participant roles */
const LEADER = 'leader';
const ORGANIZER = 'organizer';
const MODERATOR = 'moderator';
const ATTENDEE = 'attendee';

/**Meeting status */
const PENDING = 1;
const ACTIVE = 2;
const CANCELLED = 3;
const CLOSE = 4;
const ARCHIVED = 5;

/**Data access */
const PUBLIC = 'public';
const PRIVATE = 'private';

/**Meeting mode */
const RESTRICTED_ON = 'restricted';
const RESTRICTED_OFF = 'non_restricted';

/**Event attendance */
const UNKNOWN = 0;
const YES = 1;
const NO = 2;

/**Event location */
const ONLINE = 'online';
const PHONE = 'phone';
const PRESENCIAL = 'presencial';

export default {
	ROLE: {
		LEADER,
		ORGANIZER,
		MODERATOR,
		ATTENDEE,
	},
	STATUS: {
		PENDING,
		ACTIVE,
		CANCELLED,
		CLOSE,
		ARCHIVED,
	},
	ATTENDANCE: {
		UNKNOWN,
		YES,
		NO,
	},
	DATA_ACCESS: {
		PUBLIC,
		PRIVATE,
	},
	MODE: {
		RESTRICTED_ON,
		RESTRICTED_OFF,
	},
	SCOPES: {
		MANAGE: {
			EDIT: 'meetings.manage.edit',
			ADD_INVITEES: 'meetings.manage.add_invitees',
			REMOVE_INVITEES: 'meetings.manage.remove_invitees',
			EDIT_INVITEES: 'meetings.manage.edit_invitees',
			NOTIFY_NON_NOTIFIED: 'meetings.manage.notify_non_notified',
			NOTIFY_ALL: 'meetings.manage.notify_all',
			GENERATE_PUBLIC_TOKEN: 'meetings.manage.generate_public_token',
			START_SESSION: 'meetings.manage.start_session',
			CLOSE_SESSION: 'meetings.manage.close_session',
			ATTENDANCE_CONFIRMATION: 'meetings.manage.attendance_confirmation',
			CANCEL: 'meetings.manage.cancel',
		},
		SUMMARY: {
			ENABLED: 'meetings.summary',
			PARTICIPANTS_INFO: 'meetings.summary.participants',
			FILES: 'meetings.summary.files',
			CHAT_HISTORY: 'meetings.summary.chat',
			ATTENDANCE: 'meetings.summary.attendance',
		},
		VIDEO: {
			PARTICIPATE: 'meetings.video.participate',
			OWN_SOUND: 'meetings.video.own_sound',
			PIN_SELF_TO_OTHERS: 'meetings.video.pin_self_to_others',
			PIN_OTHERS_TO_SELF: 'meetings.video.pin_others_to_self',
			PIN_OTHERS_TO_ALL: 'meetings.video.pin_others_to_all',
			CHAT: 'meetings.video.chat',
			UPLOAD_FILE: 'meetings.video.upload_file',
			KICK_PARTICIPANT: 'meetings.video.kick_participant',
			ACCEPT_PARTICIPANT: 'meetings.video.accept_participant',
			SETTINGS: 'meetings.video.settings',
			SHARE_SCREEN: 'meetings.video.share_screen',
			FULLSCREEN: 'meetings.video.fullscreen',
			RAISE_HAND: 'meetings.video.raise_hand',
			REMOVE_RAISED_HAND: 'meetings.video.remove_raised_hand',
			EDIT_PARTICIPANT: 'meetings.video.edit_participant',
			SCREEN_SHOT: 'meetings.video.screen_shot',
			FILTER: 'meetings.video.filter',
		},
		LOBBY: {
			ENABLED: 'meetings.lobby',
			INVITEES: 'meetings.lobby.invitees',
			WAITING: 'meetings.lobby.waiting',
			PARTICIPANTS: 'meetings.lobby.participants',
		},
	},
	FILTER: {
		TYPES: {
			NONE: 'none',
			BACKGROUND_BLUR: 'backgroundBlur',
		},
		BLUR_STRENGTHS: {
			HIGH: 'high',
			LOW: 'low',
		},
	},
	VIDEO_SOURCE: {
		CAMERA: 'camera',
		SCREEN: 'screen',
	},
	DEVICES: {
		ON: true,
		OFF: false,
	},
	CAMERA_POSITION: {
		FRONT: 'front',
		BACK: 'back',
	},
	LOCATION: {
    ONLINE,
    PHONE,
    PRESENCIAL
  },
};
