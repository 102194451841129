import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme) => {

	return {
		'@global': {
			h1: {
				...theme.typography.h1,
				margin: '0'
			},
			h2: {
				...theme.typography.h2,
				margin: '0'
			},
			h3: {
				...theme.typography.h2,
				margin: '0'
			},
			h4: {
				...theme.typography.h4,
				margin: '0'
			},
			h5: {
				...theme.typography.h5,
				margin: '0'
			},
			h6: {
				...theme.typography.h6,
				margin: '0'
			},
			p: {
				...theme.typography.body1,
				// margin: '0'
			}
		},
	};
});
