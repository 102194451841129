import {
	CHANGE_TABLE_PAGE,
	SET_TABLE_COUNT,
	SET_TABLE_FILTERS,
	SET_TABLE_CORE_STATE,
	SET_TABLE_ROWS_PER_PAGE,
	SET_TABLE_FILTER_LIST,
  SET_TABLE_SEARCH
} from '../actions/types';

export const changeTablePageAction = (table_id, page) => (dispatch) => {
	dispatch({
		type: CHANGE_TABLE_PAGE,
		payload: { table_id, page },
	});
};

export const setTableCountAction = (table_id, count) => (dispatch) => {
	dispatch({
		type: SET_TABLE_COUNT,
		payload: { table_id, count },
	});
};

export const setTableFiltersAction = (table_id, filters) => (dispatch) => {
	dispatch({
		type: SET_TABLE_FILTERS,
		payload: { table_id, filters },
	});
};

export const setTableSearchAction = (table_id, search) => (dispatch) => {
	dispatch({
		type: SET_TABLE_SEARCH,
		payload: { table_id, search },
	});
};

export const setTableFiltersListsAction = (table_id, filter_list) => (dispatch) => {
	dispatch({
		type: SET_TABLE_FILTER_LIST,
		payload: { table_id, filter_list },
	});
};

export const setTableRowsPerPageAction = (table_id, rows_per_page) => (dispatch) => {
	dispatch({
		type: SET_TABLE_ROWS_PER_PAGE,
		payload: { table_id, rows_per_page },
	});
};

export const setTableCoreStateAction = (table_id, core_state) => (dispatch) => {
	dispatch({
		type: SET_TABLE_CORE_STATE,
		payload: { table_id, core: core_state },
	});
};

const tableActions = {
	changeTablePageAction,
  setTableCountAction,
  setTableFiltersAction,
  setTableSearchAction,
  setTableFiltersListsAction,
  setTableRowsPerPageAction,
  setTableCoreStateAction
};

export default tableActions;