import WIDGETS from './widgets';

export default {
  name: 'library_files',
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKLIBRARY, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.LIBRARYFILE, breakpoints: { xs: 12, sm: 12 } },
	],
};
