import { handleBackDrop } from '../../components/Generic/actions/genericActions';
import { store } from '../../store';

const checkVersion = async function ({ force_refresh = false, callback = false }) {
	let latestVersion = '1';
	let currentVersion = '1';

	await fetch(`/meta.json`, { cache: 'no-cache' })
		.then((response) => response.json())
		.then((meta) => {
			console.log('meta', meta);
			latestVersion = meta.version;
			currentVersion = document.head.querySelector('meta[name="version"]').content;
			// const currentVersion = global.appVersion;
		})
		.then(() => {
			console.log('On resolve import...');
			console.log('latestVersion', latestVersion);
			console.log('currentVersion', currentVersion);
			if (latestVersion !== currentVersion) {
				return store.dispatch(handleBackDrop({ open: true }));
			} else if (callback) {
				return callback();
			} else {
				return;
			}
		})
		.catch((error) => {
			console.log(error);
			if (callback) {
				return callback();
			} else if (force_refresh) {
				return store.dispatch(handleBackDrop({ open: true }));
			} else {
				return;
			}
		});
};

export { checkVersion };
