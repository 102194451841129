/**Input types */
const INPUTPOINTS = 'InputPoints';
const INPUTCHECKBOX = 'InputCheckbox';
const INPUTNUMBER = 'InputNumber';
const INPUTTEXT = 'InputText';
const INPUTRADIO = 'InputRadio';

/**Survey status */
const UNSIGNED = 'unsigned';
const SIGNED = 'signed';

export default {
	QUESTION: {
		TYPES: {
			INPUTPOINTS,
			INPUTCHECKBOX,
			INPUTNUMBER,
			INPUTTEXT,
			INPUTRADIO,
		},
	},
	STATUS: {
		UNSIGNED,
		SIGNED,
	},
};
