import axios from 'axios';
import {
	HANDLE_SPLASH_SCREEN,
	HANDLE_LOADING,
	MODAL_ACTION,
	LIGHTBOX_ACTION,
	DISABLE_FORM_BUTTON_ACTION,
	ADD_WRAPPER,
	ZOOM_BUTTON_LIGHTBOX_ACTION,
	FETCH_ROLES,
	DOCUMENT_PREVIEW,
	PROGRESS_ACTION,
	SET_PROGRESS_ACTION,
	NOTIFICATIONS_MODAL_ACTION,
	HANDLE_BLOCKING_UI,
	PLAYER_ACTION,
	SIDE_BAR,
	MODAL_CATALOGUE,
	FETCH_LAYOUTS,
	PERSISTENT_ALERT,
	FETCH_GROUPS,
	BACKDROP,
} from './types';
import { MENU_ACTIVE_ACTION } from '../../Users/actions/types';
import { push } from 'connected-react-router';
import { store } from '../../../store.js';
import { isMobileSize, openPdfFIle } from '../../../helpers/functions/functions';
import MESSAGES from '../../../helpers/messages';
import { FILES } from '../../../helpers/files';
import { apiRequest } from '../../../helpers/functions/request';
import { catalogue_structure } from '../../../catalogues';
import { removeSettingsAction } from '../../../actions/settingsActions';

export const convertToFormData = (fields) => {
	if (fields) {
		const form_data = new FormData();

		for (var key in fields) {
			if (fields[key] !== null && fields[key] instanceof FileList) {
				console.log(fields[key]);
				Array.from(fields[key]).forEach((element) => {
					form_data.append(key, element);
				});
				// fields[key].forEach(element => {
				// 	form_data.append(key, element);
				// });
			} else if (fields[key] !== null && fields[key][0] instanceof File) {
				fields[key].forEach((element) => {
					form_data.append(key, element);
				});
			} else {
				form_data.append(key, fields[key]);
			}
		}

		return form_data;
	}
};

export const handleLoadingAction = (trigger) => (dispatch) => {
	if (!trigger) {
		setTimeout(function () {
			dispatch({
				type: HANDLE_LOADING,
				payload: trigger,
			});
		}, 500);
	} else {
		dispatch({
			type: HANDLE_LOADING,
			payload: trigger,
		});
	}
};

export const handleSplashScreenAction = (trigger) => (dispatch) => {
	if (!trigger) {
		setTimeout(function () {
			dispatch({
				type: HANDLE_SPLASH_SCREEN,
				payload: trigger,
			});
		}, 1000);
	} else {
		dispatch({
			type: HANDLE_SPLASH_SCREEN,
			payload: trigger,
		});
	}
};

export const handleRedirectsAction = (path) => (dispatch) => {
	dispatch(push(path));
};

export const displayModalAction = (params) => (dispatch) => {
	dispatch({
		type: MODAL_ACTION,
		payload: {
			id: params.id,
			open: true,
			title: params.title,
			description: params.description,
			text: params.text,
			button_no: params.button_no,
			button_no_id: params.button_no_id,
			button_yes: params.button_yes,
			button_yes_id: params.button_yes_id,
			action: params.action,
			...(typeof params.disable_click_away !== 'undefined' && { disable_click_away: params.disable_click_away }),
			...(typeof params.action_no_button !== 'undefined' && { action_no_button: params.action_no_button }),
			...(typeof params.props_no_button !== 'undefined' && { props_no_button: params.props_no_button }),
			...(typeof params.action_on_close !== 'undefined' && { action_on_close: params.action_on_close }),
			...(typeof params.max_width !== 'undefined' && { max_width: params.max_width }),
			...(typeof params.show_button_no !== 'undefined' && { show_button_no: params.show_button_no }),
			...(typeof params.show_button_yes !== 'undefined' && { show_button_yes: params.show_button_yes }),
			...(typeof params.input !== 'undefined' && { input: params.input }),
			props: params.props,
		},
	});
};

export const closeModalAction = () => (dispatch) => {
	dispatch({
		type: MODAL_ACTION,
		payload: {
			open: false,
		},
	});
};

export const displayProgressAction = (params) => (dispatch) => {
	dispatch({
		type: PROGRESS_ACTION,
		payload: {
			open: true,
			title: params.title,
			description: params.description,
			button_cancel: params.button_cancel,
			button_cancel_id: params.button_cancel_id,
			action: params.action,
			props_cancel_button: params.props_cancel_button,
			props: params.props,
		},
	});
};

export const closeProgressAction = () => (dispatch) => {
	dispatch({
		type: PROGRESS_ACTION,
		payload: {
			open: false,
		},
	});
};

export const setProgressValueAction = (value) => (dispatch) => {
	dispatch({
		type: SET_PROGRESS_ACTION,
		payload: value,
	});
};

export const cancelProgressAction = () => (dispatch) => {
	dispatch({
		type: PROGRESS_ACTION,
		payload: {
			canceled: true,
			open: false,
		},
	});
};

export const fetchFileAction =
	(type, uuid, link = null, callback = false, name) =>
	async (dispatch) => {
		const state = store.getState();

		dispatch(handleBlockingUIAction(true));

		let url = state.config.apihost;

		switch (type) {
			case 'ticket_file':
				let file = state.tickets.ticket.files.find((file) => file.uuid === uuid);
				if (file.type === 'questionnaire') {
					if (callback) {
						callback(file.id, MESSAGES.REF.TICKETS_QFILES);
					}

					url = url + `/tickets/` + state.tickets.ticket.id + `/questionnaires/` + file.questionnaires_id + `/files/` + uuid;

					let mime = file.mime.split('/');

					if (mime.length > 0 && mime[0] === 'video') {
						dispatch(handleBlockingUIAction(false));
						dispatch(openPlayerAction(url));
						return;
					}
					break;
				} else {
					if (callback) {
						callback(file.id, MESSAGES.REF.TICKETS_FILES);
					}
					url = url + `/tickets/` + state.tickets.ticket.id + `/files/` + uuid;

					let mime = file.mime.split('/');

					if (mime.length > 0 && mime[0] === 'video') {
						dispatch(handleBlockingUIAction(false));
						dispatch(openPlayerAction(url));
						return;
					}
					break;
				}

			case 'ticket_certificate':
				url = url + `/tickets/` + state.tickets.ticket.id + `/certificates/` + uuid;
				break;
			case 'journal_diet':
				if (link) {
					url = url + link;
				}
				break;
			case 'ticket_prescription':
				url = url + `/tickets/` + state.tickets.ticket.id + `/prescriptions/` + uuid;
				break;
			case 'guidelines':
				url = url + `/guidelines/files/` + uuid;
				break;

			case 'files_for_patients':
				url = url + `/selfcares/files/` + uuid;
				break;

			case 'libraries':
				url = state.config.driveshost + link + '/data';
				break;

			case 'medical_records':
				url = url + link;
				break;

			default:
				break;
		}

		const response = await fetch(url);
		const blob = await response.blob();
		const fileURL = URL.createObjectURL(blob);

		if (FILES.includes(blob.type)) {
			return dispatch({
				type: LIGHTBOX_ACTION,
				payload: {
					open: true,
					image: [url],
				},
			});
		} else if (blob.type.length > 0 && blob.type.split('/')[0] === 'video') {
			dispatch(handleBlockingUIAction(false));
			return dispatch(openPlayerAction(fileURL));
		} else {
			if (isMobileSize()) {
				openPdfFIle(url, name);
			} else {
				openPdfFIle(fileURL, name);
			}
			return dispatch(handleBlockingUIAction(false));
		}
	};

export const fetchMyFileAction = (file) => async (dispatch) => {
	const state = store.getState();

	dispatch(handleBlockingUIAction(true));

	let url = state.config.apihost;

	if (file.type === 'questionnaire') {
		url = url + `/tickets/` + file.tickets_id + `/questionnaires/` + file.questionnaires_id + `/files/` + file.uuid;

		let mime = file.mime.split('/');

		if (mime.length > 0 && mime[0] === 'video') {
			dispatch(handleBlockingUIAction(false));
			dispatch(openPlayerAction(url));
			return;
		}
	} else {
		url = url + `/tickets/` + file.tickets_id + `/files/` + file.uuid;

		let mime = file.mime.split('/');

		if (mime.length > 0 && mime[0] === 'video') {
			dispatch(handleBlockingUIAction(false));
			dispatch(openPlayerAction(url));
			return;
		}
	}

	const response = await fetch(url);
	const blob = await response.blob();

	const fileURL = URL.createObjectURL(blob);

	if (FILES.includes(blob.type)) {
		return dispatch({
			type: LIGHTBOX_ACTION,
			payload: {
				open: true,
				image: [url],
			},
		});
	} else if (blob.type.length > 0 && blob.type.split('/')[0] === 'video') {
		dispatch(handleBlockingUIAction(false));
		return dispatch(openPlayerAction(fileURL));
	} else {
		if (isMobileSize()) {
			openPdfFIle(url, file.name);
			openPdfFIle(url, file.name);
		} else {
			openPdfFIle(fileURL, file.name);
		}
		return dispatch(handleBlockingUIAction(false));
	}
};

const blobPdfFromBase64String = (base64String) => {
	const byteArray = Uint8Array.from(
		atob(base64String)
			.split('')
			.map((char) => char.charCodeAt(0))
	);

	return new File([byteArray], 'Videoimage', { type: 'image/png' });
};

export const openLightBox = (image) => (dispatch) => {
	dispatch({
		type: LIGHTBOX_ACTION,
		payload: {
			open: true,
			image,
			screenshot: image,
		},
	});
};

export const closeLightboxAction = () => (dispatch) => {
	dispatch({
		type: LIGHTBOX_ACTION,
		payload: {
			open: false,
			image: [],
			disabledZoomButton: false,
		},
	});
};

export const openPlayerAction = (url) => (dispatch) => {
	dispatch({
		type: PLAYER_ACTION,
		payload: {
			open: true,
			url,
		},
	});
};

export const closePlayerAction = () => (dispatch) => {
	dispatch({
		type: PLAYER_ACTION,
		payload: {
			open: false,
			url: null,
		},
	});

	Object.assign(document.body.style, { overflow: 'auto', margin: '0px', paddingRight: '0px' });
};

export const disableLightboxZoomButtonAction = () => (dispatch) => {
	dispatch({
		type: ZOOM_BUTTON_LIGHTBOX_ACTION,
		payload: true,
	});
};

export const disableFormButtonAction = (value) => (dispatch) => {
	dispatch({
		type: DISABLE_FORM_BUTTON_ACTION,
		payload: value,
	});
};

export const handleBlockingUIAction =
	(blocking_ui = true, label = 'block_ui_loading') =>
	(dispatch) => {
		dispatch({
			type: HANDLE_BLOCKING_UI,
			payload: { open: blocking_ui, label },
		});
	};

export const addDelayAction =
	(actions = null, events = null) =>
	(dispatch) => {
		actions = actions === null ? [] : !Array.isArray(actions) ? [actions] : actions;
		events = events === null ? [] : !Array.isArray(events) ? [events] : events;

		if (actions.length > 0 || events.length > 0) {
			setTimeout(() => {
				if (actions.length > 0) actions.forEach((action) => dispatch(action));
				if (events.length > 0) events.forEach((event) => event());
			}, 1000);
		}
	};

export const addWrapper = () => (dispatch) => {
	const state = store.getState();

	dispatch({
		type: ADD_WRAPPER,
		payload: state.generic.wrapper + 1,
	});
};

export const fetchRolesAction =
	(type = 'internal') =>
	async (dispatch) => {
		const state = store.getState();

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/roles`,
			params: { type },
		});

		return dispatch({
			type: FETCH_ROLES,
			payload: {
				type: type,
				data: response.data,
			},
		});
	};

export const displayDocumentPreviewAction = (loading) => (dispatch) => {
	dispatch({
		type: DOCUMENT_PREVIEW,
		payload: {
			loading,
		},
	});
};

export const displayNotificationsModalAction = (open) => (dispatch) => {
	dispatch({
		type: NOTIFICATIONS_MODAL_ACTION,
		payload: {
			open,
		},
	});
};

export const setMenuAction = (menu) => (dispatch) => {
	dispatch({
		type: MENU_ACTIVE_ACTION,
		payload: {
			menu,
		},
	});
};

/**
 * Dispatches a JSX Component for displaying selected catalogue in a side bar
 * @param {boolean} open state variable boolean for controling side bar visibility
 * @param {catalogue_structure} catalogue catalogue to render
 * @param {object} drawer_props state MUI drawer component props
 * @param {boolean} info state variable boolean for controling side bar visibility
 * @returns
 */
export const handleSideBar =
	(open = false, catalogue = undefined, drawer_props, info = false) =>
	(dispatch) => {
		dispatch({
			type: SIDE_BAR,
			payload: {
				open,
				catalogue,
				drawer_props: { ...drawer_props },
				info,
			},
		});
		if (!open) {
			const expandedRows = document.querySelectorAll('.muitable-expanded-top-row, .muitable-expanded-bottom-row');
			expandedRows.forEach((row) => row.classList.remove('muitable-expanded-top-row', 'muitable-expanded-bottom-row'));
		}
	};

/**
 * Dispatches a JSX Component for displaying selected catalogue in a Modal
 * @param {boolean} open state variable boolean for controling modal visibility
 * @param {catalogue_structure} catalogue catalogue to render
 * @param {object} dialog_props state MUI dialog component props
 * @returns
 */
export const handleModalCatalogue =
	(open = false, catalogue = undefined, dialog_props, info = false) =>
	(dispatch) => {
		const state = store.getState();

		dispatch({
			type: MODAL_CATALOGUE,
			payload: {
				open,
				catalogue,
				dialog_props: { ...dialog_props },
				info,
			},
		});
		if (state.generic.modal_catalogue.open && state.settings.params) {
			dispatch(removeSettingsAction(['events_id', 'modal_catalogue']));
		}
	};

/**
 * Dispatches a redux action to set layouts to generic reducer
 * @returns
 */
export const loadLayouts = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/layouts`,
	});

	dispatch({
		type: FETCH_LAYOUTS,
		payload: response.data,
	});

	return response.data;
};

/**
 * Dispatches a JSX Component for displaying a persistent alert message
 * @param {boolean} open state variable boolean for controling alert visibility
 * @param {string} message alert message to display
 * @param {object} severity changes background color and alert icon (error, warning, info, success)
 * @param {jsx} action jsx component as action to the right of the alert message
 * @returns
 */
export const handlePersistentAlert =
	({ open = false, message = null, severity = 'error', action = false, icon = false }) =>
	(dispatch) => {
		dispatch({
			type: PERSISTENT_ALERT,
			payload: {
				open,
				message,
				severity,
				action,
				icon,
			},
		});
	};
/**
 * Dispatches a redux action to get groups to generic reducer
 * @returns
 */
export const fetchGroupsAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/clients/groups`,
		params: { clients_id: state.login.client.id },
	});

	dispatch({
		type: FETCH_GROUPS,
		payload: response.data,
	});

	return response.data;
};

export const fetchGroupsPublicAction = () => async (dispatch) => {
	const state = store.getState();

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/clients/groups/public`,
		params: { clients_id: state.login.client.id },
	});

	dispatch({
		type: FETCH_GROUPS,
		payload: response.data,
	});
};

/**
 * Dispatches a JSX Component for displaying a full size backdrop component with a card
 * @param {boolean} open state variable boolean for controling visibility
 * @param {boolean} invisible state variable boolean for controling backdrop visibility
 * @param {jsx} action jsx component as action to the right of the alert message
 * @returns
 */
export const handleBackDrop =
	({ open = false, invisible = false }) =>
	(dispatch) => {
		dispatch({
			type: BACKDROP,
			payload: {
				open,
				invisible,
			},
		});
	};

const genericActions = {
	handleSideBar,
	handleModalCatalogue,
};

export default genericActions;
