import React from 'react';
import { Typography, Menu, MenuItem, Divider, FormControlLabel, Checkbox } from '@mui/material';

export function NavBarMenu({ translate, ...props }) {
	return (
		<Menu
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			id={props.menuId}
			anchorEl={props.anchorEl}
			open={props.isMenuOpen}
			onClose={props.handleMenuClose}
		>
			<MenuItem disabled={true}>
				<Typography>{props.username}</Typography>
			</MenuItem>
			<Divider />
			<MenuItem id="navbar_menu_logout" onClick={() => props.logout(true)}>
				<Typography id="navbar_menu_logout_typography">{translate('navbar_button_logout')}</Typography>
			</MenuItem>
		</Menu>
	);
}
