import AttachIcon from '../icons/attach_1.svg';
// import SendIcon from '../icons/send.svg';
import SendIcon from '../send_mui_icon.svg';

const tui_editor = (theme) => {
	return {
		'.tui-toolbar-button-attach': {
			backgroundImage: `url(${AttachIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundColor: '#f7f9fc',
      backgroundSize: '23px 32px',
		},
		'.tui-toolbar-button-send': {
			backgroundImage: `url(${SendIcon}) !important`,
			backgroundRepeat: 'no-repeat !important',
			backgroundPosition: 'center !important',
			backgroundColor: theme.palette.primary.main,
      borderRadius: '50% !important',
		},
		'.tui-toolbar-button-send:hover': {
			backgroundColor: theme.palette.secondary.main + ' !important',
		},
	};
};

export default tui_editor;
