const image_list_display = (theme) => {
	/* The overlay effect (full height and width) - lays on top of the container and over the image */
	return {
		'.ReactGridGallery-Block': {
			display: 'flow-root',
		},
        '.ReactGridGallery_tile-icon-bar svg': {
			fill: 'black',
			background:
				'white !important',
			borderRadius: '25px',
            width: '24px !important',
            height: '24px !important'
		},
        '.ReactGridGallery_tile-icon-bar svg path': {
			d: "path('M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z') !important"
		},
		/* When you move the mouse over the icon, change color */
		// '.MuiSvgIcon-root:hover': {
		// 	color: 'grey',
		// 	cursor: 'pointer',
		// 	zIndex: 1,
        // },
		'.overlay-icon': {
			position: 'absolute',
			width: '30px',
			height: '30px',
			backgroundColor: 'blue',
			borderRadius: '50%',
			animation: 'blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate',
		},

		'div.image-display-main': {
			position: 'relative',
			float: 'left',
			margin: '5px',
		},
		'div.image-display-main:hover img': {
			opacity: '0.5',
		},
		'div.image-display-main:hover input': {
			display: 'block',
		},
		'div.image-display-main input': {
			position: 'absolute',
			display: 'none',
		},
		'div.image-display-main input.update': {
			top: '0',
			left: '0',
		},
		'div.image-display-main input.delete': {
			top: '0',
			left: '79%',
		},
		'.ReactGridGallery_tile-viewport': {
			position: 'relative',
			width: '170px !important',
			height: '170px !important',
			overflow: 'hidden',
		},
		'.ReactGridGallery_tile-icon-bar': {
			zIndex: '2',
		},
		'#lightboxBackdrop [class*=header_]': {
			marginBottom: theme.spacing(1) + ' !important',
			marginTop: theme.spacing(1) + ' !important',
		},
		'.lightbox-header-button-zoom': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		'.lightbox-header-button-save': {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		'.image-display-alert-message-grid': {
			marginTop: theme.spacing(1)
		},
	};
};

export default image_list_display;
