import WIDGETS from './widgets';
import { store } from '../store.js';

export default {
	name: 'navigation_weight_units',
	sx: {
		height: '100%',
    flexWrap: 'nowrap',
    backgroundColor: '#0060af',
    backgroundImage: (props) => {
      const { config, settings: { styling } } = store.getState();

      return styling?.files?.sidebar_background_image?.uuid
      ? `url(${config.apihost + '/configuration/files/' + styling.files.sidebar_background_image.uuid})`
      : 'none'
    },
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100% auto',  
	},
  className: 'nhs-links-sidebar-main',
	spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKNAVIGATION, breakpoints: { xs: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.NAVIGATION, breakpoints: { xs: 12 }, lazy_loading: 0, sx: { flexGrow: 1 } },
		{ content: WIDGETS.UNITS, breakpoints: { xs: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.LOGOUT, breakpoints: { xs: 12 }, lazy_loading: 0 },
	],
};
