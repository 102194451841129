import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, LinearProgress, Box, Typography } from '@mui/material';
import { closeProgressAction, cancelProgressAction } from './actions/genericActions';

class Progress extends Component {
	render() {
		return (
			<Dialog
				open={this.props.progress.open}
				fullWidth={true}
				onClose={(event, reason) => {
					if (!['escapeKeyDown', 'backdropClick'].includes(reason)) this.props.closeProgressAction();
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.progress.title}</DialogTitle>
				<DialogContent>
					{this.props.progress.description && (
						<DialogContentText id="alert-dialog-description">{this.props.progress.description}</DialogContentText>
					)}
					<Box display="flex" alignItems="center">
						<Box width="100%" mr={1}>
							<LinearProgress className="precall-volume-meter" variant="determinate" value={this.props.progress.value} />
						</Box>
						<Box minWidth={35}>
							<Typography variant="body2" color="textSecondary">{`${Math.round(this.props.progress.value)}%`}</Typography>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions className="generic-modal-progress">
					<Button
						{...(this.props.progress.button_cancel_id && { id: this.props.progress.button_cancel_id })}
						onClick={() => {
							if (this.props.progress.action) {
								this.props.progress.action(this.props.progress.props);
							}
							this.props.cancelProgressAction();
						}}
						// color="danger"
						className="icon-danger-alteri-color"
					>
						{this.props.progress.button_cancel}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => ({
	progress: state.generic.progress,
});

export default connect(mapStateToProps, { closeProgressAction, cancelProgressAction })(Progress);
