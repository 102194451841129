import WIDGETS from './widgets';

export default {
  name: 'survey',
	spacing: 2,
	widgets: [
		{
			content: WIDGETS.SURVEY,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
			// component_props: {
			// 	translations: { title: 'precall_title_meeting', subtitle: 'precall_subtitle_meeting' },
			// },
		},
	],
};
