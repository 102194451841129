const conditions = (theme) => {
	return {
		'.conditions-button-bar': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: theme.palette.grey[200],
			// width: '100%',
			position: 'relative',
			padding: theme.spacing(7),
			bottom: '10%',
			marginLeft: '-50vw',
			marginTop: '100px',
			left: '50%',
			width: '100vw',
			[theme.breakpoints.down('xl')]: {
				padding: theme.spacing(2)
			}
		},
		'.conditions-next-button': {
			backgroundColor: theme.palette.secondary.main,
			color: '#FFFFFF',
			'&:hover': {
				opacity: '0.8 !important',
				backgroundColor: theme.palette.secondary.main
			}
		},
		'.conditions-back-button': {
			backgroundColor: theme.palette.primary.main,
			color: '#FFFFFF',
			'&:hover': {
				opacity: '0.8 !important',
				backgroundColor: theme.palette.primary.main
			}
		},
		'.condition-image > img': {
			borderRadius: '4px'
		},
		'.conditions-separator': {
			backgroundColor: theme.palette.primary.main
		}
	};
};

export default conditions;
