const notifications = (theme) => {
	return {
		'.notifications-list-item-icon': {
			color: theme.palette.primary.main,
			minWidth: theme.spacing(4),
		},
		'.notification-bell': {
			width: '50px',
			height: 'inherit',
			color: theme.palette.error.main,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'relative',
			'&:before': {
				content: "'\\f0f3'",
				fontFamily: 'FontAwesome',
				display: 'block',
			},
			'&:after': {
				minWidth: '20px',
				height: '20px',
				content: 'attr(data-count)',
				backgroundColor: '#ed657d',
				fontFamily: 'monospace',
				fontWeight: 'bolt',
				fontSize: '14px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				position: 'absolute',
				top: '5px',
				right: '5px',
				transition: '.3s',
				opacity: '0',
				transform: 'scale(.5)',
				willChange: 'opacity, transform',
			},
			'&.notify': {
				'&:before': {
					animation: 'bell 1s ease-out',
					transformOrigin: 'center top',
				},
			},
			'& .show-count': {
				'&:after': {
					opacity: '1',
					transform: 'scale(1)',
				},
			},
		},
		'.notification-bell-hidden': {
			display: 'none !important',
		},
		'.notification-table-read-icon': {
			color: theme.palette.grey[600],
		},
		'.notification-modal-close-icon': {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[600],
		},
    '.message-tab-badge': {
      right: '-8px'
    }
	};
};

export default notifications;
