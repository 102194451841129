import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import CatalogueGrid from "../components/Layout/CatalogueGrid";

/*
    see this again
    How the hell ComponentName makes the "same" as a Route!?!?

*/
class PrivateRoute extends Component {
    render() {
        var { component, extraProps, catalogue, ...rest } = this.props;
        
        if (this.props.login) {
            const ComponentName = component;
            return (
                <Route>
                  {catalogue ? <CatalogueGrid catalogue={catalogue} /> : <ComponentName extraProps={extraProps} {...rest} />}
                </Route>
            );
        } else {
            return <Redirect to="/login" />;
        }
    }
}

const mapStateToProps = state => ({
    login: state.login.login
});

export default connect(mapStateToProps)(PrivateRoute);
