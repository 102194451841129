import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { enUS, sv, enGB } from 'date-fns/locale';
import { formatCodeDates } from '../../../helpers/functions/functions';
import DATE_FORMATS from '../../../helpers/date_formats';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { format } from 'date-fns';

const localeMap = {
	'en-US': enUS,
	'sv-SE': sv,
	'en-GB': enGB,
};

function RenderDatePicker({ input: { onChange, value }, meta: { invalid, touched, error, active }, ...custom }) {
	const translate = useSelector((state) => getTranslate(state.localize));
	const [errorType, setError] = useState(null);
	const [date, setDate] = useState(value === '' ? null : value);

	const warnings = {
		...(custom.inputdata.disablePast && { disablePast: translate('form_field_date_picker_warning_past_date') }),
		...(custom.inputdata.disableFuture && { disableFuture: translate('form_field_date_picker_warning_future_date') }),
	};

	useEffect(() => {
		if (typeof custom.inputdata.picker_type !== 'undefined') {
			if (typeof custom.inputdata.onChange !== 'undefined') {
				const formattedDate = date ? format(date, 'yyyy-MM-dd') : null;
				custom.inputdata.onChange(formattedDate);
			}
			setDate(value ? new Date(value) : null);
		}
	}, [value]);

	useEffect(() => {
		if (typeof custom.inputdata.picker_type === 'undefined') {
			setDate(value === '' ? null : value);
		}
	}, [value]);

	return (
		<LocalizationProvider
			dateAdapter={AdapterDateFns}
			adapterLocale={Object.keys(localeMap).includes(custom.locale) ? localeMap[custom.locale] : localeMap['en-US']}
		>
			<DatePicker
				name={custom.inputdata.name}
				format={formatCodeDates(custom.locale, DATE_FORMATS.TYPES.DATE_PICKER)}
				label={custom.inputdata.label}
				fullWidth
				inputVariant="outlined"
				onError={(newError) => {
					setError(newError);
				}}
				slotProps={{
					textField: {
						fullWidth: true,
						helperText: warnings[errorType],
						InputLabelProps: {
							classes: {
								error: warnings[errorType] && 'datepicker-warning',
							},
						},
						error: touched && invalid && !active,
						helperText: touched && !active && error,
						InputProps: {
							id: custom.inputdata.id,
							classes: {
								error: warnings[errorType] && 'datepicker-warning',
								notchedOutline: warnings[errorType] && 'datepicker-warning',
							},
						},
						FormHelperTextProps: {
							classes: { root: warnings[errorType] && 'datepicker-warning' },
						},
					},
				}}
				onChange={(date) => {
					onChange(date);
				}}
				value={date}
				disableFuture={custom.inputdata.disableFuture}
				disablePast={custom.inputdata.disablePast}
				disabled={custom.inputdata.readonly}
				error={touched && invalid && !active}
				helperText={touched && !active && error}
				openTo={custom.inputdata.openTo}
			/>
		</LocalizationProvider>
	);
}

export default RenderDatePicker;
