import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Box, Stepper, Step, StepLabel, Button, Paper, Typography, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import ConnectedIdentityVerification from './IdentityVerification';
import ConnectedUserProfile from './UserProfile';
import ConnectedInsurance from './Insurance';
import EmailConfirm from './EmailConfirm';
import BlockUI from '../../Layout/BlockUI';
import { MessageToastLogin } from '../../Generic';
import NavBar from '../../Welcome/NavBar';
import { createNewAccount } from '../../Login/actions/loginActions';
import { useParams } from 'react-router-dom';
import { fetchGroupsPublicAction } from '../../Generic/actions/genericActions';
import { push } from 'connected-react-router';
// import { swedish_ssn } from '../../../helpers/functions/functions';

export const Overview = ({ translate, registration_fields, settings, ...props }) => {
	const dispatch = useDispatch();
	let { origin } = useParams();
	const [activeStep, setActiveStep] = useState(0);
	const [verify, setVerify] = useState(false);

	useEffect(() => {
		if (registration_fields.find((elem) => elem.field == 'groups_id') && registration_fields.find((elem) => elem.field == 'groups_id').display) {
			dispatch(fetchGroupsPublicAction());
		}
	}, []);

	useEffect(() => {
		if (verify) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	}, [verify]);

	useEffect(() => {
		setActiveStep(0);
	}, [origin]);

	const ssnActive = registration_fields.find((elem) => elem.field === 'ssn') && registration_fields.find((elem) => elem.field === 'ssn').display;

	const shouldShowInsuranceStep =
		typeof settings.feature !== 'undefined' &&
		settings.feature.insurances &&
		registration_fields.find((elem) => elem.field === 'insurance') &&
		registration_fields.find((elem) => elem.field === 'insurance').display;

	const handleVerify = (value) => {
		setVerify(value);
	};
	const handleCreateUserSubmitSuccess = () => {
		dispatch(
			createNewAccount('create_new_account_user_profile', verify, (value) => {
				if (value && activeStep + 1 < steps.length) {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				} else {
          /**TODO: Check if valid swedish ssn and redirect to bankid login if exists? */
          // console.log(props?.form_create_new_account_search?.values?.ssn)
					let login_method = 'password';
						// props?.form_create_new_account_search?.values?.ssn && swedish_ssn(props.form_create_new_account_search.values.ssn)
						// 	? 'bankid'
						// 	: 'password';
					props.push('/login?login_method=' + login_method);
				}
			})
		);
	};

	const handleSearchSubmitSuccess = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleCreateInsuranceSubmitSuccess = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const identityVerificationStep = {
		label: 'overview_register_identity_verification',
		content: <ConnectedIdentityVerification verify={handleVerify} onSubmitSuccess={handleSearchSubmitSuccess} />,
		nextAction: () => {
			dispatch(submit('create_new_account_search'));
		},
	};

	const insuranceStep = {
		label: 'overview_register_insurance',
		content: <ConnectedInsurance onSubmitSuccess={handleCreateInsuranceSubmitSuccess} />,
		nextAction: () => {
			dispatch(submit('create_new_insurance'));
		},
		backAction: () => {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
			setVerify(false);
		},
	};

	const userProfileStep = {
		label: 'overview_register_user_profile',
		content: <ConnectedUserProfile onSubmitSuccess={handleCreateUserSubmitSuccess} />,
		nextAction: () => {
			dispatch(submit('create_new_account_user_profile'));
		},
		backAction: () => {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
			setVerify(false);
		},
	};

	const emailConfirmStep = {
		label: 'overview_register_email_confirm',
		content: <EmailConfirm />,
	};

	const steps =
		origin === 'login'
			? [emailConfirmStep]
			: !ssnActive && settings.auth.contactConfirmation
			? [userProfileStep, emailConfirmStep]
			: !ssnActive && !settings.auth.contactConfirmation
			? [userProfileStep]
			: !shouldShowInsuranceStep && ssnActive && settings.auth.contactConfirmation
			? [
					{
						...identityVerificationStep,
						nextAction: () => {
							if (registration_fields.find((elem) => elem.field === 'ssn')?.required) {
								dispatch(submit('create_new_account_search'));
							} else {
								setActiveStep((prevActiveStep) => prevActiveStep + 1);
							}
						},
					},
					userProfileStep,
					emailConfirmStep,
			  ]
			: !shouldShowInsuranceStep && ssnActive && !settings.auth.contactConfirmation
			? [
					{
						...identityVerificationStep,
						nextAction: () => {
							if (registration_fields.find((elem) => elem.field === 'ssn')?.required) {
								dispatch(submit('create_new_account_search'));
							} else {
								setActiveStep((prevActiveStep) => prevActiveStep + 1);
							}
						},
					},
					userProfileStep,
			  ]
			: !settings.auth.contactConfirmation
			? [identityVerificationStep, insuranceStep, userProfileStep]
			: [identityVerificationStep, insuranceStep, userProfileStep, emailConfirmStep];

	const handleNext = () => {
		const currentStep = steps[activeStep];
		currentStep?.nextAction();
	};

	const handleBack = () => {
		const currentStep = steps[activeStep];
		currentStep?.backAction();
	};

	const renderButtonNext = () => {
		const currentStep = steps[activeStep];

		if (currentStep?.nextAction) {
			return (
				<Button onClick={handleNext} disabled={ssnActive && registration_fields.find((elem) => elem.field === 'ssn')?.required && !verify}>
					{translate('overview_invite_next')}
				</Button>
			);
		}

		return null;
	};

	const renderButtonBack = () => {
		const currentStep = steps[activeStep];

		if (currentStep?.backAction) {
			return (
				<Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
					{translate('overview_invite_back')}
				</Button>
			);
		}

		return null;
	};

	return (
		<div className={'generic-defaultlayout-main generic-width-max'}>
			<MessageToastLogin />
			<NavBar disabledPositionFixed={true} />
			<Container
				component="main"
				// maxWidth={this.props.fullsizeContainer ? false : 'lg'}
				className="generic-defaultlayout-content"
				// disableGutters={this.props.disableGutters}
			>
				<BlockUI />
				<Typography align="center" variant="h2" component="h2" gutterBottom>
					{translate('user_register_register_new_account_title')}
				</Typography>
				<Typography align="center" variant="subtitle2" component="p" gutterBottom>
					{translate('user_register_new_account_subtitle')}
				</Typography>
				<Paper elevation={0} className="widgets-content-nhs" sx={{ mt: 2 }}>
					<Box sx={{ m: 2 }}>
						<Stepper activeStep={activeStep}>
							{steps.map((step, index) => {
								const stepProps = {};
								const labelProps = {};

								return (
									<Step key={index} {...stepProps}>
										<StepLabel {...labelProps}>{translate(step.label)}</StepLabel>
									</Step>
								);
							})}
						</Stepper>

						<React.Fragment>
							{steps[activeStep] && steps[activeStep].content}
							<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
								{ssnActive && renderButtonBack()}
								<Box sx={{ flex: '1 1 auto' }} />
								{renderButtonNext()}
							</Box>
						</React.Fragment>
					</Box>
				</Paper>
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	registration_fields: state.settings.users.registration_fields,
	settings: state.settings,
	// form_create_new_account_search: state.form?.create_new_account_search,
});

export default connect(mapStateToProps, { push })(Overview);
