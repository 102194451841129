import { apiRequest } from '../../../helpers/functions/request.js';
import { store } from '../../../store.js';
import { getTranslate } from 'react-localize-redux';
import { FETCH_TICKETS_ANALYTIC, FETCH_TICKET_ANALYTIC_LOGS, CLEAN_TICKET_LOGS } from './types.js';
import { HANDLE_TABLE_LOADING } from '../../Generic/actions/types.js';
import axios from 'axios';
import { setTableCountAction } from '../../Generic/actions/tableActions.js';
import { logoutAction } from '../../Login/actions/loginActions.js';

export const createAnalyticAction = (event, ref_type, ref_id, metadata) => async (dispatch) => {
	const state = store.getState();
	if (!state.settings.feature.analytics) {
		return;
	}
	const data = {
		event: event,
		ref_type: ref_type,
		ref_id: ref_id,
		metadata: JSON.stringify(metadata),
	};

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/analytics`,
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

export const fetchAnalyticsTicketsAction =
	(table_id, config, pagination = {}, filters = {}) =>
	(dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		var params = { ...pagination, ...filters };

		var columns = [];

		config.map((field) => {
			if (field.options.renderOnExpand) {
				field.options.display = 'excluded';
			}
			columns.push({
				name: field.label,
				label: translate('table_column_' + field.label),
				options: {
					...field.options,
				},
			});
		});

		dispatch({
			type: FETCH_TICKETS_ANALYTIC,
			payload: {
				columns: columns,
				data: [],
				count: 0,
			},
		});

		dispatch({
			type: HANDLE_TABLE_LOADING,
			payload: { table_id, loading: true },
		});

		setTimeout(() => {
			axios
				.get(state.config.apihost + `/analytics/tickets`, {
					params,
				})
				.then((res) => {
					dispatch({
						type: HANDLE_TABLE_LOADING,
						payload: { table_id, loading: false },
					});
					var i = 0;
					dispatch({
						type: FETCH_TICKETS_ANALYTIC,
						payload: {
							columns: columns,
							data: res.data.items.map((entry) => ({ ...entry, index: i++ })),
							count: res.data.total_items,
						},
					});

					dispatch(setTableCountAction(table_id, res.data.total_items));
				})
				.catch((err) => {
					if (err.request.status === 401) {
						dispatch(logoutAction(true, true, translate('inactivity_logout_info_message')));
					} else {
						dispatch({
							type: HANDLE_TABLE_LOADING,
							payload: { table_id, loading: false },
						});

						console.log(err);
						console.log('Error fetching data.');
					}
				});
		}, 1000);
	};

export const fetchAnalyticsTicketAction = (ref_id, search, start, end) => async (dispatch) => {
	const state = store.getState();

	const params = {
		search: search,
		start: start,
		end: end,
		limit: 999999,
	};

	const response = await apiRequest({
		method: 'get',
		url: state.config.apihost + `/analytics/search/` + ref_id,
		params: params,
		headers: { 'Content-Type': 'multipart/form-data' },
	});

	return dispatch({
		type: FETCH_TICKET_ANALYTIC_LOGS,
		payload: response.data.items,
	});
};
export const cleanTicketLogsAction = () => (dispatch) => {
	dispatch({
		type: 'CLEAN_TICKET_LOGS',
	});
};
