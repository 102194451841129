import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@mui/material';
import { connect } from 'react-redux';
import CatalogueGrid from './CatalogueGrid';
import genericActions from '../Generic/actions/genericActions';
import { catalogue_structure } from '../../catalogues';

export const ModalCatalogue = ({ open, catalogue, handleModalCatalogue, dialog_props }) => {
	return (
		<Dialog onClose={() => handleModalCatalogue(false)} open={open} {...dialog_props}>
			<DialogContent {...dialog_props}>
				<CatalogueGrid catalogue={catalogue} />
			</DialogContent>
		</Dialog>
	);
};

ModalCatalogue.propTypes = {
	open: PropTypes.bool.isRequired,
	catalogue: PropTypes.shape(catalogue_structure),
	handleModalCatalogue: PropTypes.func.isRequired,
	dialog_props: PropTypes.shape({
		fullScreen: PropTypes.bool,
		fullWidth: PropTypes.bool,
		maxWidth: PropTypes.string,
	}),
};

const mapStateToProps = (state) => ({
	open: state.generic.modal_catalogue.open,
	catalogue: state.generic.modal_catalogue.catalogue,
	dialog_props: state.generic.modal_catalogue.dialog_props,
});

const mapDispatchToProps = { handleModalCatalogue: genericActions.handleModalCatalogue };

export default connect(mapStateToProps, mapDispatchToProps)(ModalCatalogue);
