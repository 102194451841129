import NettoWebFont from './fonts/NettoWeb.woff';
import WorkSansFont from './fonts/work-sans-v11-latin-regular.woff';
import GlyphiconsHalflingsFont from './fonts/glyphicons-halflings-regular.woff';

import ChronicleDeck_Black_Font from './fonts/ChronicleDeck/ChronicleDeck-Black.otf';
import ChronicleDeck_BlackItalic_Font from './fonts/ChronicleDeck/ChronicleDeck-BlackItalic.otf';
import ChronicleDeck_Bold_Font from './fonts/ChronicleDeck/ChronicleDeck-Bold.otf';
import ChronicleDeck_BoldItalic_Font from './fonts/ChronicleDeck/ChronicleDeck-BoldItalic.otf';
import ChronicleDeck_Italic_Font from './fonts/ChronicleDeck/ChronicleDeck-Italic.otf';
import ChronicleDeck_Roman_Font from './fonts/ChronicleDeck/ChronicleDeck-Roman.otf';
import ChronicleDeck_SemiBold_Font from './fonts/ChronicleDeck/ChronicleDeck-Semibold.otf';
import ChronicleDeck_SemiItalic_Font from './fonts/ChronicleDeck/ChronicleDeck-Semitalic.otf';

import ChronicleText_Bold_Font from './fonts/ChronicleText/ChronicleTextG1-Bold-Pro.otf';
import ChronicleText_BoldItalic_Font from './fonts/ChronicleText/ChronicleTextG1-BoldIta-Pro.otf';
import ChronicleText_Italic_Font from './fonts/ChronicleText/ChronicleTextG1-Italic-Pro.otf';
import ChronicleText_Roman_Font from './fonts/ChronicleText/ChronicleTextG1-Roman-Pro.otf';
import ChronicleText_SemiBold_Font from './fonts/ChronicleText/ChronicleTextG1-Semibd-Pro.otf';
import ChronicleText_SemiItalic_Font from './fonts/ChronicleText/ChronicleTextG1-SemiIta-Pro.otf';

import Maax_Bold_Italic_Font from './fonts/Maax/Maax-Bold-Italic-205TF.woff';
import Maax_Bold_Font from './fonts/Maax/Maax-Bold-205TF.woff';
import Maax_Italic_Font from './fonts/Maax/Maax-Italic-205TF.woff';
import Maax_Regular_Font from './fonts/Maax/Maax-Regular-205TF.woff';

import RobotoRegularFont from './fonts/Roboto/Roboto-Regular.ttf';
import RobotoBoldFont from './fonts/Roboto/Roboto-Bold.ttf';
import RobotoBlackFont from './fonts/Roboto/Roboto-Black.ttf';
import RobotoBlackItalicFont from './fonts/Roboto/Roboto-BlackItalic.ttf';
import RobotoBoldItalicFont from './fonts/Roboto/Roboto-BoldItalic.ttf';
import RobotoItalicFont from './fonts/Roboto/Roboto-Italic.ttf';
import RobotoLightFont from './fonts/Roboto/Roboto-Light.ttf';
import RobotoLightItalicFont from './fonts/Roboto/Roboto-LightItalic.ttf';
import RobotoMediumFont from './fonts/Roboto/Roboto-Medium.ttf';
import RobotoMediumItalicFont from './fonts/Roboto/Roboto-MediumItalic.ttf';
import RobotoThinFont from './fonts/Roboto/Roboto-Thin.ttf';
import RobotoThinItalicFont from './fonts/Roboto/Roboto-ThinItalic.ttf';

import OpenSansBoldFont from './fonts/Open_Sans/OpenSans-Bold.ttf';
import OpenSansBoldItalicFont from './fonts/Open_Sans/OpenSans-BoldItalic.ttf';
import OpenSansExtraBoldFont from './fonts/Open_Sans/OpenSans-ExtraBold.ttf';
import OpenSansExtraBoldItalicFont from './fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf';
import OpenSansItalicFont from './fonts/Open_Sans/OpenSans-Italic.ttf';
import OpenSansLightFont from './fonts/Open_Sans/OpenSans-Light.ttf';
import OpenSansLightItalicFont from './fonts/Open_Sans/OpenSans-LightItalic.ttf';
import OpenSansMediumFont from './fonts/Open_Sans/OpenSans-Medium.ttf';
import OpenSansMediumItalicFont from './fonts/Open_Sans/OpenSans-MediumItalic.ttf';
import OpenSansRegularFont from './fonts/Open_Sans/OpenSans-Regular.ttf';
import OpenSansSemiBoldFont from './fonts/Open_Sans/OpenSans-SemiBold.ttf';
import OpenSansSemiBoldItalicFont from './fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf';

export const NettoWeb = {
	fontFamily: 'NettoWeb',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${NettoWebFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const RobotoRegular = {
	fontFamily: 'RobotoRegular',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoRegularFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const RobotoBold = {
	fontFamily: 'RobotoBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoBoldFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const WorkSans = {
	fontFamily: 'WorkSans',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${WorkSansFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const GlyphiconsHalflings = {
	fontFamily: 'GlyphiconsHalflings',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${GlyphiconsHalflingsFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Black = {
	fontFamily: 'ChronicleDeck_Black',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Black_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_BlackItalic = {
	fontFamily: 'ChronicleDeck_BlackItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_BlackItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Bold = {
	fontFamily: 'ChronicleDeck_Bold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Bold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_BoldItalic = {
	fontFamily: 'ChronicleDeck_BoldItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_BoldItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Italic = {
	fontFamily: 'ChronicleDeck_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Italic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_Roman = {
	fontFamily: 'ChronicleDeck_Roman',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_Roman_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_SemiBold = {
	fontFamily: 'ChronicleDeck_SemiBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_SemiBold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleDeck_SemiItalic = {
	fontFamily: 'ChronicleDeck_SemiItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleDeck_SemiItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_Bold = {
	fontFamily: 'ChronicleText_Bold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_Bold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_BoldItalic = {
	fontFamily: 'ChronicleText_BoldItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_BoldItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_Italic = {
	fontFamily: 'ChronicleText_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_Italic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_Roman = {
	fontFamily: 'ChronicleText_Roman',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_Roman_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_SemiBold = {
	fontFamily: 'ChronicleText_SemiBold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_SemiBold_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ChronicleText_SemiItalic = {
	fontFamily: 'ChronicleText_SemiItalic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${ChronicleText_SemiItalic_Font}) format('opentype')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Bold_Italic = {
	fontFamily: 'Maax_Bold_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Bold_Italic_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Bold = {
	fontFamily: 'Maax_Bold',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Bold_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Italic = {
	fontFamily: 'Maax_Italic',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Italic_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const Maax_Regular = {
	fontFamily: 'Maax_Regular',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${Maax_Regular_Font}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoBlack = {
	fontFamily: 'RobotoBlack',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 900,
	src: `url(${RobotoBlackFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const RobotoBlackItalic = {
	fontFamily: 'RobotoBlackItalic',
	fontStyle: 'italic',
	fontDisplay: 'swap',
	fontWeight: 900,
	src: `url(${RobotoBlackItalicFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const RobotoBoldItalic = {
	fontFamily: 'RobotoBoldItalic',
	fontStyle: 'italic',
	fontDisplay: 'swap',
	fontWeight: 700,
	src: `url(${RobotoBoldItalicFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoItalic = {
	fontFamily: 'RobotoItalic',
	fontStyle: 'italic',
	fontDisplay: 'swap',
	fontWeight: 400,
	src: `url(${RobotoItalicFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoLight = {
	fontFamily: 'RobotoLight',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 300,
	src: `url(${RobotoLightFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoLightItalic = {
	fontFamily: 'RobotoLightItalic',
	fontStyle: 'italic',
	fontDisplay: 'swap',
	fontWeight: 300,
	src: `url(${RobotoLightItalicFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoMedium = {
	fontFamily: 'RobotoMedium',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 500,
	src: `url(${RobotoMediumFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoMediumItalic = {
	fontFamily: 'RobotoMediumItalic',
	fontStyle: 'italic',
	fontDisplay: 'swap',
	fontWeight: 500,
	src: `url(${RobotoMediumItalicFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoThin = {
	fontFamily: 'RobotoThin',
	fontStyle: 'normal',
	fontDisplay: 'swap',
	fontWeight: 100,
	src: `url(${RobotoThinFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const RobotoThinItalic = {
	fontFamily: 'RobotoThinItalic',
	fontStyle: 'italic',
	fontDisplay: 'swap',
	fontWeight: 100,
	src: `url(${RobotoThinItalicFont}) format('woff')`,
	unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansBold = {
  fontFamily: 'OpenSansBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${OpenSansBoldFont}) format('truetype')`,
  unicodeRange:
		'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansBoldItalic = {
  fontFamily: 'OpenSansBoldItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${OpenSansBoldItalicFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansExtraBold = {
  fontFamily: 'OpenSansExtraBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `url(${OpenSansExtraBoldFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansExtraBoldItalic = {
  fontFamily: 'OpenSansExtraBoldItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `url(${OpenSansExtraBoldItalicFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansItalic = {
  fontFamily: 'OpenSansItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${OpenSansItalicFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansLight = {
  fontFamily: 'OpenSansLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${OpenSansLightFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansLightItalic = {
  fontFamily: 'OpenSansLightItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${OpenSansLightItalicFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansMedium = {
  fontFamily: 'OpenSansMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${OpenSansMediumFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansMediumItalic = {
  fontFamily: 'OpenSansMediumItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${OpenSansMediumItalicFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansRegular = {
  fontFamily: 'OpenSansRegular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${OpenSansRegularFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansSemiBold = {
  fontFamily: 'OpenSansSemiBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${OpenSansSemiBoldFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
export const OpenSansSemiBoldItalic = {
  fontFamily: 'OpenSansSemiBoldItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `url(${OpenSansSemiBoldItalicFont}) format('woff')`,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};