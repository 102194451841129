/**Analytic logs */
const OPEN_CASE = 'open_case';
const OPEN_CHAT = 'open_chat';
const OPEN_OVERVIEW = 'open_overview';
const OPEN_VIDEO = 'open_video';
const ADD_MEMBER_CARETEAM = 'add_member_careteam';
const REMOVE_MEMBER_CARETEAM = 'remove_member_careteam';
const SCHEDULE_MEETING = 'schedule_meeting';
const SHARE_FILE = 'share_file';
const UPLOAD_FILE = 'upload_file';
const OPEN_FILE = 'open_file';
const SEND_QUESTIONNAIRE = 'send_questionnaire';
const OPEN_QUESTIONNAIRE = 'open_questionnaire';
const CREATE_JOURNAL = 'create_journal';
const SIGN_JOURNAL = 'sign_journal';
const REOPEN_JOURNAL = 'reopen_journal';
const CHANGE_JOURNAL = 'change_journal';
const OPEN_JOURNAL = 'open_journal';
const CREATE_OBSERVANDUM = 'create_observandum';
const OPEN_WARNING = 'open_warning';
const OPEN_HISTORY = 'open_history';
const SHARE_SELFCARE = 'share_selfcare';
const CREATE_REFERRAL = 'create_referral';
const SCHEDULE_FOLLOWUP = 'schedule_followup';
const CREATE_NOTES = 'create_notes';
const CLOSE_CASE = 'close_case';
const OPEN_SUMMARY = 'open_summary';

export default {
	OPEN_CASE,
	OPEN_CHAT,
	OPEN_OVERVIEW,
	OPEN_VIDEO,
	ADD_MEMBER_CARETEAM,
	REMOVE_MEMBER_CARETEAM,
	SCHEDULE_MEETING,
	SHARE_FILE,
	UPLOAD_FILE,
	OPEN_FILE,
	SEND_QUESTIONNAIRE,
	OPEN_QUESTIONNAIRE,
	CREATE_JOURNAL,
	SIGN_JOURNAL,
	REOPEN_JOURNAL,
	CHANGE_JOURNAL,
	OPEN_JOURNAL,
	CREATE_OBSERVANDUM,
	OPEN_WARNING,
	OPEN_HISTORY,
	SHARE_SELFCARE,
	CREATE_REFERRAL,
	SCHEDULE_FOLLOWUP,
	CREATE_NOTES,
  CLOSE_CASE,
	OPEN_SUMMARY,
};
