export const WHOAMI = 'WHOAMI';
export const FETCH_USERS_EVENTS = 'FETCH_USERS_EVENTS';
export const ADD_USER_EVENTS = 'ADD_USER_EVENTS';
export const POST_USERS_EVENTS = 'POST_USERS_EVENTS';
export const POST_USERS_AVAILABLE_HOURS = 'POST_USERS_AVAILABLE_HOURS';
export const MENU_ACTIVE_ACTION = 'MENU_ACTIVE_ACTION';
export const PATCH_USERS_EVENTS = 'PATCH_USERS_EVENTS';
export const PATCH_CANCELLED_EVENT = 'PATCH_CANCELLED_EVENT';
export const GET_USER_EVENT = 'GET_USER_EVENT';
export const FETCH_MEETING_INTERNAL_ATTENDEES = 'FETCH_MEETING_INTERNAL_ATTENDEES';
export const FETCH_MEETING_EXTERNAL_ATTENDEES = 'FETCH_MEETING_EXTERNAL_ATTENDEES';
export const REMOVE_MEETING_INTERNAL_ATTENDEE = 'REMOVE_MEETING_INTERNAL_ATTENDEE';
export const REMOVE_MEETING_EXTERNAL_ATTENDEE = 'REMOVE_MEETING_EXTERNAL_ATTENDEE';
export const SET_MEETING_EVENT_INVITEES = 'SET_MEETING_EVENT_INVITEES';
export const GET_USER_SCHEDULE = 'GET_USER_SCHEDULE';
export const GET_CONTACTS_LIST = 'GET_CONTACTS_LIST';
export const UPDATE_FREETIMES = 'UPDATE_FREETIMES';

