import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';

function RenderSingleCheckbox({ input: { onChange, value }, meta: { invalid, touched, error,active }, ...custom }) {
	return (
		<FormControl error={touched && invalid && !active}>
			<FormControlLabel
				control={
					<Checkbox
						value={value}
						checked={value ? true : false}
						onChange={(event) => {
							if (typeof custom.inputdata.onChange !== 'undefined') {
								custom.inputdata.onChange(event.target.checked);
							}
							onChange(event);
						}}
					/>
				}
				label={custom.inputdata.label}
			/>
			<FormHelperText>{touched && !active && error}</FormHelperText>
		</FormControl>
	);
}

export default RenderSingleCheckbox;
