import WIDGETS from './widgets';

export default {
  name: 'freetime_form',
	sx: {
		height: '100%',
    flexWrap: 'nowrap'
	},
	spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.FREETIME_FORM, breakpoints: { xs: 12 }, lazy_loading: 0, sx: { flexGrow: 1 } },
	],
};
