import React, { useEffect } from 'react';
import { Typography } from '@mui/material';

import ToastEditor from '../ToastEditor';

function RenderWysiwyg({ input, meta, ...custom }) {
	const [inputInitialValue, setInputInitialValue] = React.useState(custom.inputdata.initialvalue);
	const [loading, setLoading] = React.useState(true);

	useEffect(() => {
		setInputInitialValue(custom.inputdata.initialvalue);
	}, [custom.inputdata.initialvalue]);

	setTimeout(() => {
		setLoading(false);
	}, 1000);

	return (
		<div>
			<Typography id="continuous-slider" gutterBottom>
				{custom.inputdata.label}
			</Typography>
			<ToastEditor
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				// onChange={input.onChange}
				onChange={(markdown, html) => {
					input.onChange(markdown);
					if (typeof custom.inputdata.onChange !== 'undefined') {
						custom.inputdata.onChange(markdown, html);
					}
				}}
				initialValue={inputInitialValue}
				loading={loading}
			/>
		</div>
	);
}

export default RenderWysiwyg;
