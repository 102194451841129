import { FETCH_TAGGED_FILES, FETCH_MY_FILES } from '../actions/types';

const initialState = {
	tagged_files: [],
	my_files: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_TAGGED_FILES:
			return {
				...state,
				tagged_files: action.payload,
			};
		case FETCH_MY_FILES:
			return {
				...state,
				my_files: action.payload,
			};
		default:
			return state;
	}
}
