import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { TYPES } from '../FormTypes';

class FormField extends Component {
	render() {
		return this.props.type !== TYPES.NONE && Object.values(TYPES).includes(this.props.type) ? (
			<Grid
				item
				xs={this.props.grid_layout ? this.props.grid_layout.xs : 12}
				sm={this.props.grid_layout ? this.props.grid_layout.sm : 12}
				md={this.props.grid_layout ? this.props.grid_layout.md : 12}
				{...(this.props.grid_layout && this.props.grid_layout.lg ? { lg: this.props.grid_layout.lg } : {})}
				key={this.props.form + '_form_field_' + this.props.i}
				{...(this.props.type == 'hidden' && {
					style: { display: 'none' },
				})}
			>
				{this.props.children}
			</Grid>
		) : null;
	}
}

export default FormField;

FormField.propTypes = {
	id: PropTypes.string.isRequired,
	i: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
};
