import React from 'react';
import { FilledInput } from '@mui/material';

function RenderFileField({ input, meta: { invalid, touched, error, active }, ...custom }) {
	return (
		<FilledInput
			{...input}
			id={custom.inputdata.id}
			name={custom.inputdata.name}
			label={custom.inputdata.label}
			placeholder={custom.inputdata.placeholder}
			fullWidth
			variant="outlined"
			disabled={custom.inputdata.readonly}
			error={touched && invalid && !active}
			helperText={touched && !active && error}
			onChange={(event) => {
				input.onChange(event);
				if (typeof custom.inputdata.onChange !== 'undefined') {
					custom.inputdata.onChange(event.target.value);
				}
			}}
			type={custom.inputdata.type}
			size={custom.inputdata.size ? custom.inputdata.size : null}
			{...custom}
			inputProps={{
				step: custom.inputdata.props ? custom.inputdata.props.step : null,
				min: custom.inputdata.props ? custom.inputdata.props.min : null,
				className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
			}}
		/>
		// required={(custom.inputdata.validate && custom.inputdata.validate.required) ? true : false}
	);
}

export default RenderFileField;
