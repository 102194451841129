import {
	FETCH_QUESTIONNAIRES_STATISTICS,
	FETCH_QUESTION_GROUPS,
	FETCH_STATISTICS_CONTACTS,
	FETCH_STATISTICS_CONTACTS_INTERVAL,
	FETCH_STATISTICS_CONTACTS_CATEGORY,
	FETCH_STATISTICS_CONTACTS_CATEGORY_GROUP,
	FETCH_STATISTICS_CONTACTS_TYPE,
	FETCH_STATISTICS_CONTACTS_TAG_GROUP,
	FETCH_STATISTICS_TICKETS_REFERRAL,
	FETCH_STATISTICS_CONTACTS_GENDER,
	FETCH_STATISTICS_CONTACTS_PRONOUN,
	SET_FROM_DATE,
	SET_TO_DATE,
	SET_CLIENT,
	SET_INTERVAL,
} from '../actions/types';

const initialState = {
	questionnaires: [],
	question_groups: [],
	statistics_contacts: null,
	statistics_contacts_interval: [],
	statistics_contacts_catergory: null,
	statistics_contacts_catergory_group: null,
	statistics_contacts_type: null,
	statistics_contacts_tag_group: null,
	statistics_ticket_referral: null,
	statistics_contacts_gender: null,
	statistics_contacts_pronoun: null,
	dates: {
		from: null,
		to: null,
		clients: null,
	},
	interval: 30,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_QUESTIONNAIRES_STATISTICS:
			const newArray = [...state.questionnaires];

			const index = state.questionnaires.findIndex((e) => e.id === action.payload.id);

			if (index === -1) {
				newArray.push(action.payload);
			} else {
				newArray[index] = action.payload;
			}

			return {
				...state,
				questionnaires: newArray,
			};
		case FETCH_QUESTION_GROUPS:
			const newQuestionsArray = [...state.question_groups];

			const indexQuestions = state.question_groups.findIndex((e) => e.id === action.payload.id);

			if (indexQuestions === -1) {
				newQuestionsArray.push(action.payload);
			} else {
				newQuestionsArray[indexQuestions] = action.payload;
			}

			return {
				...state,
				question_groups: newQuestionsArray,
			};
		case FETCH_STATISTICS_CONTACTS:
			return {
				...state,
				statistics_contacts: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_INTERVAL:
			return {
				...state,
				statistics_contacts_interval: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_CATEGORY:
			return {
				...state,
				statistics_contacts_catergory: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_CATEGORY_GROUP:
			return {
				...state,
				statistics_contacts_catergory_group: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_TYPE:
			return {
				...state,
				statistics_contacts_type: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_TAG_GROUP:
			return {
				...state,
				statistics_contacts_tag_group: action.payload,
			};
		case FETCH_STATISTICS_TICKETS_REFERRAL:
			return {
				...state,
				statistics_ticket_referral: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_GENDER:
			return {
				...state,
				statistics_contacts_gender: action.payload,
			};
		case FETCH_STATISTICS_CONTACTS_PRONOUN:
			return {
				...state,
				statistics_contacts_pronoun: action.payload,
			};
		case SET_FROM_DATE:
			return {
				...state,
				dates: {
					...state.dates,
					from: action.payload,
				},
			};
		case SET_TO_DATE:
			return {
				...state,
				dates: {
					...state.dates,
					to: action.payload,
				},
			};
		case SET_CLIENT:
			return {
				...state,
				dates: {
					...state.dates,
					clients: action.payload,
				},
			};
		case SET_INTERVAL:
			return {
				...state,
				interval: action.payload,
			};
		default:
			return state;
	}
}
