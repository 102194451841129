import WIDGETS from './widgets';

export default {
  name: 'ticket_invite',
	spacing: 2,
	widgets: [
		{
			content: WIDGETS.PAGE_TITLE,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
			component_props: {
				translations: { title: 'ticket_form_invite_title', subtitle: 'ticket_form_invite_subtitle' },
			},
		},

		{ content: WIDGETS.INVITE_OVERVIEW, breakpoints: { xs: 12 }, lazy_loading: 0 },
	],
};
