import zw_flag from '../assets/country_flags/zw.png';
import zm_flag from '../assets/country_flags/zm.png';
import za_flag from '../assets/country_flags/za.png';
import yt_flag from '../assets/country_flags/yt.png';
import ye_flag from '../assets/country_flags/ye.png';
import xk_flag from '../assets/country_flags/xk.png';
import ws_flag from '../assets/country_flags/ws.png';
import wf_flag from '../assets/country_flags/wf.png';
import vu_flag from '../assets/country_flags/vu.png';
import vn_flag from '../assets/country_flags/vn.png';
import vi_flag from '../assets/country_flags/vi.png';
import vg_flag from '../assets/country_flags/vg.png';
import ve_flag from '../assets/country_flags/ve.png';
import vc_flag from '../assets/country_flags/vc.png';
import uz_flag from '../assets/country_flags/uz.png';
import uy_flag from '../assets/country_flags/uy.png';
import us_flag from '../assets/country_flags/us.png';
import um_flag from '../assets/country_flags/um.png';
import ug_flag from '../assets/country_flags/ug.png';
import ua_flag from '../assets/country_flags/ua.png';
import tz_flag from '../assets/country_flags/tz.png';
import tw_flag from '../assets/country_flags/tw.png';
import tv_flag from '../assets/country_flags/tv.png';
import tt_flag from '../assets/country_flags/tt.png';
import tr_flag from '../assets/country_flags/tr.png';
import to_flag from '../assets/country_flags/to.png';
import tn_flag from '../assets/country_flags/tn.png';
import tm_flag from '../assets/country_flags/tm.png';
import tl_flag from '../assets/country_flags/tl.png';
import tk_flag from '../assets/country_flags/tk.png';
import tj_flag from '../assets/country_flags/tj.png';
import th_flag from '../assets/country_flags/th.png';
import tg_flag from '../assets/country_flags/tg.png';
import td_flag from '../assets/country_flags/td.png';
import tc_flag from '../assets/country_flags/tc.png';
import sy_flag from '../assets/country_flags/sy.png';
import sx_flag from '../assets/country_flags/sx.png';
import sv_flag from '../assets/country_flags/sv.png';
import st_flag from '../assets/country_flags/st.png';
import sr_flag from '../assets/country_flags/sr.png';
import so_flag from '../assets/country_flags/so.png';
import sn_flag from '../assets/country_flags/sn.png';
import sm_flag from '../assets/country_flags/sm.png';
import sl_flag from '../assets/country_flags/sl.png';
import sk_flag from '../assets/country_flags/sk.png';
import sj_flag from '../assets/country_flags/sj.png';
import si_flag from '../assets/country_flags/si.png';
import sh_flag from '../assets/country_flags/sh.png';
import sg_flag from '../assets/country_flags/sg.png';
import se_flag from '../assets/country_flags/se.png';
import sd_flag from '../assets/country_flags/sd.png';
import sb_flag from '../assets/country_flags/sb.png';
import sa_flag from '../assets/country_flags/sa.png';
import rw_flag from '../assets/country_flags/rw.png';
import ru_flag from '../assets/country_flags/ru.png';
import ro_flag from '../assets/country_flags/ro.png';
import re_flag from '../assets/country_flags/re.png';
import py_flag from '../assets/country_flags/py.png';
import pw_flag from '../assets/country_flags/pw.png';
import pt_flag from '../assets/country_flags/pt.png';
import pr_flag from '../assets/country_flags/pr.png';
import pn_flag from '../assets/country_flags/pn.png';
import pm_flag from '../assets/country_flags/pm.png';
import pl_flag from '../assets/country_flags/pl.png';
import pk_flag from '../assets/country_flags/pk.png';
import ph_flag from '../assets/country_flags/ph.png';
import pg_flag from '../assets/country_flags/pg.png';
import pf_flag from '../assets/country_flags/pf.png';
import pe_flag from '../assets/country_flags/pe.png';
import pa_flag from '../assets/country_flags/pa.png';
import om_flag from '../assets/country_flags/om.png';
import nz_flag from '../assets/country_flags/nz.png';
import nu_flag from '../assets/country_flags/nu.png';
import np_flag from '../assets/country_flags/np.png';
import no_flag from '../assets/country_flags/no.png';
import nl_flag from '../assets/country_flags/nl.png';
import ni_flag from '../assets/country_flags/ni.png';
import ng_flag from '../assets/country_flags/ng.png';
import nf_flag from '../assets/country_flags/nf.png';
import ne_flag from '../assets/country_flags/ne.png';
import nc_flag from '../assets/country_flags/nc.png';
import na_flag from '../assets/country_flags/na.png';
import mz_flag from '../assets/country_flags/mz.png';
import my_flag from '../assets/country_flags/my.png';
import mx_flag from '../assets/country_flags/mx.png';
import mw_flag from '../assets/country_flags/mw.png';
import mv_flag from '../assets/country_flags/mv.png';
import mu_flag from '../assets/country_flags/mu.png';
import mt_flag from '../assets/country_flags/mt.png';
import mr_flag from '../assets/country_flags/mr.png';
import mq_flag from '../assets/country_flags/mq.png';
import mp_flag from '../assets/country_flags/mp.png';
import mo_flag from '../assets/country_flags/mo.png';
import mn_flag from '../assets/country_flags/mn.png';
import mm_flag from '../assets/country_flags/mm.png';
import ml_flag from '../assets/country_flags/ml.png';
import mk_flag from '../assets/country_flags/mk.png';
import mh_flag from '../assets/country_flags/mh.png';
import mg_flag from '../assets/country_flags/mg.png';
import mf_flag from '../assets/country_flags/mf.png';
import me_flag from '../assets/country_flags/me.png';
import md_flag from '../assets/country_flags/md.png';
import mc_flag from '../assets/country_flags/mc.png';
import ma_flag from '../assets/country_flags/ma.png';
import ly_flag from '../assets/country_flags/ly.png';
import lu_flag from '../assets/country_flags/lu.png';
import lt_flag from '../assets/country_flags/lt.png';
import ls_flag from '../assets/country_flags/ls.png';
import lr_flag from '../assets/country_flags/lr.png';
import lk_flag from '../assets/country_flags/lk.png';
import li_flag from '../assets/country_flags/li.png';
import lc_flag from '../assets/country_flags/lc.png';
import lb_flag from '../assets/country_flags/lb.png';
import la_flag from '../assets/country_flags/la.png';
import kz_flag from '../assets/country_flags/kz.png';
import ky_flag from '../assets/country_flags/ky.png';
import kw_flag from '../assets/country_flags/kw.png';
import kr_flag from '../assets/country_flags/kr.png';
import kp_flag from '../assets/country_flags/kp.png';
import km_flag from '../assets/country_flags/km.png';
import ki_flag from '../assets/country_flags/ki.png';
import kh_flag from '../assets/country_flags/kh.png';
import ke_flag from '../assets/country_flags/ke.png';
import jp_flag from '../assets/country_flags/jp.png';
import jo_flag from '../assets/country_flags/jo.png';
import jm_flag from '../assets/country_flags/jm.png';
import je_flag from '../assets/country_flags/je.png';
import it_flag from '../assets/country_flags/it.png';
import is_flag from '../assets/country_flags/is.png';
import ir_flag from '../assets/country_flags/ir.png';
import iq_flag from '../assets/country_flags/iq.png';
import io_flag from '../assets/country_flags/io.png';
import in_flag from '../assets/country_flags/in.png';
import im_flag from '../assets/country_flags/im.png';
import il_flag from '../assets/country_flags/il.png';
import ie_flag from '../assets/country_flags/ie.png';
import id_flag from '../assets/country_flags/id.png';
import hu_flag from '../assets/country_flags/hu.png';
import ht_flag from '../assets/country_flags/ht.png';
import hr_flag from '../assets/country_flags/hr.png';
import hn_flag from '../assets/country_flags/hn.png';
import hm_flag from '../assets/country_flags/hm.png';
import hk_flag from '../assets/country_flags/hk.png';
import gw_flag from '../assets/country_flags/gw.png';
import gu_flag from '../assets/country_flags/gu.png';
import gt_flag from '../assets/country_flags/gt.png';
import gs_flag from '../assets/country_flags/gs.png';
import gr_flag from '../assets/country_flags/gr.png';
import gq_flag from '../assets/country_flags/gq.png';
import gn_flag from '../assets/country_flags/gn.png';
import gm_flag from '../assets/country_flags/gm.png';
import gl_flag from '../assets/country_flags/gl.png';
import gi_flag from '../assets/country_flags/gi.png';
import gh_flag from '../assets/country_flags/gh.png';
import gg_flag from '../assets/country_flags/gg.png';
import gf_flag from '../assets/country_flags/gf.png';
import ge_flag from '../assets/country_flags/ge.png';
import gd_flag from '../assets/country_flags/gd.png';
// import gb_ls from '../assets/country_flags/gb-wls.png';
// import gb_sct from '../assets/country_flags/gb-sct.png';
import gb_flag from '../assets/country_flags/gb.png';
// import gb_nir_flag from '../assets/country_flags/gb-nir.png';
// import gb_eng_flag from '../assets/country_flags/gb-eng.png';
import ga_flag from '../assets/country_flags/ga.png';
import fr_flag from '../assets/country_flags/fr.png';
import fo_flag from '../assets/country_flags/fo.png';
import fm_flag from '../assets/country_flags/fm.png';
import fk_flag from '../assets/country_flags/fk.png';
import fj_flag from '../assets/country_flags/fj.png';
import fi_flag from '../assets/country_flags/fi.png';
import et_flag from '../assets/country_flags/et.png';
import es_flag from '../assets/country_flags/es.png';
import er_flag from '../assets/country_flags/er.png';
import eh_flag from '../assets/country_flags/eh.png';
import eg_flag from '../assets/country_flags/eg.png';
import ee_flag from '../assets/country_flags/ee.png';
import ec_flag from '../assets/country_flags/ec.png';
import dz_flag from '../assets/country_flags/dz.png';
import do_flag from '../assets/country_flags/do.png';
import dm_flag from '../assets/country_flags/dm.png';
import dk_flag from '../assets/country_flags/dk.png';
import dj_flag from '../assets/country_flags/dj.png';
import de_flag from '../assets/country_flags/de.png';
import cz_flag from '../assets/country_flags/cz.png';
import cy_flag from '../assets/country_flags/cy.png';
import cw_flag from '../assets/country_flags/cw.png';
import cv_flag from '../assets/country_flags/cv.png';
import cu_flag from '../assets/country_flags/cu.png';
import cr_flag from '../assets/country_flags/cr.png';
import co_flag from '../assets/country_flags/co.png';
import cn_flag from '../assets/country_flags/cn.png';
import cm_flag from '../assets/country_flags/cm.png';
import cl_flag from '../assets/country_flags/cl.png';
import ck_flag from '../assets/country_flags/ck.png';
import ci_flag from '../assets/country_flags/ci.png';
import ch_flag from '../assets/country_flags/ch.png';
import cg_flag from '../assets/country_flags/cg.png';
import cf_flag from '../assets/country_flags/cf.png';
import cd_flag from '../assets/country_flags/cd.png';
import cc_flag from '../assets/country_flags/cc.png';
import ca_flag from '../assets/country_flags/ca.png';
import bz_flag from '../assets/country_flags/bz.png';
import by_flag from '../assets/country_flags/by.png';
import bw_flag from '../assets/country_flags/bw.png';
import bv_flag from '../assets/country_flags/bv.png';
import bt_flag from '../assets/country_flags/bt.png';
import bs_flag from '../assets/country_flags/bs.png';
import br_flag from '../assets/country_flags/br.png';
import bq_flag from '../assets/country_flags/bq.png';
import bn_flag from '../assets/country_flags/bn.png';
import bm_flag from '../assets/country_flags/bm.png';
import bl_flag from '../assets/country_flags/bl.png';
import bj_flag from '../assets/country_flags/bj.png';
import bi_flag from '../assets/country_flags/bi.png';
import bh_flag from '../assets/country_flags/bh.png';
import bg_flag from '../assets/country_flags/bg.png';
import be_flag from '../assets/country_flags/be.png';
import bd_flag from '../assets/country_flags/bd.png';
import bb_flag from '../assets/country_flags/bb.png';
import ba_flag from '../assets/country_flags/ba.png';
import az_flag from '../assets/country_flags/az.png';
import ax_flag from '../assets/country_flags/ax.png';
import aw_flag from '../assets/country_flags/aw.png';
import au_flag from '../assets/country_flags/au.png';
import at_flag from '../assets/country_flags/at.png';
import as_flag from '../assets/country_flags/as.png';
import ar_flag from '../assets/country_flags/ar.png';
import aq_flag from '../assets/country_flags/aq.png';
import ao_flag from '../assets/country_flags/ao.png';
import am_flag from '../assets/country_flags/am.png';
import ai_flag from '../assets/country_flags/ai.png';
import ag_flag from '../assets/country_flags/ag.png';
import ae_flag from '../assets/country_flags/ae.png';
import ad_flag from '../assets/country_flags/ad.png';
import va_flag from '../assets/country_flags/va.png';
import tf_flag from '../assets/country_flags/tf.png';
import sz_flag from '../assets/country_flags/sz.png';
import ss_flag from '../assets/country_flags/ss.png';
import sc_flag from '../assets/country_flags/sc.png';
import rs_flag from '../assets/country_flags/rs.png';
import qa_flag from '../assets/country_flags/qa.png';
import ps_flag from '../assets/country_flags/ps.png';
import nr_flag from '../assets/country_flags/nr.png';
import ms_flag from '../assets/country_flags/ms.png';
import lv_flag from '../assets/country_flags/lv.png';
import kn_flag from '../assets/country_flags/kn.png';
import kg_flag from '../assets/country_flags/kg.png';
import gy_flag from '../assets/country_flags/gy.png';
import gp_flag from '../assets/country_flags/gp.png';
import cx_flag from '../assets/country_flags/cx.png';
import bo_flag from '../assets/country_flags/bo.png';
import bf_flag from '../assets/country_flags/bf.png';
import al_flag from '../assets/country_flags/al.png';
import af_flag from '../assets/country_flags/af.png';
import ta_flag from '../assets/country_flags/ta.svg';
import ac_flag from '../assets/country_flags/ac.svg';

export default {
  AC: ac_flag,
  TA: ta_flag,
  ZW: zw_flag,
  ZM: zm_flag,
  ZA: za_flag,
  YT: yt_flag,
  YE: ye_flag,
  XK: xk_flag,
  WS: ws_flag,
  WF: wf_flag,
  VU: vu_flag,
  VN: vn_flag,
  VI: vi_flag,
  VG: vg_flag,
  VE: ve_flag,
  VC: vc_flag,
  UZ: uz_flag,
  UY: uy_flag,
  US: us_flag,
  UM: um_flag,
  UG: ug_flag,
  UA: ua_flag,
  TZ: tz_flag,
  TW: tw_flag,
  TV: tv_flag,
  TT: tt_flag,
  TR: tr_flag,
  TO: to_flag,
  TN: tn_flag,
  TM: tm_flag,
  TL: tl_flag,
  TK: tk_flag,
  TJ: tj_flag,
  TH: th_flag,
  TG: tg_flag,
  TD: td_flag,
  TC: tc_flag,
  SY: sy_flag,
  SX: sx_flag,
  SV: sv_flag,
  ST: st_flag,
  SR: sr_flag,
  SO: so_flag,
  SN: sn_flag,
  SM: sm_flag,
  SL: sl_flag,
  SK: sk_flag,
  SJ: sj_flag,
  SI: si_flag,
  SH: sh_flag,
  SG: sg_flag,
  SE: se_flag,
  SD: sd_flag,
  SB: sb_flag,
  SA: sa_flag,
  RW: rw_flag,
  RU: ru_flag,
  RO: ro_flag,
  RE: re_flag,
  PY: py_flag,
  PW: pw_flag,
  PT: pt_flag,
  PR: pr_flag,
  PN: pn_flag,
  PM: pm_flag,
  PL: pl_flag,
  PK: pk_flag,
  PH: ph_flag,
  PG: pg_flag,
  PF: pf_flag,
  PE: pe_flag,
  PA: pa_flag,
  OM: om_flag,
  NZ: nz_flag,
  NU: nu_flag,
  NP: np_flag,
  NO: no_flag,
  NL: nl_flag,
  NI: ni_flag,
  NG: ng_flag,
  NF: nf_flag,
  NE: ne_flag,
  NC: nc_flag,
  NA: na_flag,
  MZ: mz_flag,
  MY: my_flag,
  MX: mx_flag,
  MW: mw_flag,
  MV: mv_flag,
  MU: mu_flag,
  MT: mt_flag,
  MR: mr_flag,
  MQ: mq_flag,
  MP: mp_flag,
  MO: mo_flag,
  MN: mn_flag,
  MM: mm_flag,
  ML: ml_flag,
  MK: mk_flag,
  MH: mh_flag,
  MG: mg_flag,
  MF: mf_flag,
  ME: me_flag,
  MD: md_flag,
  MC: mc_flag,
  MA: ma_flag,
  LY: ly_flag,
  LU: lu_flag,
  LT: lt_flag,
  LS: ls_flag,
  LR: lr_flag,
  LK: lk_flag,
  LI: li_flag,
  LC: lc_flag,
  LB: lb_flag,
  LA: la_flag,
  KZ: kz_flag,
  KY: ky_flag,
  KW: kw_flag,
  KR: kr_flag,
  KP: kp_flag,
  KM: km_flag,
  KI: ki_flag,
  KH: kh_flag,
  KE: ke_flag,
  JP: jp_flag,
  JO: jo_flag,
  JM: jm_flag,
  JE: je_flag,
  IT: it_flag,
  IS: is_flag,
  IR: ir_flag,
  IQ: iq_flag,
  IO: io_flag,
  IN: in_flag,
  IM: im_flag,
  IL: il_flag,
  IE: ie_flag,
  ID: id_flag,
  HU: hu_flag,
  HT: ht_flag,
  HR: hr_flag,
  HN: hn_flag,
  HM: hm_flag,
  HK: hk_flag,
  GW: gw_flag,
  GU: gu_flag,
  GT: gt_flag,
  GS: gs_flag,
  GR: gr_flag,
  GQ: gq_flag,
  GN: gn_flag,
  GM: gm_flag,
  GL: gl_flag,
  GI: gi_flag,
  GH: gh_flag,
  GG: gg_flag,
  GF: gf_flag,
  GE: ge_flag,
  GD: gd_flag,
  GB: gb_flag,
  IR: ir_flag,
  NG: ng_flag,
  GA: ga_flag,
  FR: fr_flag,
  FO: fo_flag,
  FM: fm_flag,
  FK: fk_flag,
  FJ: fj_flag,
  FI: fi_flag,
  ET: et_flag,
  ES: es_flag,
  ER: er_flag,
  EH: eh_flag,
  EG: eg_flag,
  EE: ee_flag,
  EC: ec_flag,
  DZ: dz_flag,
  DO: do_flag,
  DM: dm_flag,
  DK: dk_flag,
  DJ: dj_flag,
  DE: de_flag,
  CZ: cz_flag,
  CY: cy_flag,
  CW: cw_flag,
  CV: cv_flag,
  CU: cu_flag,
  CR: cr_flag,
  CO: co_flag,
  CN: cn_flag,
  CM: cm_flag,
  CL: cl_flag,
  CK: ck_flag,
  CI: ci_flag,
  CH: ch_flag,
  CG: cg_flag,
  CF: cf_flag,
  CD: cd_flag,
  CC: cc_flag,
  CA: ca_flag,
  BZ: bz_flag,
  BY: by_flag,
  BW: bw_flag,
  BV: bv_flag,
  BT: bt_flag,
  BS: bs_flag,
  BR: br_flag,
  BQ: bq_flag,
  BN: bn_flag,
  BM: bm_flag,
  BL: bl_flag,
  BJ: bj_flag,
  BI: bi_flag,
  BH: bh_flag,
  BG: bg_flag,
  BE: be_flag,
  BD: bd_flag,
  BB: bb_flag,
  BA: ba_flag,
  AZ: az_flag,
  AX: ax_flag,
  AW: aw_flag,
  AU: au_flag,
  AT: at_flag,
  AS: as_flag,
  AR: ar_flag,
  AQ: aq_flag,
  AO: ao_flag,
  AM: am_flag,
  AI: ai_flag,
  AG: ag_flag,
  AE: ae_flag,
  AD: ad_flag,
  VA: va_flag,
  TF: tf_flag,
  SZ: sz_flag,
  SS: ss_flag,
  SC: sc_flag,
  RS: rs_flag,
  QA: qa_flag,
  PS: ps_flag,
  NR: nr_flag,
  MS: ms_flag,
  LV: lv_flag,
  KN: kn_flag,
  KG: kg_flag,
  GY: gy_flag,
  GP: gp_flag,
  CX: cx_flag,
  BO: bo_flag,
  BF: bf_flag,
  AL: al_flag,
  AF: af_flag,
}

const CountryCode =
	'AC' |
	'AD' |
	'AE' |
	'AF' |
	'AG' |
	'AI' |
	'AL' |
	'AM' |
	'AO' |
	'AR' |
	'AS' |
	'AT' |
	'AU' |
	'AW' |
	'AX' |
	'AZ' |
	'BA' |
	'BB' |
	'BD' |
	'BE' |
	'BF' |
	'BG' |
	'BH' |
	'BI' |
	'BJ' |
	'BL' |
	'BM' |
	'BN' |
	'BO' |
	'BQ' |
	'BR' |
	'BS' |
	'BT' |
	'BW' |
	'BY' |
	'BZ' |
	'CA' |
	'CC' |
	'CD' |
	'CF' |
	'CG' |
	'CH' |
	'CI' |
	'CK' |
	'CL' |
	'CM' |
	'CN' |
	'CO' |
	'CR' |
	'CU' |
	'CV' |
	'CW' |
	'CX' |
	'CY' |
	'CZ' |
	'DE' |
	'DJ' |
	'DK' |
	'DM' |
	'DO' |
	'DZ' |
	'EC' |
	'EE' |
	'EG' |
	'EH' |
	'ER' |
	'ES' |
	'ET' |
	'FI' |
	'FJ' |
	'FK' |
	'FM' |
	'FO' |
	'FR' |
	'GA' |
	'GB' |
	'GD' |
	'GE' |
	'GF' |
	'GG' |
	'GH' |
	'GI' |
	'GL' |
	'GM' |
	'GN' |
	'GP' |
	'GQ' |
	'GR' |
	'GT' |
	'GU' |
	'GW' |
	'GY' |
	'HK' |
	'HN' |
	'HR' |
	'HT' |
	'HU' |
	'ID' |
	'IE' |
	'IL' |
	'IM' |
	'IN' |
	'IO' |
	'IQ' |
	'IR' |
	'IS' |
	'IT' |
	'JE' |
	'JM' |
	'JO' |
	'JP' |
	'KE' |
	'KG' |
	'KH' |
	'KI' |
	'KM' |
	'KN' |
	'KP' |
	'KR' |
	'KW' |
	'KY' |
	'KZ' |
	'LA' |
	'LB' |
	'LC' |
	'LI' |
	'LK' |
	'LR' |
	'LS' |
	'LT' |
	'LU' |
	'LV' |
	'LY' |
	'MA' |
	'MC' |
	'MD' |
	'ME' |
	'MF' |
	'MG' |
	'MH' |
	'MK' |
	'ML' |
	'MM' |
	'MN' |
	'MO' |
	'MP' |
	'MQ' |
	'MR' |
	'MS' |
	'MT' |
	'MU' |
	'MV' |
	'MW' |
	'MX' |
	'MY' |
	'MZ' |
	'NA' |
	'NC' |
	'NE' |
	'NF' |
	'NG' |
	'NI' |
	'NL' |
	'NO' |
	'NP' |
	'NR' |
	'NU' |
	'NZ' |
	'OM' |
	'PA' |
	'PE' |
	'PF' |
	'PG' |
	'PH' |
	'PK' |
	'PL' |
	'PM' |
	'PR' |
	'PS' |
	'PT' |
	'PW' |
	'PY' |
	'QA' |
	'RE' |
	'RO' |
	'RS' |
	'RU' |
	'RW' |
	'SA' |
	'SB' |
	'SC' |
	'SD' |
	'SE' |
	'SG' |
	'SH' |
	'SI' |
	'SJ' |
	'SK' |
	'SL' |
	'SM' |
	'SN' |
	'SO' |
	'SR' |
	'SS' |
	'ST' |
	'SV' |
	'SX' |
	'SY' |
	'SZ' |
	'TA' |
	'TC' |
	'TD' |
	'TG' |
	'TH' |
	'TJ' |
	'TK' |
	'TL' |
	'TM' |
	'TN' |
	'TO' |
	'TR' |
	'TT' |
	'TV' |
	'TW' |
	'TZ' |
	'UA' |
	'UG' |
	'US' |
	'UY' |
	'UZ' |
	'VA' |
	'VC' |
	'VE' |
	'VG' |
	'VI' |
	'VN' |
	'VU' |
	'WF' |
	'WS' |
	'XK' |
	'YE' |
	'YT' |
	'ZA' |
	'ZM' |
	'ZW';
