import WIDGETS from './widgets';

export default {
  name: 'mood_logs',
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKMOOD, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.LOGSMOOD, breakpoints: { xs: 12, sm: 12 } },
	],
};
