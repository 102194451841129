import { Backdrop, Button, Card, CardActionArea, CardContent, CardMedia, Typography, CircularProgress, CardActions, alpha } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

export const BackdropUI = ({translate, ...props}) => {
	return (
		<Backdrop
			onClick={() => {
				if (caches) {
					caches
						.keys()
						.then(async function (names) {
							await Promise.all(names.map((name) => caches.delete(name)));
						})
						.then(() => {
							return window.location.reload(); // refresh the page
						});
				} else {
					return window.location.reload();
				}
			}}
			sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.85) })}
			{...props.backdrop}
		>
			<Card variant="elevation" elevation={4} sx={{ maxWidth: 345 }}>
				<CardActionArea
					onClick={() => {
						if (caches) {
							caches
								.keys()
								.then(async function (names) {
									await Promise.all(names.map((name) => caches.delete(name)));
								})
								.then(() => {
									return window.location.reload(); // refresh the page
								});
						} else {
							return window.location.reload();
						}
					}}
				>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div">
							{translate('new_version_backdrop_modal_title')}
              {/* New version */}
						</Typography>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {translate('new_version_backdrop_modal_description')}
							{/* A new version has been released, click to refresh and get new changes. */}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		</Backdrop>
	);
};

BackdropUI.propTypes = {
	backdrop: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  translate: getTranslate(state.localize),
	backdrop: state.generic.backdrop,
	config: state.config,
	client: state.login.client ? state.settings.clients.find((client) => client.id === state.login.client.id) : null /**TODO:  */,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BackdropUI);
