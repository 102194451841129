import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Grid } from '@mui/material';

class Presentation extends Component {
	render() {
		const { translate } = this.props;

		return (
			<Grid container justifyContent="space-around" alignContent="center" alignItems="center">
				<Grid item xs={12} md={6} className="welcome-presentation-title">
					<Typography color={'inherit'} paragraph={true} component="div" variant="h2">
						{translate('welcome_presentation_title')}
					</Typography>
					<Typography color={'inherit'} paragraph={true} component="div" variant="h5">
						{translate('welcome_presentation_subtitle')}
					</Typography>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps)(Presentation);
