import {
	FETCH_USERS_EVENTS,
	ADD_USER_EVENTS,
	POST_USERS_EVENTS,
	POST_USERS_AVAILABLE_HOURS,
	PATCH_USERS_EVENTS,
	PATCH_CANCELLED_EVENT,
	GET_USER_EVENT,
	FETCH_MEETING_INTERNAL_ATTENDEES,
	REMOVE_MEETING_INTERNAL_ATTENDEE,
	FETCH_MEETING_EXTERNAL_ATTENDEES,
	REMOVE_MEETING_EXTERNAL_ATTENDEE,
	SET_MEETING_EVENT_INVITEES,
	GET_USER_SCHEDULE,
  UPDATE_FREETIMES
} from '../actions/types';
import { CLEAR_REDUCER } from '../../Login/actions/types';

const initialState = {
	users: {
		data: [],
		selected: [],
	},
	events: [],
	available: [],
	appointments: [],
	appointments_columns: [],
	periodStart: '',
	periodEnd: '',
	dayStart: '',
	dayEnd: '',
	event: {
		start: '',
		end: '',
		resourceId: '',
	},
	schedules: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_USERS_EVENTS:
			return {
				...state,
				// users: {
				//     data: action.payload.users,
				//     selected: action.payload.users
				// },
				events: action.payload.events,
				appointments: action.payload.appointments,
				appointments_columns: action.payload.appointments_columns,
				available: action.payload.available,
				periodStart: action.payload.periodStart,
				periodEnd: action.payload.periodEnd,
				dayStart: action.payload.dayStart,
				dayEnd: action.payload.dayEnd,
			};
		case ADD_USER_EVENTS:
			return {
				...state,
				users: {
					data: action.payload.data,
					selected: action.payload.selected,
				},
			};
		case POST_USERS_EVENTS:
			return {
				...state,
				...action.payload.calendar,
				users: {
					data: action.payload.calendar.users,
					selected: action.payload.selectedUsers,
				},
			};
		case PATCH_USERS_EVENTS:
			return {
				...state,
				...action.payload.calendar,
				users: {
					data: action.payload.calendar.users,
					selected: action.payload.selectedUsers,
				},
			};
		case PATCH_CANCELLED_EVENT:
			const updatedEvents = state.events.filter((event) => event.id !== action.payload.event.id);

			return {
				...state,
				events: [...updatedEvents, action.payload.event],
			};
		case POST_USERS_AVAILABLE_HOURS:
			return {
				...state,
				...action.payload.calendar,
				users: {
					data: action.payload.calendar.users,
					selected: action.payload.selectedUsers,
				},
			};
		case GET_USER_EVENT:
			return {
				...state,
				event: action.payload,
			};
		case FETCH_MEETING_INTERNAL_ATTENDEES:
			return {
				...state,
				event: {
					...state.event,
					invitees: [...state.event.invitees, action.payload],
				},
			};
		case SET_MEETING_EVENT_INVITEES:
			return {
				...state,
				event: {
					...state.event,
					invitees: action.payload.invitees,
					external_invitees: action.payload.external_invitees,
				},
			};
		case REMOVE_MEETING_INTERNAL_ATTENDEE:
			let intenal_invitees = state.event.invitees.filter((invitee) => invitee.id !== action.payload.id);

			return {
				...state,
				event: {
					...state.event,
					invitees: intenal_invitees,
				},
			};
		case FETCH_MEETING_EXTERNAL_ATTENDEES:
			return {
				...state,
				event: {
					...state.event,
					external_invitees: [...state.event.external_invitees, action.payload],
				},
			};
		case REMOVE_MEETING_EXTERNAL_ATTENDEE:
			let external_invitees = state.event.external_invitees.filter((external_invitee) => external_invitee.id !== action.payload.id);

			return {
				...state,
				event: {
					...state.event,
					external_invitees: external_invitees,
				},
			};
		case CLEAR_REDUCER:
			return {
				...initialState,
			};
		case GET_USER_SCHEDULE:
			return {
				...state,
				schedules: action.payload,
			};
    case UPDATE_FREETIMES:
      return {
        ...state,
        available: action.payload.available
      };
		default:
			return state;
	}
}
