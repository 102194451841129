import React, { Component } from 'react';
import eventsService from '../../helpers/Events';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import {
	CheckCircle as CheckCircleIcon,
	Error as ErrorIcon,
	Info as InfoIcon,
	Close as CloseIcon,
	Warning as WarningIcon,
} from '@mui/icons-material';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

function MySnackbarContentWrapper(props) {
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	var cssClass = '';

	switch (variant) {
		case 'success':
			cssClass = 'success-alteri-color';
			break;
		case 'info':
			cssClass = 'info-alteri-color';
			break;
		case 'warning':
			cssClass = 'warning-alteri-color';
			break;
		case 'error':
			cssClass = 'danger-alteri-color';
			break;
		default:
			break;
	}

	return (
		<SnackbarContent
			className={clsx(cssClass, className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className="generic-messagetoast-message">
					<Icon className="generic-messagetoast-icon generic-messagetoast-icon-variant" />
					{message}
				</span>
			}
			action={
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose} size="large">
					<CloseIcon className="generic-messagetoast-icon" />
				</IconButton>
			}
			{...other}
		/>
	);
}

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

class MessageToastLogin extends Component {
	state = {
		open: false,
		type: 'success',
		message: '',
	};

	componentDidMount() {
		eventsService.listenEvent('alert_login', this.handleClick.bind(this));
	}

	componentWillUnmount() {
		eventsService.unlistenEvent('alert_login');
	}

	handleClick = (params) => {
		this.setState({
			open: true,
			type: params.type,
			message: params.message,
		});
	};

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			open: false,
		});
	};

	render() {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={this.state.open}
				autoHideDuration={3000}
				onClose={this.handleClose}
			>
				<div>
					<MySnackbarContentWrapper onClose={this.handleClose} variant={this.state.type} message={this.state.message} />
				</div>
			</Snackbar>
		);
	}
}

export default MessageToastLogin;
