import React, { useState, useEffect } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../Generic/Form/MainReinitialize';
import { TYPES as FORM_TYPES } from '../../Generic/FormTypes';
import { reduxForm } from 'redux-form';
import { Typography, Grid } from '@mui/material';

export const Insurance = ({ registration_fields, login, onSubmitSuccess, ...props }) => {
	const handleConfig = () => {
		const { translate } = props;

		const structure = {
			form: 'create_new_insurance',
			id: 'create_new_insurance',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'xl',
			fields_grid_layout: {
				xs: 12,
			},

			columns: [
				{
					id: 'column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'insurance_company',
									name: 'insurance_company',
									label: translate('users_register_form_myprofile_field_insurance_company'),
									type:
										registration_fields.find((elem) => elem.field == 'insurance') &&
										registration_fields.find((elem) => elem.field == 'insurance').display
											? FORM_TYPES.INPUT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'insurance') &&
											registration_fields.find((elem) => elem.field == 'insurance').required,
									},
									grid_layout: { xs: 12, md: 4 },
								},
								{
									id: 'insurance_number',
									name: 'insurance_number',
									label: translate('users_register_form_myprofile_field_insurance_number'),
									type:
										registration_fields.find((elem) => elem.field == 'insurance') &&
										registration_fields.find((elem) => elem.field == 'insurance').display
											? FORM_TYPES.NUMBER
											: FORM_TYPES.NONE,
									disableNumberArrows: true,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'insurance') &&
											registration_fields.find((elem) => elem.field == 'insurance').required,
									},
									grid_layout: { xs: 12, md: 4 },
								},
								{
									id: 'insurance_valid_date',
									name: 'insurance_valid_date',
									label: translate('users_register_form_myprofile_field_insurance_valid_date'),
									type:
										registration_fields.find((elem) => elem.field == 'insurance') &&
										registration_fields.find((elem) => elem.field == 'insurance').display
											? FORM_TYPES.DATEPICKER
											: FORM_TYPES.NONE,
									disablePast: true,
									openTo: 'year',
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'insurance') &&
											registration_fields.find((elem) => elem.field == 'insurance').required,
									},
									grid_layout: { xs: 12, md: 4 },
								},
							],
						},
					],
				},
			],
		};

		return structure;
	};

	const configuration = handleConfig();

	return (
		<Grid container>
			<Typography sx={{ mt: 2 }}>{props.translate('insurance_info')}</Typography>
			<Main form={configuration.form} formData={configuration} />
		</Grid>
	);
};

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	registration_fields: state.settings.users.registration_fields,
	login: state.login,
});

const ConnectedInsurance = reduxForm({
	form: 'create_new_insurance',
  destroyOnUnmount: false,
	onSubmit: (values, dispatch, props) => {
		if (!props.valid) {
			props.onSubmitSuccess();
		}
	},
})(Insurance);

export default connect(mapStateToProps)(ConnectedInsurance);
