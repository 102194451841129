import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import { getTranslate } from 'react-localize-redux';

// import { Viewer } from '@toast-ui/react-editor';

import { useStyles } from '../../assets/classes/htmlrender';

import { Loading } from '../Generic';
import { lazyRetry } from '../../helpers/functions/lazy_loading';

/* 
    props:
        textToParse -
    Example component usage
    Works with translation tag
        <HtmlRender textToParse='ticket_overview_title'/> 
    
    or with plaintext
        <HtmlRender textToParse={question.text} /> 
*/

function HtmlRender(props) {
	useStyles(props);

	// const Viewer = React.lazy(() => {import { Viewer } from '@toast-ui/react-editor'});

	const Viewer = React.lazy(() =>
		lazyRetry(() => import('@toast-ui/react-editor').then((module) => ({ default: module.Viewer })), 'html-reader-viewer')
	);

	const { translate } = props;

	return (
		<Suspense fallback={<Loading />}>
			<Viewer initialValue={translate(props.textToParse, null, { renderInnerHtml: false })} />
		</Suspense>
	);
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, null)(HtmlRender);
