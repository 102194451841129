import React from 'react';
import { TextField, FormHelperText, MenuItem, Checkbox, ListItemText, ListSubheader } from '@mui/material';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

function RenderSelectGroupMultiple({ input, meta: { invalid, touched, error, active }, ...custom }) {
	let value = input.value.length === 0 ? [] : input.value;
	const translate = useSelector((state) => getTranslate(state.localize));

	let childs = [];

	custom.inputdata.options.forEach((parent) => {
		childs = [...childs, ...parent.options];
	});

	const renderChildOptions = (options) => {
		const items = [];

    if (custom.inputdata.select_all) {
      items.push(
        <MenuItem key={'all'} value={'all'}>
          <Checkbox checked={value.length === childs.length} />
          <ListItemText primary={translate('generic_form_select_all_option')} />
        </MenuItem>
      );
    }

		options.forEach((group, key) => {
			items.push(
				<ListSubheader disableSticky={true} key={key}>
					{group.parent}
				</ListSubheader>
			);
			group.options.forEach((child) => {
				items.push(
					<MenuItem key={child.value} value={child.value}>
						<Checkbox checked={value.includes(child.value)} />
						<ListItemText primary={child.label} />
					</MenuItem>
				);
			});
		});
		return items;
	};

	return (
		<div>
			<TextField
				{...input}
				value={value}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				label={custom.inputdata.label}
				placeholder={custom.inputdata.placeholder}
				fullWidth
				variant="outlined"
				disabled={custom.inputdata.readonly}
				error={touched && invalid && !active}
				helperText={touched && !active && error}
				SelectProps={{
					onChange: (event, child) => {
						if (child.props.value === 'all') {
							input.onChange(value.length === childs.length ? [] : childs.map((option) => option.value));
							return;
						} else {
							if (typeof custom.inputdata.onChange !== 'undefined') {
								custom.inputdata.onChange(event.target.value);
							}

							return input.onChange(event.target.value);
						}
					},
					multiple: true,
					renderValue: (selected) => {
						return childs
							? selected
									.map((value) => {
										let option = childs.find((option) => option.value == value);
										return option?.label;
									})
									.join(', ')
							: '';
					},
				}}
				type={custom.inputdata.type}
				size={custom.inputdata.size ? custom.inputdata.size : null}
				{...custom}
				inputProps={{
					step: custom.inputdata.props ? custom.inputdata.props.step : null,
					min: custom.inputdata.props ? custom.inputdata.props.min : null,
					className: custom.inputdata.disableNumberArrows ? 'number-input' : null,
				}}
			>
				{renderChildOptions(custom.inputdata.options)}
			</TextField>
			<FormHelperText id="my-helper-text"> {custom.inputdata.helperText}</FormHelperText>
		</div>
		// required={(custom.inputdata.validate && custom.inputdata.validate.required) ? true : false}
	);
}

export default RenderSelectGroupMultiple;
