import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Field, reduxForm, getFormSyncErrors, untouch, reset, updateSyncErrors, initialize } from 'redux-form';
import withTheme from '@mui/styles/withTheme';
import { Container, Button, Grid, Typography, MenuItem, ListSubheader, IconButton } from '@mui/material';

import {
	RenderTextField,
	RenderRadioGroup,
	RenderDatePicker,
	RenderTimePicker,
	RenderDateTimePicker,
	RenderSlider,
	RenderImageUpload,
	RenderAutocomplete,
	RenderMultiAutocomplete,
	RenderLink,
	RenderCheckboxGroup,
	RenderSingleCheckbox,
	RenderInputAdornment,
	RenderWysiwyg,
	RenderTreeSelect,
	RenderTextFieldMultiple,
  RenderSelectGroupMultiple,
	TYPES,
	RenderCustomRadioGroup,
	RenderCustomCheckboxGroup,
	RenderIncrementor,
	RenderPhoneField,
} from '../FormTypes';
import { validate } from './SyncValidation';
import { Column, Separator, FormField } from './index';

// TODO: Add possibility to add defaultValues and Placeholders
// also change number of rows in textareas for instance
// add helpertext in each inputs and also in FORM (general)

const renderChildOptions = (options) => {
	const items = [];

	options.forEach((group, key) => {
		items.push(
			<ListSubheader disableSticky={true} key={key}>
				{group.parent}
			</ListSubheader>
		);
		group.options.forEach((child) => {
			items.push(
				<MenuItem key={child.value} value={child.value} className="generic-form-content-form-field-select-group-child">
					{child.label}
				</MenuItem>
			);
		});
	});
	return items;
};

class Main extends Component {
	constructor(props) {
		super(props);
		// create a ref to store the refInput DOM element
		this.refInput = React.createRef();
		this.focusRefInput = this.focusRefInput.bind(this);

		this.state = {
			clicked_button: false,
		};
	}

	resetFields = () => {
		if (this.imageRef) {
			this.imageRef.handleClearState();
		}
	};

	focusRefInput() {
		// Explicitly focus the text input using the raw DOM API
		// Note: we're accessing "current" to get the DOM node
		this.refInput.current.focus();
	}

	render() {
		const videoResolutionsAllowed = (width, height) => (field, allowed_resolutions) => {
			let aspect_ok = false;

			allowed_resolutions.forEach((elem) => {
				if (width === elem[0] && height === elem[1]) {
					aspect_ok = true;
				}
			});

			if (!aspect_ok) {
				this.props.invalidateField(this.props.formData.form, {
					[field]: this.props.translate('video_upload_error_resolution'),
				});
			}
		};

		const handleInputType = (field, i) => {
			switch (field.type) {
				case TYPES.INPUT:
				case TYPES.PASSWORD:
				case TYPES.NUMBER:
				case TYPES.TIME:
					return <Field ref={this.refInput} name={field.name} component={RenderTextField} inputdata={field} />;
				case TYPES.PHONE:
					return <Field ref={this.refInput} name={field.name} component={RenderPhoneField} inputdata={field} />;
				case TYPES.INCREMENTOR:
					return <Field ref={this.refInput} name={field.name} type={'number'} component={RenderIncrementor} inputdata={field} />;
				case TYPES.HIDDEN:
					return (
						<div key={i}>
							<Field
								ref={this.refInput}
								name={field.name}
								component={RenderTextField}
								inputdata={field}
								type="hidden"
								style={{ display: 'none' }}
							/>
						</div>
					);
				case TYPES.RADIOBUTTON:
					return <Field name={field.name} component={RenderRadioGroup} inputdata={field} ref={this.refInput} />;
				case TYPES.RADIOBUTTON_CUSTOM:
					return <Field name={field.name} component={RenderCustomRadioGroup} inputdata={field} ref={this.refInput} />;
				case TYPES.CHECKBOX:
					return (
						<RenderCheckboxGroup
							name={field.name}
							inputdata={field}
							options={field.options}
							onChangeValue={field.onChange}
							ref={this.refInput}
						/>
					);
				case TYPES.CHECKBOX_CUSTOM:
					return (
						<RenderCustomCheckboxGroup
							name={field.name}
							inputdata={field}
							options={field.options}
							onChangeValue={field.onChange}
							ref={this.refInput}
						/>
					);
				case TYPES.SINGLE_CHECKBOX:
					return <Field ref={this.refInput} name={field.name} inputdata={field} component={RenderSingleCheckbox} />;
				case TYPES.SELECT:
					return (
						<Field ref={this.refInput} name={field.name} component={RenderTextField} inputdata={field} select>
							{typeof field.empty_option === 'undefined' ||
								(field.empty_option && (
									<MenuItem value="">
										<em>-</em>
									</MenuItem>
								))}
							{field.options.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Field>
					);
				case TYPES.SELECT_MULTIPLE_CHECKBOX:
					return <Field ref={this.refInput} name={field.name} component={RenderTextFieldMultiple} inputdata={field} select />;
				case TYPES.SELECT_GROUP:
					return (
						<Field ref={this.refInput} name={field.name} component={RenderTextField} inputdata={field} select>
							<MenuItem value="">
								<em>-</em>
							</MenuItem>
							{renderChildOptions(field.options)}
						</Field>
					);
				case TYPES.SELECT_MULTIPLE_GROUP:
					return (
						<Field ref={this.refInput} name={field.name} component={RenderSelectGroupMultiple} inputdata={field} select />
					);
				case TYPES.SELECT_TREE:
					return <Field ref={this.refInput} name={field.name} component={RenderTreeSelect} inputdata={field} select />;
				/**TODO: add validation and server side validation */
				case TYPES.AUTOCOMPLETE_TEXTFIELD:
					return <Field ref={this.refInput} name={field.name} component={RenderAutocomplete} inputdata={field} />;
				/**TODO: add validation and server side validation */
				case TYPES.MULTI_AUTOCOMPLETE_TEXTFIELD:
					return <Field ref={this.refInput} name={field.name} component={RenderMultiAutocomplete} inputdata={field} />;
				case TYPES.TEXTAREA:
					return <Field ref={this.refInput} name={field.name} component={RenderTextField} inputdata={field} multiline={true} minRows={4} />;
				/**TODO: add validation and server side validation */
				case TYPES.DATEPICKER:
					return (
						<Field ref={this.refInput} name={field.name} component={RenderDatePicker} inputdata={field} locale={this.props.language} />
					);
				/**TODO: add validation and server side validation */
				case TYPES.TIMEPICKER:
					return (
						<Field ref={this.refInput} name={field.name} component={RenderTimePicker} inputdata={field} locale={this.props.language} />
					);
				/**TODO: add validation and server side validation */
				case TYPES.DATETIMEPICKER:
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderDateTimePicker}
							inputdata={field}
							locale={this.props.language}
							timezone={this.props.timezone}
						/>
					);
				/**TODO: add validation and server side validation */
				case TYPES.SLIDER:
					return (
						<Field
							ref={this.refInput}
							name={field.name}
							component={RenderSlider}
							inputdata={field}
							requiredLabel={this.props.translate('form_field_required')}
						/>
					);
				/**TODO: add validation and server side validation */
				case TYPES.DROPZONE:
					return (
						<Field
							name={field.name}
							component={RenderImageUpload}
							inputdata={field}
							type="file"
							onRef={(ref) => (this.imageRef = ref)}
							props={
								typeof field.validate.videoResolutionsAllowed !== 'undefined'
									? {
											videoResolutionsAllowed: (width, height) => {
												videoResolutionsAllowed(width, height)(field.name, field.validate.videoResolutionsAllowed);
											},
									  }
									: {}
							}
						/>
					);
				/**TODO: add validation and server side validation */
				case TYPES.WYSIWYG:
					return <Field name={field.name} component={RenderWysiwyg} inputdata={field} />;
				/**TODO: add validation and server side validation */
				case TYPES.LINK:
					return <Field ref={this.refInput} name={field.name} component={RenderLink} inputdata={field} />;
				/**TODO: add validation and server side validation */
				case TYPES.INPUT_ADORNMENT:
					return <Field ref={this.refInput} name={field.name} type={field.inputType} component={RenderInputAdornment} inputdata={field} />;
				case TYPES.EXTERNAL:
					return field.content;
				default:
					return null;
			}
		};

		return this.props.formData ? (
			<Container
				component="main"
				maxWidth={this.props.formData.maxWidth ? this.props.formData.maxWidth : 'sm'}
				disableGutters={this.props.disableGutters}
				className="generic-form-main"
			>
				<Typography variant="h5" align="center" gutterBottom>
					{this.props.formData.title}
				</Typography>
				<form
					onKeyDown={(event) => {
						if (this.props.formData.disable_submit_on_enter && event.key == 'Enter') {
							event.preventDefault();
							return false;
						}
					}}
					{...(this.props.formData.id && { id: this.props.formData.id })}
					autoComplete="off"
					/* TODO: lock/unlock submit button on submit with apiRequest function */
					onSubmit={(event) => {
						event.preventDefault();

						let clickedButton = this.props.formData.buttons.find((button) => button.id === this.state.clicked_button);

						let newFormErrors = { ...this.props.formErrors };

						if (
							Object.keys(newFormErrors).length > 0 &&
							typeof clickedButton.exclude_fields_validation !== 'undefined' &&
							clickedButton.exclude_fields_validation.length > 0
						) {
							clickedButton.exclude_fields_validation.forEach((exclude_field_validation) => {
								delete newFormErrors[exclude_field_validation];
								this.props.untouch(this.props.formData.id, exclude_field_validation);
							});
						}

						if (!this.props.invalid || Object.keys(newFormErrors).length === 0) {
							// this.props.handleFormSubmittingAction(true, this.props.form);

							if (clickedButton.action) {
								clickedButton.action();
							}

							/* TODO: Create a helper function with a callback to enable submit button when 200 response and field validations ann prevent form reset on request error*/
							setTimeout(() => {
								this.resetFields();
							}, 4000);
						} else {
							this.props.touch(...Object.keys(newFormErrors));
							document.getElementById(Object.keys(newFormErrors)[0]).scrollIntoView({ behavior: 'smooth' });
						}
					}}
					className="generic-form-content-form"
				>
					<Grid container spacing={2}>
						<Grid
							container
							item
							spacing={2}
							xs={this.props.formData.fields_grid_layout ? this.props.formData.fields_grid_layout.xs : 12}
							sm={this.props.formData.fields_grid_layout ? this.props.formData.fields_grid_layout.sm : 12}
							md={this.props.formData.fields_grid_layout ? this.props.formData.fields_grid_layout.md : 12}
							{...(this.props.formData.fields_grid_layout && this.props.formData.fields_grid_layout.content_align
								? { alignItems: this.props.formData.fields_grid_layout.content_align }
								: {})}
						>
							{this.props.formData.columns &&
								this.props.formData.columns.map((column, i) => (
									<Column key={'form_column_' + i} form={this.props.formData.form} i={i} {...column}>
										{column.separators.map((separator, i) => (
											<Separator key={'form_separator_' + i} form={this.props.formData.form} i={i} {...separator}>
												{separator.fields.map((field, i) => (
													<FormField key={'form_field_' + i} form={this.props.formData.form} i={i} {...field}>
														{handleInputType(field, i)}
													</FormField>
												))}
											</Separator>
										))}
									</Column>
								))}

							{this.props.formData.helperText &&
								this.props.formData.helperText.map((helper, i) => (
									<Grid key={i} item xs={12}>
										<Typography variant="body2" gutterBottom>
											{helper.text}
										</Typography>
									</Grid>
								))}
						</Grid>
						{!this.props.hideButton && this.props.formData.buttons && this.props.formData.buttons.length > 0 && (
							<Grid
								container
								item
								spacing={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.spacing : 2}
								xs={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.xs : 12}
								sm={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.sm : 12}
								md={this.props.formData.buttons_grid_layout ? this.props.formData.buttons_grid_layout.md : 12}
								{...(this.props.formData.buttons_grid_layout && this.props.formData.buttons_grid_layout.content_align
									? { alignItems: this.props.formData.buttons_grid_layout.content_align }
									: {})}
								{...(this.props.formData.buttons_grid_layout && this.props.formData.buttons_grid_layout.justify_content
									? { justifyContent: this.props.formData.buttons_grid_layout.justify_content }
									: {})}
							>
								{this.props.formData.buttons.map((button, i) => {
									let ButtonComponent = Button;

									if (button.type == 'iconButton') {
										ButtonComponent = IconButton;
									}

									return (
										!button.hidden && (
											<Grid
												item
												xs={button.grid_layout ? button.grid_layout.xs : 12}
												md={button.grid_layout ? button.grid_layout.md : 12}
												key={i}
												{...(button.grid_sx_props ? { sx: button.grid_sx_props } : {})}
												{...(button.grid_layout && button.grid_layout.content_align
													? { style: { textAlign: button.grid_layout.content_align } }
													: {})}
											>
												<ButtonComponent
													id={button.id}
													name={button.id}
													type={button.submit || typeof button.submit == 'undefined' ? 'submit' : 'button'}
													disabled={this.props.actionDisabled || this.props.disable_button || button.disabled}
													onClick={() => {
														this.setState(
															{
																clicked_button: button.id,
															},
															() => {
																if (typeof button.submit !== 'undefined' && !button.submit) {
																	button.action();
																}
															}
														);
													}}
													{...(typeof button.fullWidth !== 'undefined'
														? { fullWidth: button.fullWidth }
														: { fullWidth: true })}
													{...(button.variant ? { variant: button.variant } : { variant: 'contained' })}
													{...(button.size ? { size: button.size } : {})}
													{...(button.color ? { color: button.color } : { color: 'primary' })}
													{...(button.startIcon ? { startIcon: button.startIcon } : {})}
													{...(button.endIcon ? { endIcon: button.endIcon } : {})}
													{...(button.disabled ? { disabled: button.disabled } : {})}
													{...(button.customClass ? { className: button.customClass } : {})}
												>
													{typeof button.type !== 'undefined' && button.type == 'iconButton'
														? button.icon
														: this.props.translate(button.label)}
												</ButtonComponent>
											</Grid>
										)
									);
								})}
							</Grid>
						)}
					</Grid>
				</form>
			</Container>
		) : null;
	}
}

const mapStateToProps = (state, ownProps) => {
	let initialValues = {};
	let aux_validation = {};
	if (ownProps.formData) {
		ownProps.formData.columns.forEach((column) => {
			column.separators.forEach((separator) => {
				const { fields } = separator;
				initialValues = fields
					.filter((field) => Object.values(TYPES).includes(field.type) && field.type !== TYPES.NONE)
					.reduce(
						(fields, { name, initialvalue }) => (typeof initialvalue !== 'undefined' ? (fields[name] = initialvalue) : (fields[name] = null), fields),
						initialValues
					);
			});
		});

		ownProps.formData.columns.forEach((column) => {
			column.separators.forEach((separator) => {
				const { fields } = separator;
				aux_validation = fields
					.filter((field) => Object.values(TYPES).includes(field.type) && field.type !== TYPES.NONE)
					.reduce(
						(fields, { name, validate }) => (typeof validate !== 'undefined' ? (fields[name] = validate) : 0, fields),
						aux_validation
					);
			});
		});
	}

	return {
		translate: getTranslate(state.localize),
		config: state.config,
		disable_button: state.generic.form.disable_button,
		submitting: state.generic.form.submitting,
		login: state.login,
		language: state.users.whoami.language,
		formErrors: getFormSyncErrors(ownProps.form)(state),
		timezone: state.users.whoami.timezone,
		...(ownProps.formData
			? {
					initialValues: initialValues,
			  }
			: {}),
		validate: (values) => validate(values)(aux_validation),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		initialize: (data, keepDirty, options) => dispatch(initialize(ownProps.form, data, keepDirty, options)),
		untouch,
		invalidateField: (form, error) => dispatch(updateSyncErrors(form, error)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		enableReinitialize: false,
		destroyOnUnmount: false,
		multipartForm: true,
		touchOnChange: true,
	})(withTheme(Main))
);
