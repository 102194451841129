/* eslint-disable no-use-before-define */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { TextField, Button, Grid } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { isMobileSize } from '../../../helpers/functions/functions';

class FunctionMultiAutocomplete extends Component {
	state = {
		selected: this.props.customProps.inputdata.initialvalue,
	};

	setSelected = (val) => {
		this.setState({
			selected: val,
		});

		this.props.input.onChange(val);
	};

	selectedAll = () => {
		this.setState({
			selected: this.props.customProps.inputdata.options,
		});

		this.props.input.onChange(this.props.customProps.inputdata.options);

		if (typeof this.props.customProps.inputdata.onChange !== 'undefined') {
			this.props.customProps.inputdata.onChange(this.props.customProps.inputdata.options);
		}
	};

	resetAll = () => {
		this.setState({
			selected: [],
		});

		this.props.input.onChange([]);

		if (typeof this.props.customProps.inputdata.onChange !== 'undefined') {
			this.props.customProps.inputdata.onChange([]);
		}
	};

	render() {
		return (
			<div>
				<Grid container alignItems="center">
					<Grid item xs={12} sm={10}>
						<Autocomplete
							multiple
							id={'autocomplete_' + this.props.customProps.inputdata.id}
							options={this.props.customProps.inputdata.options}
							disableCloseOnSelect
							onChange={(e, val) => {
								this.setSelected(val);
								if (typeof this.props.customProps.inputdata.onChange !== 'undefined') {
									this.props.customProps.inputdata.onChange(val);
								}
							}}
							value={this.state.selected}
							isOptionEqualToValue={(option) => this.state.selected.some((selected) => selected.value === option.value)}
							filterSelectedOptions={true}
							getOptionLabel={(option) => option.label}
							renderInput={(params) => <TextField {...params} variant="outlined" label={this.props.customProps.inputdata.label} />}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={2}
						className={
							!isMobileSize()
								? 'generic-form-content-form-field-multiautocomplete-suffix-left-padding'
								: 'generic-form-content-form-field-multiautocomplete-suffix-top-padding'
						}
					>
						<Button size="small" color="primary" onClick={this.selectedAll}>
							{' '}
							{this.props.translate('generic_form_autocomplete_button_select_all')}{' '}
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, null)(FunctionMultiAutocomplete);
