const react_grid_layout = (theme) => {
	return {
		'.react-grid-layout': {
			position: 'relativeTimeRounding',
			transition: ' height 200ms easerelativeTimeRounding',
		},
		'.react-grid-item': {
			transition: 'all 200ms ease',
			transitionProperty: 'left, top',
		},
		'.react-grid-item img': {
			pointerEvents: 'none',
			userSelect: 'none',
		},
		'.react-grid-item.cssTransforms': {
			transitionProperty: 'transform',
		},
		'.react-grid-item.resizing': {
			zIndex: 1,
			willChange: 'width, height',
		},
		'.react-grid-item.react-draggable-dragging': {
			transition: 'none',
			zIndex: 3,
			willChange: 'transform',
		},
		'.react-grid-item.dropping': {
			visibility: 'hidden',
		},
		'.react-grid-item.react-grid-placeholder': {
			background: 'red',
			opacity: 0.2,
			transitionDuration: '100ms',
			zIndex: 2,
			WebkitUserSelect: 'none',
			MozUserSelect: 'none',
			msUserSelect: 'none',
			OUserSelect: 'none',
			userSelect: 'none',
		},
		'.react-grid-item > .react-resizable-handle': {
			position: 'absolute',
			width: '20px',
			height: '20px',
		},
		'.react-grid-item > .react-resizable-handle::after': {
			content: '""',
			position: 'absolute',
			right: '3px',
			bottom: '3px',
			width: '5px',
			height: '5px',
			borderRight: '2px solid rgba(0, 0, 0, 0.4)',
			borderBottom: '2px solid rgba(0, 0, 0, 0.4)',
		},
		'.react-resizable-hide > .react-resizable-handle': {
			display: 'none',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-sw': {
			bottom: 0,
			left: 0,
			cursor: 'sw-resize',
			transform: 'rotate(90deg)',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-se': {
			bottom: 0,
			right: 0,
			cursor: 'se-resize',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-nw': {
			top: 0,
			left: 0,
			cursor: 'nw-resize',
			transform: 'rotate(180deg)',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-ne': {
			top: 0,
			right: 0,
			cursor: 'ne-resize',
			transform: 'rotate(270deg)',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-w, .react-grid-item > .react-resizable-handle.react-resizable-handle-e': {
			top: '50%',
			marginTop: '-10px',
			cursor: 'ew-resize',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-w': {
			left: 0,
			transform: 'rotate(135deg)',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-e': {
			right: 0,
			transform: 'rotate(315deg)',
		},
		'.react-grid-item > .react-resizable-handle.react-resizable-handle-n, .react-grid-item > .react-resizable-handle.react-resizable-handle-s': {
			left: '50%',
			marginLeft: '-10px',
			cursor: 'ns-resize',
		},

		' .react-grid-item > .react-resizable-handle.react-resizable-handle-n': {
			top: 0,
			transform: 'rotate(225deg)',
		},
		' .react-grid-item > .react-resizable-handle.react-resizable-handle-s': {
			bottom: 0,
			transform: 'rotate(45deg)',
		},
	};
};

export default react_grid_layout;
