import WIDGETS from './widgets';

export default {
	name: 'nhs_patient_dashboard',
	widgets: [
		/**NHS Widgets Dashboard */
		{ content: WIDGETS.WELCOME, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.WEIGHT, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
    { content: WIDGETS.GOAL, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{
			content: {
				spacing: 0,
				widgets: [
					{ content: WIDGETS.FOOD, breakpoints: { xs: 12, md: 12 }, lazy_loading: 0 },
					{ content: WIDGETS.MOOD, breakpoints: { xs: 12, md: 12 }, lazy_loading: 0 },
					{ content: WIDGETS.EXERCISE, breakpoints: { xs: 12, md: 12 }, lazy_loading: 0 },
				],
			},
			breakpoints: { xs: 12, sm: 6 },
			lazy_loading: 0,
			props: {
				rowSpacing: 2,
			},
		},
		{ content: WIDGETS.DAILYREPORT, breakpoints: { xs: 12, md: 6}, lazy_loading: 0 },
		{ content: WIDGETS.MEETING, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{ content: WIDGETS.COMMUNITY, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{ content: WIDGETS.LIBRARY, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
		{ content: WIDGETS.MYFILES, breakpoints: { xs: 12, md: 6 }, lazy_loading: 0 },
	],
};
