import WIDGETS from './widgets';

export default {
  name: 'food_details',
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKFOOD, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.UPDATEFOOD, breakpoints: { xs: 12, sm: 12 } },
	],
};
