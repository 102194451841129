import React, { Component } from 'react';
import { getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Send as SendIcon } from '@mui/icons-material';
import { TextField, MenuItem, Box, Button, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { logoutAction, setAdminTokenAction, handleSelectClientAction } from './actions/loginActions';
import LoginTabs from './LoginTabs';
import MadeBy from './MadeBy';
import { setActiveLanguage } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { setPromptDialogIsDirty } from '../Generic/actions/promptActions';
import { fetchActivitybyTokenAction } from '../Activities/actions/activitiesActions';

class LoginPanel extends Component {
	getAvailableLanguages = () => {
		const languages = this.props.languages;
		return languages;
	};

	state = {
		form: true,
		message: '',
	};

	handleChangeLanguage = (value) => {
		this.props.setActiveLanguage(value);
    document.getElementsByTagName('html')[0].setAttribute('lang', value.split('-')[0]);
	};

	output = () => {
		return (
			<LoginTabs
				message={this.state.message}
				selectedLanguage={this.props.currentLanguage ? this.props.currentLanguage.code : ''}
				recovery={this.props.recovery}
				recovery_token={this.props.recovery_token}
        recovery_medium={this.props.recovery_medium}
        recovery_value={this.props.recovery_value}
				platform={this.props.platform === 'backoffice' ? 'backoffice' : 'frontend'}
			/>
		);
	};

	render() {
		return (
			<div className={this.props.platform === 'backoffice' ? '' : 'login-content-right-main'}>
				{typeof this.props.client === 'undefined' ||
				this.props.client === null ||
				(this.props.client.id === 0 && this.props.platform === 'frontoffice') ? (
					<div className="login-content-right-content-buttons">
						<Typography variant="h5">{this.props.translate('login_select_client_title')}</Typography>
						{typeof this.props.settings.clients !== 'undefined' &&
							this.props.settings.clients.map((client, key) =>
								client.public == '1' ? (
									<Box width="100%" mt={5} key={key}>
										<Button
											key={client.id}
											color="primary"
											className="login-content-right-button-clients"
											onClick={() => this.props.handleSelectClientAction(client, 'frontoffice')}
										>
											{client.name}
											<SendIcon />
										</Button>
									</Box>
								) : (
									''
								)
							)}
					</div>
				) : (
					<div className={this.props.platform === 'backoffice' ? 'login-content-right-content-backoffice' : 'login-content-right-content'}>
						{this.props.client !== null && this.props.platform === 'frontoffice' ? (
							<Typography component="h3" variant="h3" gutterBottom>
								{this.props.client.name}
							</Typography>
						) : (
							this.props.platform === 'backoffice' && (
								<Typography component="h2" variant="h2" gutterBottom>
									{this.props.translate('login_choose_site_button_backoffice')}
								</Typography>
							)
						)}
						{this.props.message && this.props.message.length > 0 && this.props.platform === 'frontoffice' && (
							<Box>
								<Alert severity="warning">{this.props.message}</Alert>
							</Box>
						)}
						<LoginTabs
							message={this.state.message}
							selectedLanguage={this.props.currentLanguage ? this.props.currentLanguage.code : ''}
							recovery={this.props.recovery}
							recovery_token={this.props.recovery_token}
							platform={this.props.platform === 'backoffice' ? 'backoffice' : 'frontend'}
						/>
					</div>
				)}
				{this.props.languages.length > 1 ? (
					<div className={this.props.platform === 'backoffice' ? '' : 'login-content-right-footer'}>
						<TextField
							FormHelperTextProps={{
								...(this.props.platform === 'backoffice' && { className: 'login-backoffice-language-selector' }),
							}}
							InputLabelProps={{
								...(this.props.platform === 'backoffice' && { className: 'login-backoffice-language-selector' }),
							}}
							SelectProps={{
								...(this.props.platform === 'backoffice' && { className: 'login-backoffice-language-selector' }),
							}}
							select
							label={this.props.translate('generic_select')}
							value={this.props.currentLanguage ? this.props.currentLanguage.code : ''}
							onChange={(value) => this.handleChangeLanguage(value.target.value)}
							helperText={this.props.translate('login_select_preferred_language')}
							margin="normal"
							variant="outlined"
						>
							{this.props.languages.map((option) => (
								<MenuItem key={option.code} value={option.code}>
									{this.props.translate('language_' + option.label)}
								</MenuItem>
							))}
						</TextField>
						<Box mt={this.props.platform === 'backoffice' ? 0 : 5}>
							<MadeBy />
						</Box>
					</div>
				) : (
					''
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings,
	login: state.login.login,
	message: state.login.message,
	platform: state.login.platform,
	confirmed: state.login.confirmed,
	client: state.login.client,
	config: state.config,
	user: state.users.whoami,
	languages: getLanguages(state.localize).filter((test) => test.code !== 'raw'),
	translate: getTranslate(state.localize),
	currentLanguage: getActiveLanguage(state.localize),
});

export default connect(mapStateToProps, {
	fetchActivitybyTokenAction,
	logoutAction,
	setAdminTokenAction,
	setActiveLanguage,
	handleSelectClientAction,
	push,
	setPromptDialogIsDirty,
})(LoginPanel);
