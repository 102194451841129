import React, { Suspense, Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Loading } from '../Generic';
import { lazyRetry } from '../../helpers/functions/lazy_loading';

const Editor = React.lazy(() => lazyRetry(() => import('@toast-ui/react-editor').then((module) => ({ default: module.Editor })), 'toast-editor'));

class ToastEditor extends Component {
	constructor(props) {
		super(props);

		this.editorRef = React.createRef();
	}

	render() {
		const { translate } = this.props;

		return (
			<Suspense fallback={''}>
				{this.props.loading ? (
					<div className="generic-text-align-center">
						<Loading size={30} insideComponent={true} />
					</div>
				) : (
					<Editor
						ref={this.editorRef}
						onChange={(event) => {
							if (this.editorRef.current) {
								let editorInstance = this.editorRef.current.getInstance();
								this.props.onChange(editorInstance.getMarkdown(), editorInstance.getHTML());
							}
						}}
						initialValue={this.props.initialValue ? this.props.initialValue : ''}
						previewStyle="vertical"
						height="500px"
						initialEditType="wysiwyg"
						useCommandShortcut={true}
						exts={[
							{
								name: 'chart',
								minWidth: 100,
								maxWidth: 600,
								minHeight: 100,
								maxHeight: 300,
							},
							'scrollSync',
							'colorSyntax',
							'uml',
							'mark',
							'table',
						]}
					/>
				)}
			</Suspense>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
});

export default connect(mapStateToProps, null)(ToastEditor);
