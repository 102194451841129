import axios from 'axios';
import eventsService from '../Events';
import { getTranslate } from 'react-localize-redux';
import { store } from '../../store.js';
import { logoutAction } from '../../components/Login/actions/loginActions';
import { handleBlockingUIAction } from '../../components/Generic/actions/genericActions';
const { updateSyncErrors } = require('redux-form/lib/actions').default;

const handleError = async (error, alert = 'alert', handle_error, submit_form) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	setTimeout(
		() => {
			if (state.login.login && error.response.status === 401) {
				store.dispatch(logoutAction(true, state.login.type !== 'meeting', translate('inactivity_logout_info_message')));
			} else if (typeof handle_error === 'function') {
				handle_error(error, () => {
					eventsService.triggerEvent(alert, { type: 'error', message: error.response.data.message });
				});
			} else {
				eventsService.triggerEvent(alert, { type: 'error', message: error.response.data.message });
			}
			if (submit_form) {
				store.dispatch(handleBlockingUIAction(false));

				store.dispatch(
					updateSyncErrors(
						submit_form,
						error.response.data.context.reduce((accumulator, error) => {
							if (Array.isArray(error.field)) {
								return error.field.reduce((acc, field) => {
									return { ...acc, [field]: error.message };
								}, accumulator);
							}
							return { ...accumulator, [error.field]: error.message };
						}, {})
					)
				);
			}
		},
		submit_form ? 1000 : 0
	);
	throw new Error(error.response.data.message);
};

const apiRequest = async ({
	method,
	url,
	params = {},
	data = false,
	headers = {},
	alert = 'alert',
	success_message = '',
	handle_error = false,
	submit_form = '',
  responseType = 'json'
}) => {
	if (submit_form) {
		store.dispatch(handleBlockingUIAction(true));
	}

	return axios({
		method,
		url,
		params,
		...(data ? { data } : {}),
		headers,
		responseType,
	})
		.then((response) => {
			if (submit_form) {
				setTimeout(() => {
					store.dispatch(handleBlockingUIAction(false));
				}, 1000);
			}

			if (success_message) {
				setTimeout(
					() => {
						eventsService.triggerEvent('alert', { type: 'success', message: success_message });
					},
					submit_form ? 1000 : 0
				);
			}

			return response;
		})
		.catch((error) => handleError(error, alert, handle_error, submit_form));
};

export { apiRequest, handleError };
