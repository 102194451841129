export const MYCASES = 'mycases';
export const MYCASES_WIDGET = 'mycases_widget';
export const ALLCASES = 'allcases';
export const CASES_HISTORY = 'cases_history';
export const WAITINGROOM = 'waitingroom';
export const ACTIVITIES = 'activities';
export const ACTIVITIES_WIDGET = 'activities_widget';
export const NOTIFICATIONS = 'notifications';
export const NOTIFICATIONS_WIDGET = 'notifications_widget';
export const MEETING_SUMMARY_FILES = 'meeting_summary_files';
export const MEETING_SUMMARY_INVITEES = 'meeting_summary_invitees';
export const ANALYTIC_TICKETS_LOGS = 'analytic_tickets_logs';

export default {
	MYCASES,
	MYCASES_WIDGET,
	ALLCASES,
	CASES_HISTORY,
	WAITINGROOM,
	ACTIVITIES,
	ACTIVITIES_WIDGET,
	NOTIFICATIONS,
	NOTIFICATIONS_WIDGET,
	MEETING_SUMMARY_FILES,
	MEETING_SUMMARY_INVITEES,
  ANALYTIC_TICKETS_LOGS,
};
