import { convertHex } from '../../helpers/functions/functions';

const videoconference = (theme) => {
	return {
    '.video-main-grid-video-chat': {
			backgroundColor: (props) => props?.settings?.styling?.videoconference_background_color,
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},
		'.fullscreen-layer': {
			width: '100%',
			position: 'relative',
			zIndex: '100',
		},
		'.fullscreen-fab': {
			// right: 0,
			// zIndex: '100',
			// position: 'absolute',
			margin: theme.spacing(2),
		},
		'.videoconference-fullscreen-button': {
			position: 'absolute',
			zIndex: '100',
			right: 0,
		},
		'.videoconference-buttons': {
			position: 'absolute',
			zIndex: '100',
			textAlign: 'center',
			// bottom: (props) => typeof props.publisher !== 'undefined' && props.publisher.grid_rows ? (props.publisher.grid_rows * 70) + 'px' : 0,
			// [theme.breakpoints.down('sm')]: {
			// 	bottom: 70 + theme.spacing(1),
			//   },
			// [theme.breakpoints.up('sm')]: {
			// 	bottom: 140 + theme.spacing(2),
			//   },
			marginLeft: 'auto',
			marginRight: 'auto',
			left: 0,
			right: 0,
		},
		'.buttons-layer': {
			display: 'flex',
			justifyContent: 'center',
			textAlign: 'center',
		},
		'.videoconference-buttons button': {
			// margin: theme.spacing(1),
		},
		'.videoconference-publisher-main': {
			// backgroundColor: 'black',
			height: '100%',
			padding: theme.spacing(1),
		},
		'.videoconference-publisher-main > div': {
			height: '100%',
		},
		'.videoconference-publisher-main > div > div': {
			height: '100%',
			borderRadius: '5px',
		},
		'.videoconference-bottom-grid': {
			// backgroundColor: 'black',
		},
		'.publisher_container': {
			height: '100%',
		},
		'.OT_widget-container': {
			//backgroundColor: '#5f6062'
		},
		'.grid-big-container': {
			//borderBottom: 'solid 1px white',
			borderBottomLeftRadius: theme.spacing(0.5),
			borderBottomRightRadius: theme.spacing(0.5),
			// backgroundColor: theme.palette.grey[900],
			// height: '45vh'
			// backgroundColor: '#5f6062',
			// backgroundColor: theme.palette.grey[700],
			display: 'block',
			position: 'relative',
		},
		'.videoconference-subscriber-main': {
			height: '100%',
			position: 'relative',
			padding: theme.spacing(1),
		},
		'.videoconference-subscriber-main > div': {
			height: '100%',
		},
		'.videoconference-subscriber-main > div > div': {
			borderRadius: '5px',
			// border: '1px solid grey'
		},

		'.videoconference-small-streams': {
			height: '16vh',
		},
		'.videoconference-small-streams-subscriber': {
			cursor: 'pointer',
		},

		'.videoconference-card-content-container': {
			padding: '0px !important',
			textAlign: 'center',
			marginBottom: theme.spacing(3),
		},
		'.videoconference-card-content-container-webcam': {
			// transform: 'scaleX(-1)',
			// width: '90%'
			// width: '35%',
			// height: 'inherit',
			width: '100%',
		},
		'.videoconference-card-content-container-icon-main': {
			width: '100%',
			// height: '300px',
			textAlign: 'center',
			position: 'relative',
		},
		'.videoconference-card-content-container-icon': {
			position: 'relative',
			color: 'grey',
			fontSize: '100px',
			height: 'inherit',
		},

		// 		  '.layout-container, .publisher-container': {
		// 			height: '100%'
		// 		},

		// '.layout-container': {
		// 	// position: 'absolute',
		// 	top: 0,
		// 	left: 0,
		// 	right: 0,
		// 	bottom: 0,
		// 	backgroundColor: '#DDD',
		//   },

		//   '.layout-container > div': {
		// 	backgroundColor: '#000',
		// 	display: 'inline-block',
		// 	border: '1px solid #fff',

		// 	transitionProperty: 'all',
		// 	transitionDuration: '0.5s',

		// width: 0,
		// height: 0,
		// opacity: 0,
		//   },

		//   '.layout-container > div.ot-layout': {
		// 	opacity: 1
		//   }
		// '.publisher': {
		// 	float: 'right',
		// 	marginRight: '10%',
		//   },
		//   '.publisher_container': {
		// 	// width: '80vh !important',
		// 	// height: '80vh !important',
		// 	position: 'absolute',
		//     zIndex: 100
		//   },

		'.ml14': {
			color: (props) => props?.settings?.styling?.videoconference_background_contrast_color,
		},

		'.ml14 .text-wrapper': {
			position: 'relative',
			display: 'inline-block',
			paddingTop: '0.1em',
			paddingRight: '0.05em',
			paddingBottom: '0.15em',
		},

		'.ml14 .line': {
			opacity: 0,
			position: 'absolute',
			left: 0,
			height: '2px',
			width: '100%',
			backgroundColor: '#fff',
			transformOrigin: '100% 100%',
			bottom: 0,
		},
		'.ml14 .letter': {
			display: 'inline-block',
			lineHeight: '1em',
		},
		'.video-tab-blinker-badge': {
			animation: 'blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate',
		},
		'@keyframes blinker': {
			from: { opacity: '1' },
			to: { opacity: '0' },
		},
		'.subscriber-action-buttons-container': {
			paddingTop: theme.spacing(4),
			position: 'absolute',
			zIndex: 50,
			width: '99%',
			top: '0%',
		},
		'.subscriber-action-button': {
			margin: theme.spacing(0.5),
		},
		'.subscriber-action-button-pin': {
			color: theme.palette.common.white,
		},
		'.subscriber-action-button-pin-active': {
			color: theme.palette.primary.main,
		},
		'.video-lobby-list-header-item-waiting': {
			backgroundColor: theme.palette.warning.light,
			borderBottom: 'solid 1px',
			borderTop: 'solid 1px',
		},
		'.video-lobby-list-header-item-attendees': {
			backgroundColor: convertHex(theme.palette.primary.light, 5),
			borderBottom: 'solid 1px',
			borderTop: 'solid 1px',
		},
		'.video-lobby-list-header-item-invitees': {
			backgroundColor: convertHex(theme.palette.secondary.main, 5),
			borderBottom: 'solid 1px',
			borderTop: 'solid 1px',
		},
		'.video-lobby-snackbar': {
			minWidth: 'auto !important',
			backgroundColor: theme.palette.grey[300],
			color: theme.palette.common.black,
		},
		'.video-snack-action-button': {
			textTransform: 'none',
			color: theme.palette.primary.contrastText,
		},
		'.video-chat-container': {
			backgroundColor: theme.palette.grey[100],
		},
		'.video-lobby-container': {
			backgroundColor: theme.palette.grey[100],
			padding: theme.spacing(1),
			height: '100%',
			overflow: 'auto',
		},
		'.video-verified-user-badge': {
			color: theme.palette.success.main + ' !important',
		},
		'.video-verified-user-subscriber-ot-bar': {
			color: theme.palette.success.main + ' !important',
			fontSize: '1.5rem !important',
		},
		'.subscriber-ot-bar-name': {
			paddingLeft: theme.spacing(3) + ' !important',
		},
		'.video-settings-box-control-container': {
			display: 'flex',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
		},
		'.video-settings-box-control-meter-container': {
			position: 'relative',
			top: '50%',
			transform: 'translateY(-50%)',
			textAlign: 'center',
		},
		'.video-settings-dialog-close-button': {
			position: 'absolute',
			right: '8px',
			top: '8px',
			color: 'grey',
		},
		'.videoconference-buttons-disabled.Mui-disabled': {
			backgroundColor: theme.palette.grey[700],
			color: theme.palette.getContrastText(theme.palette.grey[700]),
			'&:hover': {
				backgroundColor: theme.palette.grey[700],
				color: theme.palette.getContrastText(theme.palette.grey[700]),
			},
		},
		'.wave': {
			animationName: 'wave-animation' /* Refers to the name of your @keyframes element below */,
			animationDuration: '2.5s' /* Change to speed up or slow down */,
			animationIterationCount: 'infinite' /* Never stop waving :) */,
			transformOrigin: '70% 70%' /* Pivot around the bottom-left palm */,
			display: 'inline-block',
		},
		'@keyframes wave-animation': {
			'0%': { transform: 'rotate( 0.0deg)' },
			'10%': { transform: 'rotate(14.0deg)' } /* The following five values can be played with to make the waving more or less extreme */,
			'20%': { transform: 'rotate(-8.0deg)' },
			'30%': { transform: 'rotate(14.0deg)' },
			'40%': { transform: 'rotate(-4.0deg)' },
			'50%': { transform: 'rotate(10.0deg)' },
			'60%': { transform: 'rotate( 0.0deg)' } /* Reset for the last half to pause */,
			'100%': { transform: 'rotate( 0.0deg)' },
		},
		'.videoconference-button-raised-hand': {
			color: '#fac01e',
		},
		'.video-lobby-mobile-width': {
			minWidth: '45px !important',
		},
    '.OT_root': {
      margin: theme.spacing(1) + ' !important'
    },
    '.OT_bar': {
      height: '100% !important'
    },
    '.OT_name': {
      left: theme.spacing(1) + ' !important'
    }
	};
};

export default videoconference;
