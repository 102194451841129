/** Orig Types */
const TICKETS = 'App\\Models\\Tickets';
const MEETINGS  = 'App\\Models\\Meetings';
const USERS  = 'App\\Models\\Users';
const ACTIVITIES  = 'App\\Models\\Activities';

/** Reference Types */
const TICKETS_TICKETS = 'App\\Models\\Tickets';
const TICKETS_FOLLOWUP = 'App\\Models\\Tickets';
const TICKETS_APPOINTMENTS = 'App\\Models\\Tickets\\Appointments';
const TICKETS_MEETINGS = 'App\\Models\\Meetings';
const TICKETS_FILES = 'App\\Models\\Tickets\\Files';
const TICKETS_QUESTIONNAIRES = 'App\\Models\\Tickets\\Questionnaires';
const TICKETS_SURVEYS = 'App\\Models\\Tickets\\Surveys';
const TICKETS_ACCESS = 'App\\Models\\Tickets\\Access';
const CHAT_MESSAGES  = 'App\\Models\\Messages';
const TICKETS_QFILES = 'App\\Models\\Tickets\\Questionnaires\\Files';
const TICKETS_PRESCRIPTIONS = 'App\\Models\\Tickets\\Prescriptions';

/** Link Types */
const APPOINTMENT = 'appointment';
const FILE = 'files';
const MEETING_TICKET = 'meeting_ticket';
const MEETING = 'meetings';

export default {
	ORIG: {
		TICKETS,
		MEETINGS,
		USERS,
		ACTIVITIES,
	},
	REF: {
		TICKETS_TICKETS,
		TICKETS_FOLLOWUP,
		TICKETS_APPOINTMENTS,
		TICKETS_MEETINGS,
		TICKETS_FILES,
		TICKETS_QUESTIONNAIRES,
		TICKETS_ACCESS,
		CHAT_MESSAGES,
		TICKETS_QFILES,
		TICKETS_PRESCRIPTIONS,
    TICKETS_SURVEYS
	},
  LINK_TYPE: {
    FILE,
    APPOINTMENT,
    MEETING_TICKET,
    MEETING
  }
};