import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormHelperText, Typography, FormControl, Grid, Tooltip, Box, FormControlLabel } from '@mui/material';
import { isMobileSize } from '../../../helpers/functions/functions';
import InfoIcon from '@mui/icons-material/Info';

function RenderCustomRadioGroup({ input, meta: { invalid, touched, error, active }, ...custom }) {
	// console.log('ID RenderCustomRadioGroup', custom.inputdata.id);
	// console.log('INPUT RenderCustomRadioGroup', input);
  // console.log(custom)
	const radio = (option, props) => {
    // console.log('re-render');
    // console.log('PROPS', props);
    // console.log('INPUT', input);

    return (<FormControlLabel
			sx={{ m: 0 }}
			control={
				<Radio
					icon={option.icon}
					checkedIcon={option.checked_icon}
					key={option.value}
					value={option.value}
					disabled={option.readonly}
					sx={{
						color: 'white',
						'&.Mui-checked': {
							backgroundColor: option.color,
						},
					}}
					{...(custom.inputdata.enable_option_label && { classes: { root: 'hidden' } })}
					// onMouseDown={(event) => {
					// 	console.log('onMouseDown', event);
					// 	input.onChange(event);
					// 	if (typeof custom.inputdata.onChange !== 'undefined') {
					// 		custom.inputdata.onChange(event.target.value);
					// 	}
					// }}
					onChange={(event) => {
						console.log('ON CHANGE', event);
            if (event.target.value == option.value) {
              input.onChange(event);
              if (typeof custom.inputdata.onChange !== 'undefined') {
                custom.inputdata.onChange(event.target.value);
              }
            }
					}}
          // onBlur={() => {
          //   console.log('ON CHANGE', event);
					// 	input.onChange(event);
					// 	if (typeof custom.inputdata.onChange !== 'undefined') {
					// 		custom.inputdata.onChange(event.target.value);
					// 	}
          // }}
          // onFocus={() => {
          //   console.log('ON CHANGE', event);
					// 	input.onChange(event);
					// 	if (typeof custom.inputdata.onChange !== 'undefined') {
					// 		custom.inputdata.onChange(event.target.value);
					// 	}
          // }}
					{...props}
				/>
			}
			{...(custom.inputdata.enable_option_label && { label: option.label })}
		/>)
  };

	return (
		<FormControl fullWidth component="fieldset" error={touched && invalid && !active}>
			<Grid container sx={custom.inputdata.label_grid_sx}>
				<Typography sx={custom.inputdata.label_sx}>{custom.inputdata.label}</Typography>
				{custom.inputdata.infoTooltip && (
					<Tooltip title={custom.inputdata.infoTooltip} placement="right-end" color="secondary">
						<InfoIcon size="small" />
					</Tooltip>
				)}
			</Grid>
			<RadioGroup
				{...input}
				id={custom.inputdata.id}
				name={custom.inputdata.name}
				row={typeof custom.inputdata.row !== 'undefined' ? custom.inputdata.row : !isMobileSize()}
				{...custom}
			>
				<Box sx={{ width: '100%' }}>
					{custom.inputdata.renderRadio((value) => {
						// console.log('INPUT', 'ON CHANGE BOX', input);
						// console.log('VALUE', 'ON CHANGE BOX', value);
						input.onChange(value);
						if (typeof custom.inputdata.onChange !== 'undefined') {
							custom.inputdata.onChange(value);
						}
					})(radio, custom.inputdata.options)}
				</Box>
			</RadioGroup>
			<FormHelperText>{touched && !active && error}</FormHelperText>
		</FormControl>
	);
}

RenderCustomRadioGroup.propTypes = {};

export default RenderCustomRadioGroup;
