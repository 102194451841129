import WIDGETS from './widgets';

export default {
  name: 'mu_navigation',
	sx: {
		height: '100%',
    flexWrap: 'nowrap'
	},
	spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKNAVIGATION, breakpoints: { xs: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.MU_NAVIGATION, breakpoints: { xs: 12 }, lazy_loading: 0, sx: { flexGrow: 1 } },
		{ content: WIDGETS.LOGOUT, breakpoints: { xs: 12 }, lazy_loading: 0 },
	],
};
