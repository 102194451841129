import WIDGETS from './widgets';

export default {
  name: 'cummunity_details',
  spacing: 0,
	direction: 'column',
	widgets: [
		{ content: WIDGETS.GOBACKCOMMUNITY, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
		{ content: WIDGETS.COMMUNITYDETAILS, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
    { content: WIDGETS.ACTIVITYLIST, breakpoints: { xs: 12, sm: 12 }, lazy_loading: 0 },
	],
};
