import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Container, Typography, Link, Paper } from '@mui/material';
import PlaceholderIMG from '../../assets/images/placeholder.svg';

function Footer({ translate, ...props }) {
	return (
		<Paper
			// className="generic-footer"
      elevation={3}
			id="footer-element"
			sx={{
				minHeight: '',
				backgroundColor: '#f4f6f8',
				width: '100%',
				...(props.side_bar?.drawer_props?.variant === 'permanent' && { zIndex: (theme) => theme.zIndex.drawer + 1 }),
			}}
		>
			<Container
				maxWidth={props.settings.styling.always_display_general_brand ? 'xs' : 'sm'}
				style={{ display: props.settings.styling.always_display_general_brand ? 'flex' : '' }}
			>
				{props.settings.styling.always_display_general_brand && (
					<img
						src={
							typeof props.settings.site !== 'undefined' && props.settings.site.files.navbar_logo
								? props.config.apihost + '/configuration/files/' + props.settings.site.files.navbar_logo.uuid
								: PlaceholderIMG
						}
						height="20"
						alt="Alteri's logo"
					/>
				)}
				<Typography
					variant="body2"
					color="textSecondary"
					align="center"
					sx={{
						paddingLeft: props.settings.styling.always_display_general_brand ? '24px' : '',
						paddingTop: (theme) => theme.spacing(1),
						paddingBottom: (theme) => theme.spacing(1),
					}}
				>
					{translate('footer_copyright', {
						footer_copyright_company: (
							<Link color="inherit" href={process.env.REACT_APP_COPYRIGHT_LINK} target="_blank">
								{translate('company_name')}
							</Link>
						),
					})}{' '}
					{new Date().getFullYear()}
				</Typography>
			</Container>
		</Paper>
	);
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	settings: state.settings,
	config: state.config,
	side_bar: state.generic.side_bar,
});

export default connect(mapStateToProps)(Footer);
